export const GetAutoNumber = async (requestBody: any) => {
  const email = window.localStorage.getItem("email") || "";
  requestBody.UserPrincipalName = email;
  const respone = await fetch("api/RequestControl/GetAutoNumber", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {});
  return respone;
};

export const GetSaveRunningNumber = async (requestBody: any) => {
  const email = window.localStorage.getItem("email") || "";
  requestBody.UserPrincipalName = email;
  const respone = await fetch("api/RequestControl/SaveRunning", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {});
  return respone;
};

export const GetRvsRunning = async (requestBody: any) => {
  const email = window.localStorage.getItem("email") || "";
  requestBody.UserPrincipalName = email;
  const respone = await fetch("api/RequestControl/GetRunning", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {});
  return respone;
};

export const CanAssignMoreThanOne = async () => {
  const email = window.localStorage.getItem("email") || "";
  let requestBody = {
    UserPrincipalName: email,
  };
  const respone = await fetch("api/RequestControl/CanAssignMoreThanOne", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  })
    .then((response) => response.json())
    .then((data) => {
      data.result = data.result == "True";
      return data;
    })
    .catch((err) => {
      console.error("error: CanAssignMoreThanOne", err);
    });
  return respone;
};

export const CanRequestCommentMoreThanOne = async () => {
  const email = window.localStorage.getItem("email") || "";
  let requestBody = {
    UserPrincipalName: email,
  };
  const respone = await fetch(
    "api/RequestControl/CanRequestCommentMoreThanOne",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    }
  )
    .then((response) => response.json())
    .then((data) => {
      data.result = data.result == "True";
      return data;
    })
    .catch((err) => {
      console.error("error: CanRequestCommentMoreThanOne", err);
    });
  return respone;
};

export const onDatareladToLoadData = async (dataJson: any) => {
  const response = await fetch(
    "api/TemplateList/TemplateByid/LoadLogic/GetLoadDataFormControl2",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataJson),
    }
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(() => false);
  return response;
};
