import React, { useEffect, useState } from "react";
import { IListApprovalDetailsModel } from "../../../../IRequestModel/IListApprovalDetailsModel";
import { Col, Row, Table } from "antd";
import { useUserContext } from "../../../../Context/UserContext";
import "../../../../components/RequestComponents/LineApprovalsComponent/LineApprovalsComponent.css";
import { GetSignature } from "../../../../Services/MasterDataService";

interface Props {
  lineapprovals: IListApprovalDetailsModel[];
}

export const ResultComponent = ({ lineapprovals }: Props) => {
  const [userData] = useUserContext();
  const [signatureList, setSignatureList] = useState<any[]>([]);

  useEffect(() => {
    fecthSignature();
  }, []);

  const fecthSignature = async () => {
    const res = await GetSignature();
    setSignatureList([...res]);
  };

  return (
    <>
      <Row>
        <Col>Line approvals</Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            className="wolf-table"
            dataSource={lineapprovals}
            columns={[
              {
                title: "#",
                render: (value, record, index) => (index += 1),
                width: 10,
              },
              {
                title: (
                  <>
                    <p className="row headtext">Employee Name</p>
                    <p className="row subtext">ชื่อพนักงาน</p>
                  </>
                ),
                render: (value, record, index) => {
                  return record.approver[
                    userData.Lang === "TH" ? "NameTh" : "NameEn"
                  ];
                },
              },

              {
                title: (
                  <>
                    <p className="row headtext">Position</p>
                    <p className="row subtext">ตำแหน่ง</p>
                  </>
                ),

                render: (value, record, index) => {
                  return record.approver[
                    userData.Lang === "EN" ? "PositionNameEn" : "PositionNameTh"
                  ];
                },
              },
              {
                title: (
                  <>
                    <p className="row headtext">Department</p>
                    <p className="row subtext">หน่วยงาน</p>
                  </>
                ),

                render: (value, record, index) => {
                  return record.approver[
                    userData.Lang === "EN"
                      ? "DepartmentNameEn"
                      : "DepartmentNameTh"
                  ];
                },
              },
              {
                title: (
                  <>
                    <p className="row headtext">Signature </p>
                    <p className="row subtext">คำลงท้าย</p>
                  </>
                ),

                render: (value, record, index) => {
                  const val = signatureList.find(
                    (e: any) => e.masterId === record.signature_id
                  );

                  if (!val) return "";

                  return val[userData.Lang === "EN" ? "value2" : "value1"];
                },
              },
            ]}
          />
        </Col>
      </Row>
    </>
  );
};
