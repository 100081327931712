import React from "react";
import {
  CloseCircleOutlined,
  CheckCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import useMemoPage from "../../hooks/useMemoPage";
import { Link } from "react-router-dom";

type Props = {};

const NotiPopOverContent = (props: Props) => {
  const { FailQue, onDismiss } = useMemoPage();

  const handleDismissNoti = (id: number) => {
    onDismiss(id);
  };

  return (
    <>
      <div className="message-container">
        <h5>Notification</h5>
        <div
          className="message-box"
          style={{
            display: "flex",
            flexDirection: "column",
            rowGap: 5,
          }}
        >
          {FailQue.map((que) => (
            <div
              style={{
                display: "flex",
                backgroundColor: "#F980804D",
                width: "300px",
                borderRadius: 12,
                padding: 12,
                columnGap: 5,
                position: "relative",
              }}
            >
              <span
                style={{
                  position: "absolute",
                  right: 10,
                  top: 5,
                  color: "#8d8787d1",
                  cursor: "pointer",
                }}
                onClick={() => handleDismissNoti(que.id)}
              >
                X
              </span>
              <CloseCircleOutlined
                rev={undefined}
                style={{ fontSize: "20px", color: "#F98080", marginTop: 5 }}
              />
              <Link
                style={{ color: "black" }}
                to={`/Request?MemoID=${que.memoId}`}
              >
                <span>
                  Oops! Something went wrong while sending your document{" "}
                  {que.documentNo} Please review and resend.
                </span>
                <span
                  style={{
                    marginTop: "10px",
                    display: "block",
                    color: "#5c5c5c",
                  }}
                >
                  {que.message}
                </span>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default NotiPopOverContent;
