import "bootstrap/dist/css/bootstrap.css";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import "./i18n";
import {Helmet} from "react-helmet";
const rootElement = document.getElementById("root");

ReactDOM.render(
  <><Helmet>
    <script type="text/javascript" src="compatibility.js"></script>
    <script type="text/javascript" src="pdf.js"></script>

</Helmet>
  <BrowserRouter>
              
    <Suspense fallback={<div>Loading... </div>}>
      <App />
    </Suspense>

  </BrowserRouter>
  </>

  
  ,
  rootElement
);

registerServiceWorker();
