import { useContext } from "react";
import { PDFEditorContext } from "../Context/PDFEditorContext";

const usePDFEditor = () => {
  const context = useContext(PDFEditorContext);
  if (!context)
    throw new Error("PDFEditor Context must be use inside PDFEditor Provider");
  return context;
};

export default usePDFEditor;
