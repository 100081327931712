import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import React, { useEffect, useState } from "react";
import { ControllerRenderProps, FieldValues } from "react-hook-form";

type Props = {
  field: ControllerRenderProps<FieldValues, "default">;
  disabled: boolean;
};

const CalendarDefaultValueGroup = ({ field, disabled }: Props) => {
  useEffect(() => {
    if (disabled) {
      field.onChange("");
    }
  }, [disabled]);

  return (
    <div className="p-inputgroup">
      <InputText
        id={field.name}
        value={field.value}
        aria-describedby="username-help"
        className={`set-input-component-css`}
        disabled={disabled}
        onChange={(e: any) => {
          console.log("valuedate", e.target.value, field, disabled);
          field.onChange(e.target.value);
        }}
      />
    </div>
  );
};

export default CalendarDefaultValueGroup;
