import { Col } from "antd";
import React, { useRef } from "react";
import usePDFEditor from "../../../hooks/usePDFEditor";

type Props = {
  label: string;
  type: string;
  attribute?: any;
  icon: React.ReactNode;
  layoutType: "header" | "body" | "footer" | "content";
};

const PDFCompontButton = ({
  label,
  type,
  icon,
  layoutType,
  attribute,
}: Props) => {
  const count = useRef<number>(1);
  const { updatePageProperties } = usePDFEditor();

  return (
    <div
      draggable
      unselectable="on"
      className={`BTN`}
      onDragStart={(e) => {
        const dataString = JSON.stringify({
          action: "add",
          label: label,
          type: type,
          count: count.current,
          layoutType: layoutType,
          value: "",
          attribute: attribute,
        });
        updatePageProperties({
          dragingType: layoutType,
          isDragingFromOutSide: true,
        });
        e.dataTransfer.setData("application/json", dataString);
      }}
      onDragEnd={() => {
        count.current += 1;
        updatePageProperties({
          isDragingFromOutSide: false,
        });
      }}
    >
      <Col>{icon}</Col>
      <Col flex="left" offset={1}>
        {label}
      </Col>
    </div>
  );
};

export default PDFCompontButton;
