import { Col, Form, Input, Row, Select, Switch } from "antd";
import React from "react";
import { TextHeaderComponents } from "../../../TextHeaderComponents/TextHeaderComponents";
import TextArea from "antd/lib/input/TextArea";

type Props = { templateName: string };

const InformationTab = ({ templateName }: Props) => {
  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={6}>
          <Row>
            <Col>
              <TextHeaderComponents
                textHeaderProps={"Form Name :"}
                textSubProps={"ฟอร์ม :"}
              />
            </Col>
            <Col>
              <div className="flex justify-content-center">
                <p>{templateName}</p>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <Form.Item label="Logic type" name="logictype" required>
            <Select
              showSearch
              options={[
                { label: "Empty", value: "Empty" },
                { label: "actionservice", value: "actionservice" },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Return special" name="ReturnSpecial" required>
            <Select
              showSearch
              options={[
                { label: "Empty", value: "Empty" },
                { label: "True", value: "true" },
                { label: "False", value: "false" },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Auto save" name="AutoSave" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Item label="Description" name="description">
            <TextArea rows={4} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default InformationTab;
