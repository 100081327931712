import { Button, Form, FormInstance, Input, Modal, Select, Space } from "antd";
import React, { useState } from "react";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { advanceFormItemType } from "../../../../Helper/LogicHelper";
import MatchFieldsDataModal from "./MatchFieldsDataModal";
import { ButtonComponents } from "../../../ButtonComponents/ButtonComponents";

type Props = {
  advanceFormItem: advanceFormItemType[];
  form: FormInstance<any>;
};

const MatchFieldsTab = ({ advanceFormItem, form }: Props) => {
  const [modal, contextHolder] = Modal.useModal();
  const [selectedDataIdx, setSelectedDataIdx] = useState<number>(-1);

  return (
    <>
      {contextHolder}
      <Form.List name="MatchSAPField">
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map(({ key, name, ...restField }, idx) => (
                <Space
                  key={idx}
                  size={"middle"}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  align="start"
                >
                  <Form.Item {...restField} name={[name, "HEAD"]}>
                    <Input placeholder="Head" />
                  </Form.Item>
                  <Form.Item {...restField} name={[name, "TYPE"]}>
                    <Select
                      placeholder="-- Please Select --"
                      style={{ minWidth: 100 }}
                      options={[
                        {
                          value: "Single",
                          label: "Single",
                        },
                        {
                          value: "List",
                          label: "List",
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item {...restField} name={[name, "TABLENAME"]}>
                    <Select
                      placeholder="-- Please Select --"
                      options={advanceFormItem
                        .filter((item) => item.type === "tb")
                        .map((e) => {
                          {
                            return { label: e.label, value: e.label };
                          }
                        })}
                    />
                  </Form.Item>
                  <Form.Item {...restField} name={[name, "DATA"]}>
                    <ButtonComponents
                      key={"data" + idx}
                      setStyleProps={{
                        width: "150px",
                        borderRadius: "6px",
                        boxShadow: "none",
                        border: "1px solid #282f6a",
                        fontSize: "13px",
                        paddingLeft: "16px",
                      }}
                      typeProps={"button"}
                      setClassNameProps={"p-button-text-position"}
                      setLabelProps={"Data"}
                      onClickProps={() => {
                        setSelectedDataIdx(idx);
                      }}
                    />
                  </Form.Item>

                  <MinusCircleOutlined
                    onClick={() => remove(name)}
                    rev={undefined}
                  />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined rev={undefined} />}
                >
                  Add
                </Button>
              </Form.Item>
            </>
          );
        }}
      </Form.List>
      <MatchFieldsDataModal
        rowData={
          selectedDataIdx !== -1
            ? form.getFieldValue("MatchSAPField")[selectedDataIdx]?.DATA ?? []
            : undefined
        }
        onSubmit={(formData: { DATA: any[] }) => {
          if (formData.DATA.length > 0) {
            let allmatchSAPField = form.getFieldsValue();
            if (allmatchSAPField["MatchSAPField"][selectedDataIdx]) {
              allmatchSAPField["MatchSAPField"][selectedDataIdx].DATA =
                formData.DATA;
            } else {
              allmatchSAPField["MatchSAPField"][selectedDataIdx] = formData;
            }

            form.setFieldsValue(allmatchSAPField);
          }
          setSelectedDataIdx(-1);
        }}
      />
    </>
  );
};

export default MatchFieldsTab;
