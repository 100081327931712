import React, { useState, useEffect } from "react";
import "../../components/ErrorPageComponent/ErrorPageComponent.css";
type Props = {};

const RejectBrowser = (props: Props) => {
  const [browserConfig] = useState<any>(
    JSON.parse(localStorage.getItem("mstDatabrowserConfig") || "[]")
  );
  return (
    <div className="error-page-container">
      <div className="all-text-container">
        <div className="error-text-container">
          <p className="error-text">Access denied!</p>
          {browserConfig.messages.map((x: any) => (
            <p className="not-found-text">{x}</p>
          ))}
          {/* <p className="not-found-text">
            Browser NOT support this application. Please chooose{" "}
            
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default RejectBrowser;
