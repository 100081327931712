import React, { ReactNode } from "react";
import "./Button.css"; // You can create a CSS file for styling

type FloatingButtonProps = {
  onClick: () => void;
  icon: ReactNode;
  label?: string;
};

const FloatingButton: React.FC<FloatingButtonProps> = ({
  onClick,
  label,
  icon,
}) => {
  return (
    <button className="floating-button" onClick={onClick}>
      {label} {icon}
    </button>
  );
};

export default FloatingButton;
