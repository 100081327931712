import React from "react";
import { LogicFormProps } from "../JsonLogicForm";
import { Button, Form, Input, Select, Space } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
const { Option, OptGroup } = Select;

const SpecialActionServiceForm = ({
  form,
  advanceFormItem,
}: LogicFormProps) => {
  const controlOption: any[] = advanceFormItem
    .map((item) => {
      if (item.type === "tb" && item.columns) {
        return {
          label: "Tb :" + item.label,
          value: item.label,
          options: item.columns.map((col) => ({
            label: col.label,
            value: col.label + "|Tb",
          })),
        };
      }
      return {
        label: item.label,
        value: item.label,
      };
    })
    .filter((e) => e.label);
  return (
    <>
      <Form.Item label="Show Action" name="showaction">
        <Select
          placeholder="Show on status"
          showSearch
          mode={"multiple"}
          style={{ minWidth: 200 }}
          options={[
            { label: "New Request", value: "New Request" },
            { label: "Draft", value: "Draft" },
            { label: "Rework", value: "Rework" },
            { label: "Recall", value: "Recall" },
            {
              label: "Wait for Requestor Review",
              value: "Wait for Requestor Review",
            },
            {
              label: "Return",
              value: "Return",
            },
            {
              label: "Request Comment",
              value: "Request Comment",
            },
          ]}
        />
      </Form.Item>
      <Form.List name="fieldaction">
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map(({ key, name, ...restField }, idx) => (
                <Space
                  key={idx}
                  size={"middle"}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  align="start"
                >
                  <Form.Item
                    {...restField}
                    name={[name, "lable"]}
                    rules={[{ required: true, message: "Missing lable" }]}
                  >
                    <Input placeholder="Label" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "position"]}
                    rules={[
                      {
                        required: true,
                        message: "Missing Position of control",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Position"
                      style={{ minWidth: 200 }}
                      allowClear
                      showSearch
                    >
                      {controlOption.map((item: any, index) => {
                        if (item.options) {
                          return (
                            <>
                              <Option key={item.label} value={item.value}>
                                {item.label}
                              </Option>
                              <OptGroup label={item.label + "'s columns"}>
                                {item.options.map((option: any) => (
                                  <Option
                                    key={
                                      item.label + "'s columns" + option.value
                                    }
                                    value={option.value}
                                  >
                                    {option.label}
                                  </Option>
                                ))}
                              </OptGroup>
                            </>
                          );
                        }

                        return (
                          <Option key={index} value={item.label}>
                            {item.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <MinusCircleOutlined
                    onClick={() => remove(name)}
                    rev={undefined}
                  />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined rev={undefined} />}
                >
                  Add value
                </Button>
              </Form.Item>
            </>
          );
        }}
      </Form.List>
    </>
  );
};

export default SpecialActionServiceForm;
