import { Row, Col, Table, Space, Form, Divider } from "antd";
import React, { useEffect, useState } from "react";
import { ButtonComponents } from "../../ButtonComponents/ButtonComponents";
import { TextHeaderComponents } from "../../TextHeaderComponents/TextHeaderComponents";
import { LogicType } from "../../../constants/logic-type";
import { EditOutlined, EyeOutlined, CloseOutlined } from "@ant-design/icons";

import ActionserviceForm from "./ActionserviceForm";
import {
  advanceFormItemType,
  getTranFormAdvanceForm,
} from "../../../Helper/LogicHelper";
import { FiPlus } from "react-icons/fi";
import useModal from "antd/lib/modal/useModal";

type Props = {
  templateId: any;
  templateName: string;
  dataLogic: any[];
  advanceForm: any;
  setControlModel: (data: any) => void;
};

const ActionserviceTemplate = ({
  templateId,
  dataLogic,
  advanceForm,
  setControlModel,
  templateName,
}: Props) => {
  const [selectedRecord, setSelectedRecord] = useState<any>();
  const [form] = Form.useForm();
  const [advanceFormItem, setAdvanceFormItem] = useState<advanceFormItemType[]>(
    []
  );
  const [modal, contextHolder] = useModal();

  useEffect(() => {
    if (advanceForm) {
      let advanceFormItem = getTranFormAdvanceForm(advanceForm);
      advanceFormItem.push({ label: "Empty", type: "em" });
      setAdvanceFormItem(advanceFormItem);
    }
  }, []);

  const columns: any = [
    {
      title: (
        <p
          style={{
            fontSize: "13px",
            lineHeight: "15px",
            marginBottom: "2px",
            color: "rgb(38, 42, 45)",
            fontWeight: "700",
          }}
        >
          <TextHeaderComponents
            textHeaderProps={"Sequence"}
            textSubProps={"ลำดับ"}
          />
        </p>
      ),
      dataIndex: "seq",
      key: "seq",
    },
    {
      title: (
        <p
          style={{
            fontSize: "13px",
            lineHeight: "15px",
            marginBottom: "2px",
            color: "rgb(38, 42, 45)",
            fontWeight: "700",
          }}
        >
          <TextHeaderComponents
            textHeaderProps={"Logic Type"}
            textSubProps={"ประเภท"}
          />
        </p>
      ),
      dataIndex: "logictype",
      key: "logictype",
      // width: "10%",
    },
    {
      title: (
        <p
          style={{
            fontSize: "13px",
            lineHeight: "15px",
            marginBottom: "2px",
            color: "rgb(38, 42, 45)",
            fontWeight: "700",
          }}
        >
          <TextHeaderComponents
            textHeaderProps={"Json Object"}
            textSubProps={"เงื่อนไข"}
          />
        </p>
      ),
      dataIndex: "jsonvalue",
      key: "jsonvalue",
      render: (record: any) => (
        <>{record ? record.substring(0, 100) + "..." : ""}</>
      ),
    },
    {
      title: (
        <p
          style={{
            fontSize: "13px",
            lineHeight: "15px",
            marginBottom: "2px",
            color: "rgb(38, 42, 45)",
            fontWeight: "700",
          }}
        >
          <TextHeaderComponents
            textHeaderProps={"Description"}
            textSubProps={"รายละเอียด"}
          />
        </p>
      ),
      dataIndex: "description",
      key: "description",
      render: (_: any, record: any, idx: any) => {
        return <>{_}</>;
      },
      width: 400,
    },
    {
      title: (
        <>
          <FiPlus
            onClick={() => {
              form.resetFields();

              setSelectedRecord({
                Logicid: "-1",
                TemplateId: templateId,
                Seq: 1,
                Logictype: "actionservice",
                Jsonvalue: JSON.stringify({
                  MatchSAPField: [],
                  Event: [],
                  Condition: {
                    FormatDate: [],
                    ChangeField: [],
                    FormatReplace: [],
                  },
                  API: {
                    Header: [],
                    Link: "",
                  },
                  Return: [],
                  ReturnMessage: {
                    HEAD: "",
                    TYPE: "",
                    OPERATOR: "",
                    CONDITIONERROR: {
                      KEY: "",
                      TYPE: "",
                      VALUE: "",
                    },
                    ALLOW: false,
                    MESSAGE_KEY: "",
                  },
                  AutoSave: true,
                }),
              });
            }}
          />
        </>
      ),
      dataIndex: "action",
      className: "action-column",
      key: "action",
      align: "center",
      width: "15%",
      render: (_: any, record: any, idx: any) => {
        if (record.jsonvalue !== undefined) {
          const json = JSON.parse(record.jsonvalue);
          return (
            <Space size={"small"}>
              <ButtonComponents
                setIconProps={<EyeOutlined rev={undefined} />}
                onClickProps={() => {
                  modal.confirm({
                    icon: <></>,
                    width: "50%",
                    title: "View Json",
                    okType: "primary",
                    cancelButtonProps: { style: { display: "none" } },
                    content: (
                      <>
                        <Divider />
                        <pre
                          style={{
                            overflowX: "auto",
                            backgroundColor: "#f4f4f4",
                            padding: "10px",
                            borderRadius: "5px",
                          }}
                        >
                          {JSON.stringify(json, null, 2)}
                        </pre>
                      </>
                    ),
                  });
                }}
              />
              <ButtonComponents
                setIconProps={<EditOutlined rev={undefined} />}
                onClickProps={() => {
                  const jsonValue = JSON.parse(record?.jsonvalue);

                  form.setFieldsValue({
                    ...record,
                    ...jsonValue,
                  });
                  setSelectedRecord(record);
                }}
              />
              <ButtonComponents
                setIconProps={"pi pi-trash"}
                setClassNameProps={
                  " p-button-danger p-mr-2 set-icon-LineApprovalsComponent"
                }
                onClickProps={() => {
                  if (record.Logicid !== "-1") {
                    setControlModel((prevState: any) => ({
                      ...prevState,
                      cMSTTemplateLogic: prevState.cMSTTemplateLogic.filter(
                        (e: any) => {
                          return e.logicid !== record.logicid;
                        }
                      ),
                    }));
                  } else {
                    setControlModel((prevState: any) => ({
                      ...prevState,
                      cMSTTemplateLogic: prevState.cMSTTemplateLogic.filter(
                        (e: any) => {
                          return e.seq !== record.seq;
                        }
                      ),
                    }));
                  }
                }}
              />
            </Space>
          );
        }
      },
    },
  ];
  const onFinish = (values: any) => {
    const type = values.logictype;
    const description = values.description;
    console.log(values);

    if (type) {
      let updatedJson = JSON.parse(selectedRecord.jsonvalue);
      updatedJson = { ...updatedJson, ...values };
      delete updatedJson.logictype;
      delete updatedJson.description;
      console.log(selectedRecord);

      if (selectedRecord.logicid !== "-1") {
        setControlModel((prevState: any) => ({
          ...prevState,
          cMSTTemplateLogic: prevState.cMSTTemplateLogic.map((e: any) => {
            if (e.logicid === selectedRecord.logicid) {
              e.logictype = type;
              e.description = description;
              e.jsonvalue = JSON.stringify(updatedJson);
            }
            return e;
          }),
        }));
      } else {
        const item = {
          ...selectedRecord,
          ...{
            logictype: type,
            description: description,
            jsonvalue: JSON.stringify(updatedJson),
          },
        };

        setControlModel((prevState: { cMSTTemplateLogic: any }) => ({
          ...prevState,
          cMSTTemplateLogic: [...prevState.cMSTTemplateLogic, item],
        }));
      }
      form.resetFields();
      setSelectedRecord(undefined);
    }
  };

  return (
    <>
      {contextHolder}

      <Row className="sub-header">
        <Col xs={24} md={24} lg={24} xl={24} style={{ alignItems: "center" }}>
          <p
            className="Col-text-header-Inform"
            style={{ fontWeight: 500, color: "#262A2D" }}
          >
            Template Logic
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={24} md={24} lg={24} xl={24}>
          <p className="informationComponents-line-border"></p>
        </Col>
      </Row>
      <Row className="gutter-row" style={{ width: "100%" }}>
        <Col xs={24} md={24} lg={24} xl={24}>
          <Table
            size="small"
            columns={columns}
            dataSource={dataLogic.filter(
              (e) =>
                e.logictype === LogicType.ActionService ||
                e.Logictype === LogicType.ActionService
            )}
            scroll={{ x: "80%" }}
          />
        </Col>
      </Row>
      {selectedRecord && (
        <Form
          form={form}
          onFinish={onFinish}
          labelAlign={"left"}
          labelCol={{ span: 8 }}
        >
          <Row justify="end">
            <Col span={3}>
              <ButtonComponents
                setStyleProps={{
                  width: "150px",
                  borderRadius: "6px",
                  boxShadow: "none",
                  border: "1px solid #282f6a",
                  fontSize: "13px",
                  paddingLeft: "16px",
                }}
                typeProps={"submit"}
                setLabelProps={"Submit"}
                setIconProps={<FiPlus />}
                setClassNameProps={"p-button-text-position"}
              />
            </Col>
            <Col span={3}>
              <ButtonComponents
                setStyleProps={{
                  width: "150px",
                  borderRadius: "6px",
                  boxShadow: "none",
                  fontSize: "13px",
                  paddingLeft: "16px",
                  backgroundColor: "red",
                  border: "1px solid red",
                }}
                setClassNameProps={"p-button-text-position"}
                setLabelProps={"Cancel"}
                setIconProps={<CloseOutlined rev={undefined} />}
                onClickProps={() => {
                  setSelectedRecord(undefined);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} lg={24} xl={24}>
              <ActionserviceForm
                templateName={templateName}
                advanceFormItem={advanceFormItem}
                form={form}
              />
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default ActionserviceTemplate;
