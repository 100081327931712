import React, { useEffect, useState } from "react";
import "./FooterComponents.css";
import { getVersion } from "../../Services/ConfigurationService";
interface Props {}

export const FooterComponents = (props: Props) => {
  const [webVersion, setWebVersion] = useState<any>();
  const [apiVersion, setAPIVersion] = useState<any>();
  useEffect(() => {
    fetchVersion();
  }, []);
  const fetchVersion = async () => {
    const response = await getVersion();
    setWebVersion(response.webVersion);
    console.log('webVersion', response.webVersion);
    setAPIVersion(response.apiVersion);
    console.log('apiVersion', response.apiVersion);
  };

  return (
    <div className="footerComponents-padding-div">
      <p className="footerComponents-border"></p>
      <div className="footerComponents-padding">
        <p>
          We recommend using{" "}
          <label className="footerComponents-color-a">Microsoft Edge</label> or{" "}
          <label className="footerComponents-color-a">Google Chrome</label> at
          this time.
        </p>
        <p>© WOLF R2 v{webVersion} | API v{apiVersion}</p>
      </div>
    </div>
  );
};
