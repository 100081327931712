import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSessionContext } from "../../Context/AuthContext";
import { Carousel, Spin } from "antd";
import { useUserContext } from "../../Context/UserContext";
import { deleteAllCookies } from "../../Helper/DeleteAllCookie";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";

interface Props {
  pathLogo: any;
  pathCarousel: any;
}

export const LoginSSUP = (props: Props) => {
  const [onLoading, setOnLoading] = useState<boolean>(false);
  const [userData, setUserData] = useUserContext();
  const [remarkValid, setRemarkValid] = useState("");
  const { i18n } = useTranslation(["translation"]);
  const [sessionState, setSessionState] = useSessionContext();
  const history = useHistory();
  const location = useLocation();

  const cookies = new Cookies();
  
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const accessToken = searchParams.get("access_token");
    if (accessToken != null && accessToken.trim().length > 0) {
      onCallback(accessToken);
    } else if (sessionState.isAuthenticated) {
      history.push("/Default");
    }
  }, []);

  const onSubmit = async () => {
    setOnLoading(true);
    try {
      const { redirect: authorizeUrl } = await (
        await fetch("api/Login/Authorize/SSUPLogin")
      ).json();
      window.location.href = authorizeUrl;
    } catch (e) {
      console.error(e);
      setOnLoading(false);
    }
  };

  const onCallback = async (accessToken: string) => {
    setOnLoading(true);
    try {
      const _baseUrl = window.location.hostname;
      const TmpUrl =
        process.env.NODE_ENV === "development"
          ? process.env.REACT_APP_BASE_URL || "qar2.wolfapprove.com"
          : _baseUrl;
      const response = await (
        await fetch(
          `api/Login/Authorize/SSUPCallback?access_token=${accessToken}&TmpUrl=${encodeURIComponent(
            TmpUrl
          )}`
        )
      ).json();
      localStorage.removeItem("userData");
      localStorage.removeItem("tinyUrl");
      localStorage.removeItem("userData");
      localStorage.removeItem("tinyUrl");
      cookies.remove("GuidVerify");
      cookies.set("GuidVerify", response.GuidVerify, {
        path: "/",
        expires: new Date(Date.now() + 86400000),
      });
      ActionPassLogin(response);
    } catch (e) {
      console.error(e);
      setOnLoading(false);
    }
  };

  const ActionPassLogin = async (_loginWolfAccount: any) => {
    if (_loginWolfAccount === false) {
      localStorage.clear();
      deleteAllCookies();
      setRemarkValid("ไม่พบผู้ใช้ในระบบกรุณาลองใหม่อีกครั้ง");
      setOnLoading(false);
      return;
    }
    const fav_storage = JSON.parse(localStorage.getItem("favorite") || "null");
    if (fav_storage !== null) {
      if (fav_storage.length > 0) {
        localStorage.setItem("favorite", JSON.stringify(fav_storage));
      }
    } else {
      localStorage.setItem("favorite", JSON.stringify([]));
    }
    if (_loginWolfAccount?.employeeData?.Lang === "TH") {
      i18n.changeLanguage("th");
    } else {
      i18n.changeLanguage("en");
    }
    setUserData(_loginWolfAccount?.employeeData);
    localStorage.setItem("userData", JSON.stringify(_loginWolfAccount));
    localStorage.setItem(
      "employeeId",
      _loginWolfAccount.employeeData.EmployeeId
    );
    localStorage.setItem(
      "employeeCode",
      _loginWolfAccount.employeeData.EmployeeCode
    );
    localStorage.setItem("lang", _loginWolfAccount.employeeData.Lang);
    localStorage.setItem("nameEn", _loginWolfAccount.employeeData.NameEn);
    localStorage.setItem("nameTh", _loginWolfAccount.employeeData.NameTh);
    localStorage.setItem("email", _loginWolfAccount.employeeData.Email);
    localStorage.setItem(
      "positionNameTh",
      _loginWolfAccount.employeeData.PositionNameTh
    );
    localStorage.setItem(
      "positionNameEn",
      _loginWolfAccount.employeeData.PositionNameEn
    );
    localStorage.setItem(
      "departmentId",
      _loginWolfAccount.employeeData.DepartmentId
    );
    localStorage.setItem(
      "departmentNameTh",
      _loginWolfAccount.employeeData.DepartmentNameEn
    );
    localStorage.setItem("tinyUrl", _loginWolfAccount.TinyURL);

    setSessionState({ ...sessionState, isAuthenticated: true });
    setOnLoading(false);
    if (
      localStorage.getItem("memoid") &&
      localStorage.getItem("memoid") !== ""
    ) {
      const id = localStorage.getItem("memoid");
      localStorage.removeItem("memoid");
      history.push("/Request?MemoID=" + id);
    } else {
      history.push("/Default");
    }
  };

  return (
    <div className="login-screens">
      <div className="login-container">
        <div className="image-slider-container">
          <Carousel autoplay className="img-carousel">
            {props.pathCarousel.map((_carousel: any, idx: number) => {
              return (
                <div className="slide-content">
                  <img src={_carousel} alt="slide" />
                </div>
              );
            })}
          </Carousel>
        </div>

        <div className="main-azure-container">
          {onLoading ? (
            <div className="loading-container">
              <Spin size="large" />
              <p className="text-wait">กำลังเข้าสู่ระบบ</p>
            </div>
          ) : (
            <div className="right-card-container">
              <img src={props.pathLogo} alt="logo-login" className="img-logo" />

              <div className="button-container">
                <button className="login-button" onClick={() => onSubmit()}>
                  Log In
                </button>
                <p className="text-incorrect">{remarkValid}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
