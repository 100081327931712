import React, { useEffect, useState } from "react";

import { Col, Row } from "react-bootstrap";
import "./ButtonComponent.css";
import { useHistory } from "react-router";
import { confirmDialog } from "primereact/confirmdialog";
import ComponentLabel from "../ComponentLabel";
import { Button } from "antd";
interface Props {
  template: any;
  data: any;
  col?: any;
  rowIdx: number;
  colIdx: number;
  onChangeEditForm?: (dataRequest: any, rowIdx: number, colIdx: number) => void;
  colText?: number;
  colAction?: number;
  documentNo: any;
  renderInTable?: boolean;
  errorValid?: any;
  statusMemoDetail?: boolean;
  name: any;
  control: any;
  canEditDoc: boolean;
  onCheckInterface:any; 
}
export default function ButtonSpecialComponent(props: Props) {
  const [loadingInterface, setLoadingInterface] = useState<boolean>(false);
  return (
    <>
      {/* <ComponentLabel
        renderInTable={props.renderInTable}
        col={props.col}
        colText={props.colText}
        rowIdx={props.rowIdx}
        colIdx={props.rowIdx}
        template={props.template}
      /> */}
      <Col 
        sm={12}
        md={12}
        xs={12}
        xl={12}
        className={
          props.renderInTable === undefined ? "padding-controller" : ""
        }
      >
        <div style = {{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}>
          <Button
            loading={loadingInterface}
            type = "primary"
            htmlType={"button"}
            onClick={() => {
              props.onCheckInterface(props.template.label, setLoadingInterface);
            }}
            >{props.template.alter}</Button>
        </div>
      </Col>
    </>
  );
}
