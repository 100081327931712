import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Menu, Modal, Row, Table } from "antd";
import { ButtonComponents } from "../../ButtonComponents/ButtonComponents";
import { FiPlus } from "react-icons/fi";
import { LogicType } from "../../../constants/logic-type";
import { ILogic, labelAction } from "../../../IRequestModel/ILogicModel";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { RiFileCopy2Line } from "react-icons/ri";
import JsonLogicForm from "./JsonLogicForm";
import {
  advanceFormItemType,
  enumToObject,
  getTranFormAdvanceForm,
} from "../../../Helper/LogicHelper";
import { useLocation } from "react-router";

type Props = {
  advanceForm: any;
  datalogic: any[];
  setControlModel: (data: any) => void;
};

type AllLogic = {
  key: number;
  type: LogicType;
  actionControl: string;
};

const FormLogicComponent = ({
  advanceForm,
  datalogic,
  setControlModel,
}: Props) => {
  const [allLogic, setAllLogic] = useState<AllLogic[]>([]);
  const [modal, contextHolder] = Modal.useModal();
  const [advanceFormItem, setAdvanceFormItem] = useState<advanceFormItemType[]>(
    []
  );
  const [_dataLogics, set_dataLogics] = useState<any[]>([]);
  const query = new URLSearchParams(useLocation().search);
  const [form] = Form.useForm();
  const readableLogicType = enumToObject(LogicType);

  useEffect(() => {
    const tranformData = () => {
      try {
        const lowercaseKeysArray = datalogic
          .filter(
            (e) =>
              e.logictype === LogicType.HideControl ||
              e.logictype === LogicType.ReadOnlyControl ||
              e.logictype === LogicType.DataSourceLoad ||
              e.logictype === LogicType.DataSourceRelated ||
              e.logictype === LogicType.DataRelatedToLoadData ||
              e.logictype === LogicType.Role ||
              e.logictype === LogicType.SpecialActionService ||
              e.logictype === LogicType.SetValueControl ||
              e.Logictype === LogicType.HideControl ||
              e.Logictype === LogicType.ReadOnlyControl ||
              e.Logictype === LogicType.DataSourceLoad ||
              e.Logictype === LogicType.DataSourceRelated ||
              e.Logictype === LogicType.DataRelatedToLoadData ||
              e.Logictype === LogicType.Role ||
              e.Logictype === LogicType.SpecialActionService ||
              e.Logictype === LogicType.SetValueControl
          )
          .map((obj) =>
            Object.fromEntries(
              Object.entries(obj).map(([key, value]) => [
                key.toLowerCase(),
                value,
              ])
            )
          );

        const logics: AllLogic[] = lowercaseKeysArray.map(
          (logic: any, idx: any) => {
            const jsonValue = JSON.parse(logic?.jsonvalue);

            if (jsonValue?.labelactions) {
              const labelaction: labelAction[] = jsonValue.labelactions;

              return {
                key: logic.seq,
                type: logic.logictype,
                logicid: logic.logicid,
                actionControl: labelaction.map((e) => e.label).join(","),
              };
            } else if (jsonValue?.actionControl) {
              const labelaction: labelAction = JSON.parse(
                jsonValue.actionControl
              );

              return {
                key: logic.seq,
                type: logic.logictype,
                logicid: logic.logicid,
                actionControl: labelaction.label,
              };
            } else {
              return {
                key: logic.seq,
                logicid: logic.logicid,
                type: logic.logictype,
                actionControl: jsonValue.label,
              };
            }
          }
        );
        set_dataLogics([...lowercaseKeysArray]);
        setAllLogic([...logics]);
      } catch (error) {
        console.error(error);
      }
    };
    if (advanceForm) {
      const advanceFormItem = getTranFormAdvanceForm(advanceForm);
      setAdvanceFormItem(advanceFormItem);
    }
    tranformData();
  }, [datalogic]);
  const onClickAddButton = (action: string, record?: any) => {
    form.resetFields();

    if (record) {
      const logic = _dataLogics.find((e) =>
        record !== "-1" ? e.seq === record.key : e.logicid === e.logicid
      );

      const jsonValue = JSON.parse(logic?.jsonvalue);
      if (logic.logictype === LogicType.DataRelatedToLoadData) {
        form.setFieldsValue({
          ...jsonValue,
          labelactions: jsonValue?.labelactions?.map(
            (e: { label: any }) => e.label
          ),
          type: logic.logictype,
        });
      } else if (logic.logictype === LogicType.SpecialActionService) {
        form.setFieldsValue({
          ...jsonValue,
          showaction: jsonValue.showaction.map((e: any) => e.status),
          type: logic.logictype,
        });
      } else if (logic.logictype === LogicType.SetValueControl) {
        form.setFieldsValue({
          ...jsonValue,
          type: logic.logictype,
        });
      } else if (logic.logictype === LogicType.Role) {
        form.setFieldsValue({
          ...jsonValue,
          roleids: jsonValue.roleids.map((e: any) => {
            if (!e.id) return e;
            return e.id;
          }),
          fieldaction: jsonValue.fieldaction.map((e: any) => JSON.stringify(e)),
          type: logic.logictype,
        });
      } else if (
        logic.logictype === LogicType.HideControl ||
        logic.logictype === LogicType.ReadOnlyControl
      ) {
        let conditions = JSON.stringify(jsonValue.conditions);
        if (conditions) {
          if (!conditions?.startsWith('[{"conditions":')) {
            let mainConditions = JSON.parse(conditions);

            mainConditions = mainConditions.map((e: { field: any }) => {
              let field = e.field;
              if (typeof field === "string") {
                field = JSON.parse(field);
              }
              e.field = field.label;

              return { conditions: [e], description: null };
            });
            jsonValue.conditions = mainConditions;
          }
        }

        form.setFieldsValue({
          ...jsonValue,
          type: logic.logictype,
        });
      } else {
        form.setFieldsValue({
          ...jsonValue,
          type: logic.logictype,
        });
      }
    }

    modal.confirm({
      icon: <></>,
      width: "80%",
      title: <h4>{action} Logic</h4>,
      content: <JsonLogicForm form={form} advanceFormItem={advanceFormItem} />,
      onOk: () => {
        try {
          let _templateId: string = query.get("TemplateId") || "";
          let formData = form.getFieldsValue();
          const logicType = formData.type;
          if (logicType === LogicType.DataSourceRelated) {
            const selected = formData.label.split("|");

            formData.relatedvalue = formData.relatedvalue.map((e: any) => {
              const splitLabel = e.label.split("|");
              e.label = splitLabel[0];
              return e;
            });
            formData.label = selected[0];
            formData.tablestatus = selected[1] !== undefined;
            formData.fields = formData.relatedvalue.map((e: any) => {
              return { field: e.value };
            });
          } else if (logicType === LogicType.DataSourceLoad) {
          } else if (logicType === LogicType.DataRelatedToLoadData) {
            formData.labelactions = formData.labelactions.map((e: any) => {
              const splitLabel = e.split("|");
              const isInTable = splitLabel[1] !== undefined ? "true" : "false";

              return { label: splitLabel[0], labelintablestatus: isInTable };
            });
          } else if (logicType === LogicType.SpecialActionService) {
            formData.showaction = formData.showaction.map((e: string) => {
              return { status: e };
            });
          } else if (logicType === LogicType.Role) {
            formData.roleids = formData.roleids.map((e) => {
              return { id: e };
            });
            formData.fieldaction = formData.fieldaction.map((e: any) => {
              const objValue = JSON.parse(e);
              return objValue;
            });
          }

          delete formData.type;

          if (action === "Edit") {
            setControlModel((prevState: any) => {
              return {
                ...prevState,
                cMSTTemplateLogic: prevState.cMSTTemplateLogic.map(
                  (e: any, idx: any) => {
                    if (idx === record.key) {
                      e.Jsonvalue = JSON.stringify(formData);
                      e.logictype = logicType;
                    }
                    return e;
                  }
                ),
              };
            });
          } else {
            const dataLogic: ILogic = {
              logicid: "",
              ShowHideActionType: "",
              Seq: 0,
              TemplateId: Number(_templateId),
              jsonvalue: JSON.stringify(formData),
              logictype: logicType,
            };

            setControlModel((prevState: { cMSTTemplateLogic: any }) => ({
              ...prevState,
              cMSTTemplateLogic: [...prevState.cMSTTemplateLogic, dataLogic],
            }));
          }
        } catch (error) {
          console.error(error);
        }
      },
    });
  };

  return (
    <>
      {contextHolder}
      <Row className="0">
        <Col span={24} style={{ alignItems: "center" }}>
          <p className="Col-text-header-Inform">Form Logic List</p>
          <p className="informationComponents-line-border"></p>
        </Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col>
          <ButtonComponents
            setStyleProps={{
              width: "150px",
              borderRadius: "6px",
              boxShadow: "none",
              border: "1px solid #282f6a",
              fontSize: "13px",
              paddingLeft: "16px",
            }}
            onClickProps={() => onClickAddButton("Add")}
            setLabelProps={"Add Logic"}
            setIconProps={<FiPlus />}
            setClassNameProps={"p-button-text-position"}
          />
        </Col>
        <Col span={24}>
          <Table
            dataSource={allLogic}
            columns={[
              {
                title: "#",
                dataIndex: "key",
                render: (value, record, index) => value + 1,
                width: 50,
              },
              {
                title: "Logic type",
                key: "logictype",
                dataIndex: "type",
                render: (value) => readableLogicType[value],
              },
              {
                title: "Action control",
                key: "actioncontrol",
                render: (value, rowData: any) => {
                  return rowData["actionControl"] || rowData["labelaction"];
                },
              },
              {
                title: "Relate control",
                key: "relatecontrol",
                dataIndex: "relateControl",
              },

              {
                title: "Edit",
                width: 50,
                align: "center",
                render: (value, record, index) => {
                  return (
                    <button
                      className="table-button"
                      onClick={() => onClickAddButton("Edit", record)}
                    >
                      <EditOutlined rev={undefined} />
                    </button>
                  );
                },
              },
              {
                title: "Delete",
                width: 50,
                align: "center",
                dataIndex: "key",
                render: (value, record, index) => {
                  return (
                    <button
                      className="table-button"
                      onClick={() => {
                        const updatedAllLogic = datalogic.filter(
                          (e, idx) => idx !== value
                        );

                        setControlModel((prevState: any) => ({
                          ...prevState,
                          cMSTTemplateLogic: updatedAllLogic,
                        }));
                      }}
                    >
                      <DeleteOutlined rev={undefined} />
                    </button>
                  );
                },
              },
            ]}
          />
        </Col>
      </Row>
    </>
  );
};

export default FormLogicComponent;
