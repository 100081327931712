import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import "../../RequestComponents/InformationComponent/InformationComponent.css";
import { Col, Row } from "react-bootstrap";
import { InputText } from "primereact/inputtext";
import { TextHeaderComponents } from "../../TextHeaderComponents/TextHeaderComponents";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import "../ReferenceDocumentComponents/ReferenceDocumentComponents.css";
import { Button } from "antd";
interface Props {
  name: string;
  dataKey: string;
  dataList: any;
  columns: any[];
  loading: boolean;
  setLoading: any;
  updateData: any;
}

function SelectDataFormTable(props: Props) {
  const [globalFilterValue1, setGlobalFilterValue1] = useState<any>("");
  const userData = JSON.parse(
    window.localStorage.getItem("userData") || "null"
  );
  const [filters1, setFilters1] = useState<any>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilter, setGlobalFilter] = useState(
    Object.keys(props.dataList[0])
  );
  const [selectedData, setSelectedData] = useState<any[]>([]);

  const dynamicColumns = props.columns.map((col: any) => {
    let field = "";
    if (userData.employeeData.Lang === "EN") {
      field = col.field.replace("Th", "En");
    } else {
      field = col.field.replace("En", "Th");
    }
    return <Column key={field} field={field} header={col.header} />;
  });
  console.log(selectedData);

  return (
    <Row>
      <Col className="row-formgroup">
        <Row>
          <Col xs={1} sm={1} xl={1}>
            <TextHeaderComponents
              textHeaderProps={"Search"}
              textSubProps={"ค้นหา"}
            />
          </Col>
          <Col xs={11} sm={11} xl={11}>
            <InputText
              onChange={(e: any) => {
                const value = e.target.value;
                let _filters1 = { ...filters1 };
                _filters1["global"].value = value;
                setFilters1(_filters1);
                setGlobalFilterValue1(value);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <DataTable
              id={"table"}
              value={props.dataList}
              paginator
              size="small"
              filters={filters1}
              rows={5}
              rowHover
              dataKey={props.dataKey}
              selection={selectedData}
              selectionMode="multiple"
              globalFilterFields={globalFilter}
              // onRowClick={(e: any) => {
              //   props.updateData(e.data);
              // }}
              onSelectionChange={(e) => {
                setSelectedData(e.value);
              }}
              responsiveLayout="scroll"
            >
              <Column
                selectionMode="multiple"
                headerStyle={{ width: "3em" }}
              ></Column>
              {dynamicColumns}
            </DataTable>
          </Col>
        </Row>
        <Row>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              style={{
                border: "0.3px solid green",
                borderRadius: 6,
                backgroundColor: "green",
              }}
              onClick={() => {
                selectedData.forEach((e) => {
                  props.updateData(e);
                });
              }}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default SelectDataFormTable;
