import { useContext } from "react";
import { MemoPageContext } from "../Context/MemoPageContext";

const useMemoPage = () => {
  const context = useContext(MemoPageContext);
  if (!context)
    throw new Error("MemoPage Context  must be use inside MemoPage Provider");
  return context;
};

export default useMemoPage;
