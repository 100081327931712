import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import ComponentLabel from "../../ControlComponents/ComponentLabel";
import { Col } from "react-bootstrap";
import { Controller, UseFormGetValues } from "react-hook-form";
import { TableInputProps } from "../TableComponent/TableComponent";
import { ILogicRequestBody } from "../../../IRequestModel/ILogicModel";
import {
  checkRequireFromTemplate,
  dataReleteToDD,
  getRefInforValue,
} from "../../../Helper/RequestScreenHelper";
import { formatKeyLogicData } from "../../../Helper/formatKeyLogicData";

type InputProps = {
  template: any;
  data: any;
  rowIdx: number;
  colIdx: number;
  col?: any;
  colText?: number;
  colAction?: number;
  name: string;
  control: any;
  onControlChange: (controlTemplate: any, controlValue: any) => any;
  controlUpdate: any;
  canEditDoc: boolean;
  buttonType: string;
  checkActionPage: string;
  actionAlert?: any;
  allLogicInTable: any[];
  getValues?: UseFormGetValues<{
    items: any;
  }>;
};

type SelectDropdownProps = {
  ref?: any;
  template: any;
  value?: any;
  status?: any;
  onChange: (value: any, type: string) => void;
  canEditDoc: boolean;
  checkActionPage: string;
  onDropdownVisibleChange?: (open: boolean) => void;
  tableOptions?: any[];
  actionAlert?: any;
  loading?: boolean;
};

const { Option } = Select;

export const SelectWithTableDropdownComponent: React.FC<
  SelectDropdownProps
> = ({
  template,
  value,
  canEditDoc,
  onChange,
  tableOptions,
  actionAlert,
  onDropdownVisibleChange,
  loading,
}) => {
  const [action, setAction] = useState<boolean>(false);
  useEffect(() => {
    checkStatus();
  }, [actionAlert]);

  const checkStatus = () => {
    if (actionAlert) {
      if (actionAlert.Data) {
        actionAlert.Data.map((data: any) => {
          if (
            actionAlert.ErrorType === "SpecialError" &&
            data.Label === template.label &&
            data.Value === value
          ) {
            setAction(true);
          }
        });
      }
    }
  };

  // console.log("actionAlert=>", actionAlert);
  return (
    <>
      <Select
        showSearch
        value={
          value !== "" && value !== null
            ? value
            : template.attribute.items && template.attribute.items.length > 0
            ? template.attribute.items[0].item
            : "--select--"
        }
        onDropdownVisibleChange={onDropdownVisibleChange}
        placeholder={template.attribute.description}
        optionFilterProp="children"
        loading={loading}
        onChange={(value) => onChange(value, "dd")}
        disabled={
          !canEditDoc ||
          template.attribute.readonly === "Y" ||
          template.readonly === "Y"
        }
        filterOption={(input, option) =>
          (option!.children as unknown as string)
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        style={{
          textAlign: template.attribute.align === "r" ? "right" : "left",
          height: 38,
        }}
        size={"large"}
        status={action ? "error" : ""}
      >
        {tableOptions
          ? tableOptions?.map((option: any) => (
              <Option value={option.item}>{option.item}</Option>
            ))
          : template.attribute?.items?.map((option: any) => (
              <Option value={option.item}>{option.item}</Option>
            ))}
      </Select>
    </>
  );
};
export const SelectDropdownComponent: React.FC<SelectDropdownProps> = ({
  template,
  value,
  status,
  onChange,
  canEditDoc,
  tableOptions,
  onDropdownVisibleChange,
}) => {
  return (
    <>
      <Select
        showSearch
        disabled={
          !canEditDoc ||
          template.attribute.readonly === "Y" ||
          template.readonly === "Y"
        }
        // disabled={!canEditDoc || template.attribute.readonly === "Y"}
        value={value && value !== "" && value}
        placeholder={template.attribute.description}
        optionFilterProp="children"
        onChange={(value: any) => onChange(value, "dd")}
        onDropdownVisibleChange={onDropdownVisibleChange}
        filterOption={(input, option) =>
          (option!.children as unknown as string)
            ?.toLowerCase()
            ?.includes(input.toLowerCase())
        }
        style={{
          textAlign: template.attribute.align === "r" ? "right" : "left",
        }}
        size={"large"}
        status={status && "error"}
      >
        {tableOptions
          ? tableOptions?.map((option: any) => (
              <Option value={option.item}>{option.item}</Option>
            ))
          : template.attribute?.items?.map((option: any) => (
              <Option value={option.item}>{option.item}</Option>
            ))}
      </Select>
    </>
  );
};
export const SelectDropdownControlComponent: React.FC<InputProps> = ({
  allLogicInTable,
  colIdx,
  rowIdx,
  control,
  name,
  template,
  col,
  colAction,
  colText,
  onControlChange,
  canEditDoc,
  checkActionPage,
  buttonType,
  actionAlert,
  getValues,
}) => {
  const [customOptions, setCustomOptions] = useState<any[]>();
  const [labelActionLogic, setLabelActionLogic] = useState<string>();
  const [loading, setLoading] = useState(false);

  const logicRequest = (des: any) => {
    try {
      if (allLogicInTable && getValues) {
        let conditions: ILogicRequestBody[] = allLogicInTable.filter(
          (e) => e.actionlabel === template.label
        );

        const allVal = getValues();

        if (conditions.length > 0) {
          setCustomOptions(undefined);

          setLoading(true);
          let labelactionfield: string = "";
          dataReleteToDD(
            conditions.map((con) => {
              labelactionfield = con.labelactionfield;
              if (con.Key !== "") {
                allVal.items.forEach((item: any) => {
                  item.layout.forEach((_layout: any) => {
                    if (
                      _layout?.template?.label &&
                      _layout?.template?.label === con.Key &&
                      (_layout?.template?.description || "") === (des || "")
                    ) {
                      con.Value = _layout.data.value;
                    }
                  });
                });
              }
              return con;
            })
          ).then((val) => {
            const res = formatKeyLogicData({
              data: val,
              labelactionfield: labelactionfield,
            });

            if (res) {
              setCustomOptions([...res.data]);
            }
            setLoading(false);
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: (value) => {
          if (canEditDoc && checkRequireFromTemplate(template, buttonType)) {
            if (value.data.value) {
              if (
                value.data.value !== "---Select---" &&
                value.data.value !== "-- Please Select --" &&
                value.data.value !== "--Please Select--" &&
                value.data.value !== "--select--"
              ) {
                return true;
              } else if (
                template.attribute.require === "" ||
                template.attribute.require === "N"
              ) {
                return true;
              } else {
                return false;
              }
            } else if (!value.data.value) {
              if (
                !template.attribute.require ||
                template.attribute.require === "" ||
                template.attribute.require === "N"
              ) {
                return true;
              } else {
                return false;
              }
            }
          }
        },
      }}
      render={({
        field: { onChange, value },
        formState: { errors, isSubmitted },
      }) => {
        const _value = value;
        return (
          <>
            <ComponentLabel
              col={col}
              colText={colText}
              rowIdx={rowIdx}
              colIdx={rowIdx}
              template={template}
            />
            <Col
              sm={col === undefined ? 12 : 12}
              md={col === undefined ? colAction : 12}
              xs={col === undefined ? 12 : 12}
              xl={col === undefined ? colAction : 12}
              className={"padding-controller"}
            >
              <div
                className={`input-component-container  ${
                  isSubmitted &&
                  errors?.items &&
                  errors?.items[rowIdx] &&
                  errors?.items[rowIdx].layout[colIdx]
                    ? "set-layout-required"
                    : ""
                }`}
              >
                <SelectDropdownComponent
                  {...{ canEditDoc, checkActionPage }}
                  value={_value.data.value}
                  template={_value.template}
                  onChange={(value) => {
                    onChange({
                      ..._value,
                      data: {
                        value,
                      },
                    });
                    onControlChange(template, value);
                  }}
                  tableOptions={customOptions}
                  loading={loading}
                  onDropdownVisibleChange={(open) => {
                    if (open) {
                      logicRequest(value?.template?.description);
                    } else {
                      setCustomOptions(undefined);
                      setLoading(false);
                    }
                  }}
                  actionAlert={actionAlert}
                  status={
                    isSubmitted &&
                    ((errors?.items &&
                      errors?.items[rowIdx] &&
                      errors?.items[rowIdx].layout[colIdx]) ||
                      _value === "--select--")
                  }
                />

                {isSubmitted &&
                  errors?.items &&
                  errors?.items[rowIdx] &&
                  errors?.items[rowIdx].layout[colIdx] && (
                    <small id="Name-help" className="p-error p-d-block">
                      {template.label} is required.
                    </small>
                  )}
              </div>
            </Col>
          </>
        );
      }}
    />
  );
};

export const SelectDropdownTableComponent: React.FC<TableInputProps> = ({
  name,
  template,
  saveFunc,
  isEditing,
  onEdit,
  children,
  canEditDoc,
  checkActionPage,
  record,
  allLogicInTable,
  actionAlert,
  getValues,
}) => {
  const [customOptions, setCustomOptions] = useState<any[]>();
  const [loading, setLoading] = useState(false);

  if (!isEditing) {
    return (
      <div onMouseOver={onEdit}>
        <SelectWithTableDropdownComponent
          {...{ canEditDoc, checkActionPage }}
          value={children[1]}
          template={template}
          onChange={saveFunc}
          tableOptions={customOptions}
          actionAlert={actionAlert}
        />
      </div>
    );
  }

  const logicRequest = (des: string) => {
    if (allLogicInTable && getValues) {
      const allVal = getValues();
      let conditions: ILogicRequestBody[] = allLogicInTable.filter(
        (e: any) =>
          e.actionlabel === template.label ||
          e.actionlabel?.startsWith(template.label + "|")
      );

      if (conditions.length > 0) {
        setLoading(true);
        let labelactionfield: string = "";

        dataReleteToDD(
          conditions.map((con) => {
            if (con.Key !== "" && record[con.Key]) {
              con.Value = record[con.Key];
            } else if (con.Key !== "") {
              allVal.items.forEach((item: any) => {
                item.layout.forEach((_layout: any) => {
                  if (
                    _layout?.template?.label &&
                    _layout?.template?.label === con.Key &&
                    (_layout?.template?.description || "") === (des || "")
                  ) {
                    con.Value = _layout.data.value;
                  }
                });
              });
            }
            labelactionfield = con.labelactionfield;

            return con;
          })
        ).then((val) => {
          const res = formatKeyLogicData({
            data: val,
            labelactionfield: labelactionfield,
          });
          if (res) {
            setCustomOptions([...res.data]);
          }
          setLoading(false);
        });
      }
    }
  };

  return (
    <>
      <Form.Item
        style={{ margin: 0 }}
        name={name}
        rules={
          canEditDoc && [
            {
              required:
                template.attribute.required === "Y" &&
                template.attribute.items.length > 1,
              validator: (_, value) => {
                let res = true;

                if (template.attribute.items.length > 1) {
                  if (value) {
                    if (
                      value !== "--select--" &&
                      value !== "--Please Select--"
                    ) {
                      res = true;
                    } else if (template.attribute.require === "N") {
                      res = true;
                    } else {
                      res = false;
                    }
                  } else {
                    if (template.attribute.require === "N") {
                      res = true;
                    } else {
                      res = false;
                    }
                  }
                }

                return res
                  ? Promise.resolve()
                  : Promise.reject(`${template.label} is required.`);
              },
            },
          ]
        }
      >
        <SelectWithTableDropdownComponent
          {...{ canEditDoc, checkActionPage }}
          template={template}
          onChange={saveFunc}
          tableOptions={customOptions}
          actionAlert={actionAlert}
          loading={loading}
          onDropdownVisibleChange={(open) => {
            if (open) {
              logicRequest(template.description);
            } else {
              setCustomOptions(undefined);
              setLoading(false);
            }
          }}
        />
      </Form.Item>
    </>
  );
};
