export const GetAllEmployee = async (opts?: {Scopes?: string, IsActiveOnly?: boolean}) => {
  let qs = new URLSearchParams({
    ["Scopes"]: opts?.Scopes || "",
    ["IsActiveOnly"]: (opts?.IsActiveOnly || true).toString()
  });
  const respone = await fetch("api/Employee/GetAll?" + qs.toString())
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {});

  return respone?.filter((e: any) => e.IsActive === true);
};

/**
 * ฟังก์ชันอยู่ระหว่างพิจารณาปรับใช้งาน
 */
export const GetEmployeeByUserPrincipalName = async (dataEmp :any) => {
  try{
    dataEmp.UserPrincipalName = JSON.parse(localStorage.getItem("userData")!).employeeData.Email;
    const respone = await fetch("api/Employee/GetEmployeeByUserPrincipalName",{
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataEmp),
    })
    return await respone.json();

    // if (Array.isArray(data?.data)) {
    //   // ใช้ filter กับข้อมูลของ Employee ได้เนื่องจาก data.data เป็นอาร์เรย์
    //   const activeEmployees = data.data.filter((e: any) => e.IsActive === true);
    //   return activeEmployees;
    // } else {
    //   console.error("Invalid employee data returned from GetByIdEmployee");
    //   return null;
    // }
  } catch (error) {
    console.log("emp=>err", error);
    return error;
  }
};

export const UpdateEmployee = async (dataJson: any) => {
  const respone = await fetch("api/Employee/UpdateData", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(dataJson),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log("emp=>err", err);
      return err;
    });
  return respone;
};

export const UpdateSignature = async (dataJson: any) => {
  const email = window.localStorage.getItem("email") || "";
  dataJson.UserPrincipalName = email;
  const respone = await fetch("api/Employee/UpdateSignature", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(dataJson),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log("emp=>err", err);
      return err;
    });
  return respone;
};

export const GetUserData = async (empData: any) => {
  const email = window.localStorage.getItem("email") || "";
  empData.UserPrincipalName = email;
  const respone = await fetch("api/Employee/GetById", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(empData),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });

  return respone;
};
