import { Button, Col, Collapse } from "antd";
import type { CollapseProps } from "antd";
import { MenuItem } from "primereact/menuitem";
import React, { useEffect, useRef, useState } from "react";
import { FaHeading, FaRegImage, FaRegListAlt } from "react-icons/fa";
import { BiText, BiTimeFive } from "react-icons/bi";
import { ImTextHeight } from "react-icons/im";
import { AiOutlineNumber } from "react-icons/ai";
import { FiCalendar } from "react-icons/fi";
import { IoMdRadioButtonOn } from "react-icons/io";
import { MdOutlineCheckBox } from "react-icons/md";
import { CgPlayButtonO } from "react-icons/cg";
import {
  BsFileEarmarkRichtext,
  BsSortNumericDown,
  BsTable,
} from "react-icons/bs";
import { GrAttachment } from "react-icons/gr";
import usePDFEditor from "../../../hooks/usePDFEditor";
import PDFCompontButton from "./PDFCompontButton";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";

type Props = {
  advanceForm: any;
  reportTem: any[];
};

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuItem[] = [getItem("Option 1", "1"), getItem("Option 2", "2")];

const PDFToolBar = ({ advanceForm, reportTem }: Props) => {
  const [controlItem, setControlItems] = useState(advanceForm.items);

  const { addLayout } = usePDFEditor();

  useEffect(() => {
    getItems();
  }, []);

  const getItems = () => {
    let items: any[] = [];
    advanceForm.items.forEach((item: any) => {
      item.layout.forEach((layout: any) => {
        items.push(layout);
      });
    });

    setControlItems((prev: any) => items);
  };

  const getIcon = (type: string) => {
    switch (type) {
      case "l":
        return <FaHeading />;
      case "ed":
        return <BsFileEarmarkRichtext />;
      case "c":
        return <AiOutlineNumber />;
      case "Number":
        return <AiOutlineNumber />;
      case "ta":
        return <ImTextHeight />;
      case "dd":
        return <FaRegListAlt />;
      case "cb":
        return <MdOutlineCheckBox />;
      case "at":
        return <GrAttachment />;
      case "t":
        return <BiText />;
      case "Text":
        return <BiText />;
      case "r":
        return <IoMdRadioButtonOn />;
      case "tb":
        return <BsTable />;
      case "d":
        return <FiCalendar />;
      case "Datetime":
        return <FiCalendar />;
      case "bt":
        return <CgPlayButtonO />;
      case "an":
        return <BsSortNumericDown />;
      case "rvs":
        return <BiTimeFive />;
      case "im":
        return <FaRegImage />;
      default:
        break;
    }
  };

  return (
    <div className="tool-container">
      <Collapse defaultActiveKey={["1"]} items={undefined}>
        <CollapsePanel key={"1"} header={"Static Control"}>
          <PDFCompontButton
            label={"Text"}
            type={"staticText"}
            icon={getIcon("t")}
            layoutType="content"
          />{" "}
          <PDFCompontButton
            label={"Image"}
            type={"staticImage"}
            icon={getIcon("im")}
            layoutType="content"
          />
        </CollapsePanel>
        <CollapsePanel key={"2"} header={"Advance Form"}>
          {controlItem
            .filter((e: any) => e.template?.type !== "em")
            .map((item: any) => (
              <PDFCompontButton
                label={item?.template?.label}
                type={item?.template?.type}
                attribute={item?.template?.attribute}
                icon={getIcon(item?.template?.type)}
                layoutType="content"
              />
            ))}
        </CollapsePanel>
        <CollapsePanel key={"3"} header={"Memo"}>
          {reportTem.map((item: any) => (
            <PDFCompontButton
              label={item?.value1}
              type={item?.value5}
              attribute={{
                default: "",
                fontSize: 14,
                fontFamily: "Arial",
                color: "#000000",
                fontStyte: "normal",
                hAlign: "center",
                vAlign: "center",
                urlLink: "",
                hideLabel: false,
                column:
                  item.value5 === "tb"
                    ? item.value4.split("|").map((e: string) => {
                        return {
                          guid: "0gb2o3crjqs9ijcmisefv6k",
                          label: e,
                          alter: "",
                          control: {
                            template: {
                              type: "t",
                              label: e,
                              alter: "",
                              attribute: {
                                description: "",
                                default: "",
                                length: "0",
                                require: "N",
                                readonly: "N",
                                widthInTable: "0",
                                hideInPdf: "N",
                              },
                            },
                            data: {
                              value: null,
                            },
                          },
                        };
                      })
                    : null,
              }}
              icon={getIcon(item?.value5)}
              layoutType="content"
            />
          ))}
        </CollapsePanel>
      </Collapse>
    </div>
  );
};

export default PDFToolBar;
