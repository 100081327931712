import React, { createContext, useContext, useState, useEffect } from "react";
import {
  UpdateDismissById,
  getStatusQueueByActorId,
} from "../Services/MemoService";
import { useUserContext } from "./UserContext";

type IMemoPageContextType = {
  processQue: number[];
  FailQue: memoQueStatus[];
  getMyMemoStatus: () => Promise<void>;
  onDismiss: (id: number) => Promise<void>;
  checkSyncMemo: () => void;
};

export type memoQueStatus = {
  id: number;
  memoId: number;
  jobId: number;
  actorId: number;
  state: string;
  documentNo: string;
  message: string;
};

export const MemoPageContext = createContext<IMemoPageContextType | null>(null);

export const MemoPageContextProvider: React.FC = (props) => {
  const [processQue, setProcessQue] = useState<number[]>([]);
  const [FailQue, setFailQue] = useState<memoQueStatus[]>([]);
  const [isCheck, setIsCheck] = useState(false);

  const user = useUserContext();
  useEffect(() => {
    if (isCheck) {
      const intervalId = setInterval(() => {
        getMyMemoStatus(true);
      }, 5000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [isCheck]);

  const getMyMemoStatus = async ( isAuto? : boolean ) => {
    try {
      if (user[0]) {
        const result: memoQueStatus[] = await getStatusQueueByActorId(
          user[0].EmployeeId
        );

        if (result) {
          if (result.every((e) => e.state === "Succeeded")) {
            isAuto && setIsCheck(false);
          }
          const fail = result?.filter((e) => e.state === "Failed");
          if (fail?.length > 0) {
            if (JSON.stringify(fail) === JSON.stringify(FailQue)) {
              isAuto &&  setIsCheck(false);
            }
          }
          setProcessQue([
            ...result
              ?.filter(
                (e) =>
                  e.state !== "Failed" && e.state !== "Succeeded"
              )
              .map((e) => e.memoId),
          ]);
          setFailQue([...fail]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onDismiss = async (id: number) => {
    const result = await UpdateDismissById(id);
    getMyMemoStatus();
  };

  const checkSyncMemo = () => {
    setIsCheck(!isCheck);
  };

  return (
    <MemoPageContext.Provider
      value={{ processQue, FailQue, onDismiss, getMyMemoStatus, checkSyncMemo }}
    >
      {props.children}
    </MemoPageContext.Provider>
  );
};
