import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Col, Form, Row } from "react-bootstrap";
import { TextHeaderComponents } from "../../TextHeaderComponents/TextHeaderComponents";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { InputNumber } from "primereact/inputnumber";
import { FiSave } from "react-icons/fi";
import { Controller, useForm } from "react-hook-form";
import { confirmDialog } from "primereact/confirmdialog";
import useAlert from "../../../hooks/useAlert";
import { UploadFileAttachFiles } from "../../../Services/AttachFileService";
import { AddUserManual } from "../../../Services/UserManualService";
import { useUserContext } from "../../../Context/UserContext";
import { isEmptyObject } from "jquery";
import { Button } from "primereact/button";
import { Popover } from "antd";
import { AiOutlineClose } from "react-icons/ai";
import "./UserManualDialog.css";
import { classNames } from "primereact/utils";
import { GetAllMasterData } from "../../../Services/MasterDataService";

type Props = {
  // visible: any;
  // onHide: () => void;
  dialogHeader: string;
  formData: any;
  tableData?: any;
  setTableData: (items: any) => void;
  tableComfirmDialog?: boolean;
  setTableComfirmDialog?: (bool: boolean) => void;
  mainDialogVisible?: boolean;
  toggleMainDialog: (state: boolean, action: string) => void;
  setMainLoading?: (bool: boolean) => void;
};

interface IUserManualForm {
  ActorId: number;
  ActorName: string;
  UserManualGroupName: string;
  AttachedDate: string;
  AttachFile: string;
  FileName: string;
  FileDescription: string;
  FilePath: string;
  TagKeyword: string;
  Sep: number;
  OrderItem: number;
  ModifiedBy: string;
  ModifiedDate: string;
  CreatedBy: string;
  CreatedDate: string;
  IsActive: boolean;
}
interface IfileName {
  fileName: string;
  pathUrl: string;
  result: boolean;
}
const UserManualDialog = (props: Props) => {
  const [userManualValue, setUserManualValue] = useState<any[]>([]);
  const [fileName, setFileName] = useState<any>({});
  const [configFile, setConfigFile] = useState<any>({});
  const [userData, setUserData] = useUserContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [action, setAction] = useState<string>("");
  const [messageConfirmDialog, setMessageConfirmDialog] = useState<string>("");
  const [fileInputType, setfileInputType] = useState<any>(".pdf");
  const ref = React.useRef<any>();
  const { toggleAlert } = useAlert();
  const _userData = JSON.parse(window.localStorage.getItem("userData") || "");
  const _sharepointSiteURL = _userData.SharepointSiteURL;
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [checkHasValue, setCheckHasValue] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    shouldUnregister: false,
  });

  useEffect(() => {
    setMessageConfirmDialog("Do you want to " + action + " this record?");
  }, [action]);

  useEffect(() => {
    if (!isEmptyObject(props.formData)) {
      setAction("edit");
      setCheckHasValue(true);
    } else {
      setAction("add");
      setCheckHasValue(false);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (props.mainDialogVisible) {
      fetchMasterData();
      setLoading(false);
    }
  }, [props.mainDialogVisible]);

  const fetchMasterData = async () => {
    const masterData = await GetAllMasterData();
    if (masterData) {
      const masterDataType = masterData?.filter((e: any) => {
        return e.MasterType === "UserManual" && e.IsActive === true;
      });
      masterDataType.map((master: any) => {
        const userManual = master.Value1.split("|");
        const masterDataValue = userManual.map((e: any) => {
          return { UserManualGroupName: e };
        });

        control._formValues["UserManualGroupName"] = masterDataValue.find(
          (e: any) =>
            e.UserManualGroupName === props.formData.UserManualGroupName
        );
        setValue(
          "UserManualGroupName",
          control._formValues["UserManualGroupName"]
        );
        setUserManualValue([...masterDataValue]);
      });
    }
  };

  async function openWindow(link: any) {
    var pathArray = link?.split("/");
    const _link = link
      ? link.charAt(0) !== "/" && !_sharepointSiteURL
        ? "/" + link
        : link
      : "";

    const protocol = window.location.protocol;
    if (_sharepointSiteURL) {
      if (_link.startsWith(_sharepointSiteURL)) {
        window.open(`${_link}`, "_blank", "noreferrer");
      } else {
        window.open(`${_sharepointSiteURL}${_link}`, "_blank", "noreferrer");
      }
    } else if (_userData.TinyURL) {
      const _port = location.port;
      if (location.host !== "localhost:5001") {
        if (_port) {
          _userData.TinyURL = _userData.TinyURL + ":" + _port;
        }
      }
      if (
        _link.includes("www") ||
        _link.includes(".com") ||
        _link.includes(".tv") ||
        _link.includes(".net") ||
        _link.includes("https") ||
        _link.includes("http") ||
        _link.includes(".com") ||
        _link.includes(".co.th")
      ) {
        if (_link.includes("https")) {
          window.open(
            `https://${_link.replaceAll("https://", "")}`,
            "_blank",
            "noreferrer"
          );
        } else if (_link.includes("http")) {
          window.open(
            `http://${_link.replaceAll("http://", "")}`,
            "_blank",
            "noreferrer"
          );
        } else {
          window.open(`https://${_link}`, "_blank", "noreferrer");
        }
      } else {
        window.open(
          `${protocol}//${_userData.TinyURL}${_link}`,
          "_blank",
          "noreferrer"
        );
      }
    } else if (!_userData.TinyURL) {
      window.open(`${_link}`, "_blank", "noreferrer");
    }
  }

  const onFileSelect = (e: any) => {
    var filename = e.target.value;
    const files = e.target.files;
    const maxLength = parseInt(configFile.limitFileSize);
    const maxMb = 20971520 / 1024 / 1024;
    for (let i = 0; i < files.length; i++) {
      if (files[i].size >= maxLength) {
        toggleAlert({
          description: `Can not upload file size more than ${maxMb}.MB File name is ${files[i].name}`,
          message: `File size warning.`,
          type: "warning",
        });
        ref.current.value = null;
        return;
      }
    }
    return e.target.files;
  };

  async function onUploadFile(file: any) {
    let formData = new FormData();
    formData.append(`files`, file[0]);
    formData.append("Doclib", "TempAttachment");
    formData.append("docSet", "UserManual");
    formData.append("fileDesc", fileName);
    formData.append("actorID", userData.EmployeeId.toString());

    var response: any = await UploadFileAttachFiles(formData);
    console.log("response=>", response);
    if (response.data) {
      if (response.data?.result === true) {
        return response.data;
      } else {
        return null;
      }
    }
  }

  async function acceptSave(formData: any, attachFile: any) {
    try {
      let userManualformData: any = {};
      if (action === "add") {
        if (attachFile) {
          userManualformData.UserManualId = null;
          userManualformData.UserManualGroupName =
            formData?.UserManualGroupName?.UserManualGroupName;
          userManualformData.AttachFile =
            attachFile.fileName + "|" + attachFile.pathUrl;
          userManualformData.FileName = attachFile.fileName;
          userManualformData.FileDescription = formData.FileDescription;
          userManualformData.TagKeyword = formData.TagKeyword;
          userManualformData.Sep = formData.Sep;
          userManualformData.IsActive = formData.IsActive;
          userManualformData.ActorId = userData.EmployeeId;
          userManualformData.ActorName = userData.NameEn;
          userManualformData.AttachedDate = new Date().toLocaleString("en-UK", {
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          });
          userManualformData.FilePath = attachFile.pathUrl;
          userManualformData.OrderItem = "1";
          userManualformData.ModifiedBy = userData.EmployeeId.toString();
          userManualformData.ModifiedDate = new Date().toLocaleString("en-UK", {
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          });
          userManualformData.CreatedDate = new Date().toLocaleString("en-UK", {
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          });
          userManualformData.CreatedBy = userData.EmployeeId.toString();
        }
      } else {
        if (attachFile) {
          userManualformData = props.formData;
          userManualformData.UserManualId = props?.formData?.UserManualId;
          userManualformData.UserManualGroupName =
            formData?.UserManualGroupName?.UserManualGroupName;
          userManualformData.AttachFile =
            attachFile.fileName + "|" + attachFile.pathUrl;
          userManualformData.FileName = attachFile.fileName;
          userManualformData.FileDescription = formData.FileDescription;
          userManualformData.TagKeyword = formData.TagKeyword;
          userManualformData.Sep = formData.Sep;
          userManualformData.IsActive = formData.IsActive;
          userManualformData.ActorId = userData.EmployeeId;
          userManualformData.ActorName = userData.NameEn;
          userManualformData.AttachedDate = new Date().toLocaleString("en-UK", {
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          });
          userManualformData.FilePath = attachFile.pathUrl;
          userManualformData.OrderItem = "1";
          userManualformData.ModifiedBy = userData.EmployeeId.toString();
          userManualformData.ModifiedDate = new Date().toLocaleString("en-UK", {
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          });
          userManualformData.CreatedDate = new Date().toLocaleString("en-UK", {
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          });
          userManualformData.CreatedBy = userData.EmployeeId.toString();
        }
      }

      var response: any = await AddUserManual(userManualformData);
      if (response?.result === "success") {
        props.toggleMainDialog(false, "save");
        if (props.setMainLoading !== undefined) {
          props.setMainLoading(false);
        }
        toggleAlert({
          description: `(${attachFile.fileName}) has ${action} successfully`,
          message: `Success`,
          type: "success",
        });
      } else {
        toggleAlert({
          description: `Server Error Please try again.`,
          message: `Server Error`,
          type: "error",
        });
        if (props.setMainLoading !== undefined) {
          props.setMainLoading(false);
        }
      }
    } catch (error) {
      if (props.setMainLoading !== undefined) {
        props.setMainLoading(false);
      }
      toggleAlert({
        description: `${error}`,
        message: `Error`,
        type: "error",
      });
    }
  }

  const getFormErrorMessage = (name: any) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };
  const onSubmit = async (data: IUserManualForm) => {
    if (props.mainDialogVisible) {
      confirmDialog({
        message: messageConfirmDialog,
        header:
          action === "add" ? "Add" + " Confirmation" : "Edit" + " Confirmation",
        icon: "p-confirm-dialog-icon pi pi-info-circle",
        acceptClassName:
          "p-button p-component p-confirm-dialog-accept table-accept p-button-danger table-control-confirm-button p-button-accept-cancel",
        accept: async () => {
          if (props.setMainLoading !== undefined) {
            props.setMainLoading(true);
          }
          const file = {
            fileName: props.formData.FileName,
            pathUrl: props.formData.FilePath,
          };
          if (checkHasValue === false) {
            const uploadAttach = await onUploadFile(data.AttachFile);
            await acceptSave(data, uploadAttach);
          } else {
            await acceptSave(data, file);
          }
        },
      });
    }
  };

  return (
    <div className="card flex justify-content-center">
      <Dialog
        header={
          action === "add"
            ? "Add" + " " + props.dialogHeader
            : "Edit" + " " + props.dialogHeader
        }
        visible={props.mainDialogVisible}
        style={{ width: "90%", maxWidth: "992px" }}
        onHide={() => {
          reset();
          props.toggleMainDialog(false, "close");
        }}
        draggable={false}
      >
        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
          <div>
            <Row style={{ paddingBottom: "10px" }}>
              <Col xs={12} sm={2} lg={2} xl={2}>
                <TextHeaderComponents textHeaderProps={"User Manual Group"} />
              </Col>
              <Col xs={12} sm={6} lg={4} xl={4}>
                <Controller
                  name="UserManualGroupName"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Dropdown
                      id={field.name}
                      {...field}
                      options={userManualValue}
                      optionLabel={"UserManualGroupName"}
                      filter
                      showClear
                      filterBy={"UserManualGroupName"}
                      placeholder="Select User Manual Group"
                    />
                  )}
                />
              </Col>{" "}
              <Col xs={9} sm={2} lg={2} xl={2}>
                <TextHeaderComponents
                  textHeaderProps={"Active"}
                  textSubProps={"สถานะ"}
                />
              </Col>
              <Col xs={3} sm={2} lg={1} xl={4}>
                <Controller
                  name="IsActive"
                  control={control}
                  defaultValue={
                    props.formData.IsActive === undefined ||
                    props.formData.IsActive === true
                      ? true
                      : false
                  }
                  render={({ field, fieldState }) => (
                    <InputSwitch
                      id={field.name}
                      checked={field.value}
                      onChange={(e: any) => {
                        field.onChange(e.value);
                      }}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row style={{ paddingBottom: "10px" }}>
              {" "}
              <Col xs={12} sm={12} lg={2} xl={2}>
                <TextHeaderComponents
                  textHeaderProps={"AttachFile"}
                  textSubProps={"ไฟล์เเนบ"}
                  isRequir
                />
              </Col>
              <Col xs={12} sm={12} lg={10} xl={10}>
                <div className="p-inputgroup set-layout-att-input">
                  <Controller
                    name="AttachFile"
                    control={control}
                    // defaultValue={formSelectedUserManual.AttachFile}
                    rules={{
                      required: !checkHasValue
                        ? "AttachFile is required."
                        : undefined,
                        
                    }}
                    render={({ field, fieldState }) => (
                      <>
                        {checkHasValue === true && (
                          <>
                            <Button
                              label={
                                checkHasValue
                                  ? props.formData.FileName.length <= 20
                                    ? props.formData.FileName
                                    : props.formData.FileName.substring(0, 20) +
                                      " ..."
                                  : "Upload"
                              }
                              onClick={() => {
                                if (checkHasValue) {
                                  openWindow(props.formData.FilePath);
                                } else {
                                  setIsModalVisible(true);
                                }
                              }}
                              className="r"
                              type="button"
                              style={{
                                borderTopLeftRadius: "6px",
                                borderBottomLeftRadius: "6px",
                                borderTopRightRadius: "6px",
                                borderBottomRightRadius: "6px",
                                backgroundColor: "rgb(40, 47, 106)",
                                border: "1px solid rgb(40, 47, 106)",
                                boxShadow: "none",
                                width: "100%",
                                height: "38px",
                              }}
                            />
                            <div
                              className="border-icon-manual"
                              onClick={() => {
                                setCheckHasValue(false);
                                toggleAlert({
                                  description: `กรุณา อัพโหลดไฟล์ PDF ใหม่`,
                                  message: `Please Upload New PDF File`,
                                  type: "error",
                                });
                              }}
                            >
                              <AiOutlineClose />
                            </div>
                          </>
                        )}

                        {checkHasValue === false && (
                          <>
                            <Form.Control
                              ref={ref}
                              id="attach-file"
                              type="file"
                              accept=".pdf"
                              onChange={(e) => {
                                field.onChange(onFileSelect(e));
                              }}
                              multiple
                            />
                          </>
                        )}
                      </>
                    )}
                  />
                </div>
                {fileInputType === ".pdf" ? (
                  <></>
                ) : (
                  <>
                    <small className="p-error">
                      Please input only PDF file
                    </small>
                  </>
                )}

                {getFormErrorMessage("AttachFile")}
              </Col>
            </Row>
            <Row style={{ paddingBottom: "10px" }}>
              {" "}
              <Col xs={12} sm={12} lg={2} xl={2}>
                <TextHeaderComponents
                  textHeaderProps={"File Description"}
                  textSubProps={"รายละเอียดไฟล์"}
                />
              </Col>
              <Col xs={12} sm={12} lg={10} xl={10}>
                <Controller
                  name="FileDescription"
                  control={control}
                  defaultValue={props.formData.FileDescription}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      autoFocus
                      className="p-d-block p-inputgroup attachForm-border-description-inputText"
                    />
                  )}
                />
              </Col>
            </Row>
            <Row style={{ paddingBottom: "10px" }}>
              {" "}
              <Col xs={12} sm={12} lg={2} xl={2}>
                <TextHeaderComponents textHeaderProps={"Tag"} />
              </Col>
              <Col xs={12} sm={12} lg={10} xl={10}>
                <Controller
                  name="TagKeyword"
                  control={control}
                  defaultValue={props.formData.TagKeyword}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      autoFocus
                      className="p-d-block p-inputgroup attachForm-border-description-inputText"
                    />
                  )}
                />
              </Col>
            </Row>
            <Row style={{ paddingBottom: "10px" }}>
              {" "}
              <Col xs={12} sm={12} lg={2} xl={2}>
                <TextHeaderComponents textHeaderProps={"Sequence"} isRequir />
              </Col>
              <Col xs={12} sm={12} lg={10} xl={10}>
                <Controller
                  name="Sep"
                  control={control}
                  defaultValue={props.formData.Sep}
                  rules={{ required: "Sequence is required." }}
                  render={({ field, fieldState }) => (
                    <InputNumber
                      id={field.name}
                      {...field}
                      autoFocus
                      onChange={(e: any) => {
                        field.onChange(e.value);
                      }}
                      className={classNames({
                        "p-invalid": fieldState.error,
                      })}
                      // className="p-d-block p-inputgroup attachForm-border-description-inputText"
                    />
                  )}
                />

                {getFormErrorMessage("Sep")}
              </Col>
            </Row>
          </div>
          <div className="footer-dialog">
            <button
              onClick={() => {
                reset();
                props.toggleMainDialog(false, "close");
              }}
              className="button-cancle"
            >
              Close
            </button>

            <button className="button-save" type="submit">
              <FiSave />
              Save
            </button>
          </div>
        </form>
      </Dialog>
    </div>
  );
};

export default UserManualDialog;
