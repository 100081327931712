import React from "react";
import "./LogSendEmailScreen.css";
import { useEffect, useState } from "react"
import { GetAll, ReSend } from "../../Services/LogSendEmailService";
import { Col, Input, Modal, Row, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { ButtonComponents } from "../../components/ButtonComponents/ButtonComponents";
import useAlert from "../../hooks/useAlert";
import {
    SearchOutlined,
} from "@ant-design/icons";
import useLoading from "../../hooks/useLoading";

const LogSendEmailScreen = () => {
    const [pageNumber, setPageNumber] = useState(1);
    const [logData, setLogData] = useState<any>([]);
    const [total, setTotal] = useState(0);
    const { toggleAlert } = useAlert();
    const [searchKeyword, setSearchKeyword] = useState("");
    const {setLoad} = useLoading();
    
    useEffect(() => {
        fetchGetAll();
    }, [pageNumber, searchKeyword]);

    async function fetchGetAll() {
        let requestBody = {
            pageNumber: pageNumber,
            pageSize: 10,
            searchKeyword: searchKeyword
        }
        let result = await GetAll(requestBody);
        if (result) {
            setLogData(result.SendMails);
            setTotal(result.total);
        }
        console.log("result", result);
        console.log("logData", logData);
    }

    async function onResend(id: number) {
        setLoad(true);
        let requestBody = {
            Id: id
        };

        let result = await ReSend(requestBody);
        console.log("result", result);
        if (result) {
            toggleAlert({
                description: `Success`,
                message: `Re-Send`,
                type: "success",
            });
        }
        else {
            toggleAlert({
                description: `Fail`,
                message: `Re-Send`,
                type: "error",
            });
        }
        await fetchGetAll();
        setLoad(false);
    }

    const columns: ColumnsType<any> = [
        {
            title: () => <b>TimeStamp</b>,
            key: "TimeStamp",
            dataIndex: "TimeStamp",
            width: 140,
            render: (
                value: string | number | Date | dayjs.Dayjs | null | undefined
            ) => dayjs(value).format("DD MMM YYYY HH:mm:ss"),
        },
        {
            title: () => <b>MemoId</b>,
            key: "MemoId",
            dataIndex: "MemoId",
            width: 80,
        },
        {
            title: () => <b>MemoSubject</b>,
            key: "MemoSubject",
            dataIndex: "MemoSubject",
            width: 120,
        },
        {
            title: () => <b>DocumentNo</b>,
            key: "DocumentNo",
            dataIndex: "DocumentNo",
            width: 120,
        },
        {
            title: () => <b>ActorName</b>,
            key: "ActorName",
            dataIndex: "ActorName",
            width: 120,
        },
        {
            title: () => <b>ActionProcess</b>,
            key: "ActionProcess",
            dataIndex: "ActionProcess",
            width: 120,
        },
        {
            title: () => <b>MailSubject</b>,
            key: "MailSubject",
            dataIndex: "MailSubject",
            width: 120,
        },
        {
            title: () => <b>Mail_To</b>,
            key: "Mail_To",
            dataIndex: "Mail_To",
            width: 120,
        },
        {
            title: () => <b>Mail_CC</b>,
            key: "Mail_CC",
            dataIndex: "Mail_CC",
            width: 120,
        },
        {
            title: () => <b>Status</b>,
            key: "Status",
            dataIndex: "Status",
            width: 120,
            render: (value) => (
                value === "Success"
                    ? <p style={{ borderRadius: "5px", backgroundColor: "#28a745", display: "flex", color: "#ffffff", justifyContent: "center" }}>{value}</p>
                    : <p style={{ borderRadius: "5px", backgroundColor: "#e93445", display: "flex", color: "#ffffff", justifyContent: "center" }}>{value}</p>
            )
        },
        {
            width: 120,
            render: (value, rowData) => (
                <>
                    <ButtonComponents
                        setIconProps={"pi pi-sync"}
                        setLabelProps={("Re-Send")}
                        setClassNameProps={"reset-btn"}
                        onClickProps={() => {
                            Modal.confirm({
                                title: "Confirm",
                                content: (<p>
                                    The email will be sent to user related. Are you sure to continue?
                                </p>),
                                okText: "Yes, Send now",
                                onOk: () => {
                                    onResend(rowData.Id);
                                },
                                okButtonProps: {
                                    title: "Re-Send",
                                    style: {
                                        borderRadius: 6,
                                        borderColor: "rgb(40, 47, 106)",
                                        backgroundColor: "rgb(40, 47, 106)",
                                    },
                                },
                                cancelButtonProps: {
                                    style: {
                                        marginBottom: "10px",
                                        borderRadius: "6px",
                                        background: "#ffffff !important",
                                        color: "rgb(40, 47, 106) !important"
                                    },
                                },
                            });
                        }}
                    />
                </>
            ),
        },
    ];

    return (
        <>
            <div style={{ margin: "20px" }}>
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <Input
                            prefix={<SearchOutlined rev={undefined} />}
                            placeholder="ค้นหา"
                            style={{ width: "20%", margin: "5px", borderRadius: "10px" }}
                            allowClear
                            onChange={(e: any) => setSearchKeyword(e.target.value)}
                        />
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <Table
                            rowKey={"Id"}
                            bordered={true}
                            columns={columns}
                            dataSource={logData}
                            pagination={{ current: pageNumber, total: total }}
                            onChange={(e: any) => {
                                setPageNumber(e.current);
                            }}
                            scroll={{ y: 1500 }}>
                        </Table>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default LogSendEmailScreen