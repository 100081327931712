import { AutoComplete, Button, Modal, Radio, Space, Table } from "antd";
import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useUserContext } from "../../../Context/UserContext";
import { GetDepartment } from "../../../Services/DepartmentService";
import { GetAllEmployee } from "../../../Services/EmployeeService";
import AutoCompleteComponents from "../../AutoCompleteComponents/AutoCompleteComponents";
import { ButtonComponents } from "../../ButtonComponents/ButtonComponents";
import { CheckboxCpmponents } from "../../CheckboxCpmponents/CheckboxCpmponents";
import { InputTextComponents } from "../../InputTextComponents/InputTextComponents";
import { TextHeaderComponents } from "../../TextHeaderComponents/TextHeaderComponents";
import type { RadioChangeEvent } from "antd";
import "../TemplateDetailComponents.css";
import { RiDeleteBin6Line } from "react-icons/ri";
import { SelectDataDialog } from "../../Select/SelectionDataDialog/SelectDataDialog";
import { ColumnsType } from "antd/lib/table";
interface Props {
  InformationDataProps: any;
  setControlModelObj?: any;
  state?: string;
  checkValidation?: any;
  isLoadProps: any;
  groupDataTemplate: any;
  setGroupDataTemplate: any;
  isVersion: boolean;
}

export const InformationComponents = (props: Props) => {
  const [departmentList, setDepartmentList] = useState<any>({});
  const [informationDataProps, setInformationDataPropst] = useState<any>({
    ...props.InformationDataProps,
  });
  const [filteredAutoComplete, setFilteredAutoComplete] = useState<any>();
  const [key, setKey] = useState<any>();
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [searchData, setSearchData] = useState<any[]>([]);
  const [isDialogVisible, setDialogVisible] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [employee, setEmployee] = useState<any>({});
  const [departmentSelect, setDepartmentSelect] = useState<any>({});
  const [toSelect, setToSelect] = useState<any>([]);
  const [ccSelect, setCcSelect] = useState<any>([]);
  const [toName, setToName] = useState<any>([]);
  const [ccnName, setCcName] = useState<any>([]);
  const [userData, setUserData] = useUserContext();
  const [categoryFilter, setCategoryFilter] = useState<any>(
    props.groupDataTemplate
  );
  const [isMobile, setIsMobile] = useState<any>(false);
  const [toValue, setToValue] = useState<any[]>(toName);
  const [CCValue, setCCValue] = useState<any[]>(ccnName);
    console.log("mylogg",props.InformationDataProps);
  useEffect(() => {
    if (toSelect) {
      let filtered = toSelect.filter((e: any, idx: number) => idx <= 2);
      setToValue(filtered);
    }
    if (ccnName) {
      let filtered = ccSelect.filter((e: any, idx: number) => idx <= 2);
      setCCValue(filtered);
    }
  }, [toSelect, ccSelect]);

  useEffect(() => {
    checkDevice();
  }, []);

  const checkDevice = () => {
    let regexp = /android|iphone|kindle|ipad/i;
    let isMobileDevice = regexp.test(navigator.userAgent);
    setIsMobile(isMobileDevice);
    if (isMobileDevice) {
      console.log("Mobile Device");
    } else {
      console.log("Desktop");
    }
  };
  useEffect(() => {
    if (
      Object.keys(departmentList).length === 0 &&
      Object.keys(employee).length === 0
    ) {
      _fechDepartment();
      _fechEmployee();
      dataToAndCC(employee);
    }
  }, [departmentList, employee]);

  useEffect(() => {
    if (
      Object.keys(departmentList).length !== 0 &&
      Object.keys(employee).length !== 0
    ) {
      dataToAndCC(employee);
    }
  }, [props.InformationDataProps]);

  async function dataToAndCC(employeeData: any) {
    try {
      let _to =
        props.InformationDataProps.templateForm.ToId.length === 0
          ? []
          : props.InformationDataProps.templateForm.ToId.split(",");

      let _cc =
        props.InformationDataProps.templateForm.CcId.length === 0
          ? []
          : props.InformationDataProps.templateForm.CcId.split(",");
      let _employeeData =
        Object.keys(employeeData).length === 0 ? [] : employeeData;
      if (_employeeData.length === 0) return;
      let _toArray: any = [];
      let _toName: any = [];
      let _ccArray: any = [];
      let _ccName: any = [];
      if (_to.length !== 0) {
        // new code
        for(let item of _to){
          let emp = _employeeData?.find((x: any) => x?.NameEn === item);
          let _duplicate = _toArray?.some((x: any) => x?.NameEn === emp?.NameEn) 
          if(emp && !_duplicate){
            _toArray.push(emp)
          }
        }
        // for (let i = 0; i < _employeeData.length; i++) {
        //   for (let j = 0; j < _to.length; j++) {
        //     if (_employeeData[i].NameEn === _to[j]) {
        //       if (_toArray.length != 0) {
        //         let _dataFilter = _toArray.filter(
        //           (item: any) => item.NameEn === _employeeData[i].NameEn
        //         );

        //         if (_dataFilter.length === 0) {
        //           _toArray.push(_employeeData[i]);
        //         }
        //       } else {
        //         _toArray.push(employee[i]);
        //       }
        //     }
        //   }
        // }

        if (_to.length !== _toArray.length) {
          let _dataFilter = _to.filter((data: any) => {
            let _data = _toArray.filter((_data: any) => _data.NameEn === data);
            if (_data.length === 0) {
              let _event = {
                AccountCode: "",
                AccountName:
                  "                                                                                                                                                                                                                                             ",
                CreatedBy: null,
                CreatedDate: "",
                DefaultLang: "EN",
                DepartmentId: null,
                DepartmentNameEn: "",
                DepartmentNameTh: "",
                Email: "",
                EmployeeCode: "",
                EmployeeId: null,
                ExpiredDate: "",
                IsActive: true,
                Lang: "EN",
                ModifiedBy: "",
                ModifiedDate: "",
                NameEn: data,
                NameTh: data,
                PositionId: null,
                PositionNameEn: "",
                PositionNameTh: "",
                RegisteredDate: "",
                ReportToEmpCode: "",
                SignPicPath: null,
                Username: "",
              };
              _toArray.push(_event);
            }
          });
        }
        _toArray.map((e: any) => {
          _toName.push(userData.Lang === "EN" ? e.NameEn : e.NameTh);
        });
        setToName(_toName);
        setToSelect(_toArray);
      }
      if (_cc.length !== 0) {
        // new code
        for(let item of _cc){
          let emp = _employeeData?.find((x: any) => x?.NameEn === item);
          let _duplicate = _ccArray?.some((x: any) => x?.NameEn === emp?.NameEn) 
          if(emp && !_duplicate){
            _ccArray.push(emp)
          }
        }
        // for (let i = 0; i < _employeeData.length; i++) {
        //   for (let j = 0; j < _cc.length; j++) {
        //     if (_employeeData[i].NameEn === _cc[j]) {
        //       if (_ccArray.length != 0) {
        //         let _dataFilter = _ccArray.filter(
        //           (item: any) => item.NameEn === _employeeData[i].NameEn
        //         );
        //         if (_dataFilter.length === 0) {
        //           _ccArray.push(_employeeData[i]);
        //         }
        //       } else {
        //         _ccArray.push(employee[i]);
        //       }
        //     }
        //   }
        // }
        // ไม่รู้ว่ามีไว้ทำไมเลยคอมเมนท์ไว้ก่อน เขาเขียนไว้
        // _employeeData.filter((_data: any) => {
        //   let _dataCc = _cc.filter(
        //     (dataCc: any) => _data.NameTh === dataCc || _data.NameEn === dataCc
        //   );

        //   if (_dataCc.length !== 0) {
        //     if(ccEmpCode == _data.EmployeeId){
        //       _ccArray.push(_data);
        //     }
        //   }
        // });
        if (_cc.length !== _ccArray.length) {
          let _dataFilter = _cc.filter((data: any) => {
            let _data = _ccArray.filter((_data: any) => _data.NameEn === data);
            if (_data.length === 0) {
              let _event = {
                AccountCode: "",
                AccountName:
                  "                                                                                                                                                                                                                                             ",
                CreatedBy: null,
                CreatedDate: "",
                DefaultLang: "EN",
                DepartmentId: null,
                DepartmentNameEn: "",
                DepartmentNameTh: "",
                Email: "",
                EmployeeCode: "",
                EmployeeId: null,
                ExpiredDate: "",
                IsActive: true,
                Lang: "EN",
                ModifiedBy: "",
                ModifiedDate: "",
                NameEn: data,
                NameTh: data,
                PositionId: null,
                PositionNameEn: "",
                PositionNameTh: "",
                RegisteredDate: "",
                ReportToEmpCode: "",
                SignPicPath: null,
                Username: "",
              };
              _ccArray.push(_event);
            }
          });
        }
        _ccArray.map((e: any) => {
          _ccName.push(userData.Lang === "EN" ? e.NameEn : e.NameTh);
        });
        setCcName(_ccName);
        setCcSelect(_ccArray);
      }
    } catch (error) {}
  }
  const [showDialogEmp, setShowDialogEmp] = useState(true);
  async function showModal(key: any) {
    if (globalFilterValue != "") {
      setGlobalFilterValue("");
    }
    // setDialogVisible(!isDialogVisible);
    // setKey(key);
    // _fechEmployee();
    // setIsloading(true);
    setDialogVisible(!isDialogVisible);
    let req = {
      IsActiveOnly: true,
      Scopes: "name,email,org",
    };
    setKey(key);
    if (showDialogEmp) {
      if (employee.length <= 0) {
        const employeeList = await GetAllEmployee(req);
        setEmployee(employeeList);
        setSearchData(employeeList);
      }

      setShowDialogEmp(!showDialogEmp);
    }
  }
  function onSelectedEmployeeToAndPass(data: any) {
    let checkValue: boolean = false;
    const _value = userData.Lang === "EN" ? data.NameEn : data.NameTh;
    _AutoComplete(data, key === "ToId" ? key : "CcId");
    setDialogVisible(!isDialogVisible);
    // false;
  }

  async function _fechEmployee() {
    const _employee = await GetAllEmployee();
    dataToAndCC(_employee);
    setEmployee(_employee);
    setSearchData(_employee);
    setIsloading(true);
  }

  async function _fechDepartment() {
    const _Department = await GetDepartment();
    let _dataFilter = _Department.filter(
      (_department: any) => _department.NameEn !== null
    );
    if (props.InformationDataProps.templateForm?.DepartmentId !== 0) {
      let _dataFilterDepartmentId = _dataFilter.filter(
        (_department: any) =>
          _department.DepartmentId ===
          props.InformationDataProps.templateForm?.DepartmentId
      );
      setDepartmentSelect(_dataFilterDepartmentId[0]);
    }
    setDepartmentList(_dataFilter);
  }

  async function _DropdownDepartment(data: any, key: any) {
    if (data === undefined || data === null) {
    } else {
      props.setControlModelObj((prevState: any) => ({
        ...prevState,
        templateForm: {
          ...props.InformationDataProps.templateForm,
          DepartmentId: data.DepartmentId,
        },
      }));

      setDepartmentSelect(data);
    }
  }
  async function _RadioButtonFormType(data: any, key: any) {
    if (data) {
      setDepartmentSelect({});
    }
    props.setControlModelObj((prevState: any) => ({
      ...prevState,
      templateForm: {
        ...props.InformationDataProps.templateForm,
        isPublic: data,
        DepartmentId:
          data === false
            ? props.InformationDataProps.templateForm.DepartmentId
            : 0,
      },
    }));
  }
  async function _CheckboxCpmponents(data: any, key: any) {
    props.setControlModelObj((prevState: any) => ({
      ...prevState,
      templateForm: {
        ...props.InformationDataProps.templateForm,
        [key]: data,
      },
    }));
  }
  async function _InputText(data: any, key: any) {
    props.setControlModelObj((prevState: any) => ({
      ...prevState,
      templateForm: {
        ...props.InformationDataProps.templateForm,
        [key]: data,
      },
    }));
  }

  function selecteOrTypeListInToAndPass(event: any, type: any) {
    let checkValue: boolean = false;
    const _value = event.value.map((item: any) => {
      if (!(typeof item === "string")) {
        return item.NameEn;
      } else {
        return item;
      }
    });
    if (type === "ToId") {
      let _components = toSelect;
      _components = [..._value];
      setToName([..._components]);
      setToSelect([..._components]);
      props.setControlModelObj((prevState: any) => ({
        ...prevState,
        templateForm: {
          ...props.InformationDataProps.templateForm,
          ToId: _components.toString(),
        },
      }));
    } else if (type === "CcId") {
      let _components = ccSelect;
      _components = [..._value];

      setCcName([..._components]);
      setCcSelect([..._components]);
      props.setControlModelObj((prevState: any) => ({
        ...prevState,
        templateForm: {
          ...props.InformationDataProps.templateForm,
          CcId: _components.toString(),
        },
      }));
    }
  }
  async function _AutoComplete(data: any, key: any) {
    const _value = userData.Lang === "EN" ? data.NameEn : data.NameTh;
    let checkValue: boolean = false;

    if (key === "ToId") {
      let _ToArray: any = toName;
      _ToArray.filter((_item: any) => {
        if (_value === _item) {
          checkValue = true;
        }
      });
      _ToArray.push(_value);
      setToName([..._ToArray]);
      setToSelect([..._ToArray]);
      props.setControlModelObj((prevState: any) => ({
        ...prevState,
        templateForm: {
          ...props.InformationDataProps.templateForm,
          ToId: _ToArray.toString(),
        },
      }));
    } else if (key === "CcId") {
      let _CcArray: any = ccnName;

      _CcArray.filter((_item: any) => {
        if (_value === _item) {
          checkValue = true;
        }
      });
      _CcArray.push(_value);
      setCcName([..._CcArray]);
      setCcSelect([..._CcArray]);
      props.setControlModelObj((prevState: any) => ({
        ...prevState,
        templateForm: {
          ...props.InformationDataProps.templateForm,
          CcId: _CcArray.toString(),
        },
      }));
    }
  }

  const searchAutoComplete = async (event: any) => {
    const _employee = employee;

    setTimeout(() => {
      let _filteredEmployee;
      if (!event.query.trim().length) {
        _filteredEmployee = [..._employee];
      } else {
        _filteredEmployee = _employee.filter((dataemployee: any) => {
          if (
            dataemployee.NameEn.toLowerCase().includes(
              event.query.toLowerCase()
            )
          ) {
            return dataemployee.NameEn.toLowerCase().includes(
              event.query.toLowerCase()
            );
          }
          if (
            dataemployee.NameEn.toLowerCase().includes(
              event.query.toLowerCase()
            )
          ) {
            return dataemployee.NameEn.toLowerCase().includes(
              event.query.toLowerCase()
            );
          }
          if (
            dataemployee.PositionNameTh.toLowerCase().includes(
              event.query.toLowerCase()
            )
          ) {
            return dataemployee.PositionNameTh.toLowerCase().includes(
              event.query.toLowerCase()
            );
          }
          if (
            dataemployee.PositionNameEn.toLowerCase().includes(
              event.query.toLowerCase()
            )
          ) {
            return dataemployee.PositionNameEn.toLowerCase().includes(
              event.query.toLowerCase()
            );
          }
        });
      }

      setFilteredAutoComplete(_filteredEmployee);
    }, 250);
  };
  const searchEmployeeEnter = (event: any, key: any) => {
    console.log("searchEvent", event);

    let dataSelectEmployee: any[] =
      key === "ToId" ? [...toSelect] : [...ccSelect];
    if (event.length !== 0) {
      let _event = {
        AccountCode: "",
        AccountName:
          "                                                                                                                                                                                                                                             ",
        CreatedBy: null,
        CreatedDate: "",
        DefaultLang: "EN",
        DepartmentId: null,
        DepartmentNameEn: "",
        DepartmentNameTh: "",
        Email: "",
        EmployeeCode: "",
        EmployeeId: null,
        ExpiredDate: "",
        IsActive: true,
        Lang: "EN",
        ModifiedBy: "",
        ModifiedDate: "",
        NameEn: event,
        NameTh: event,
        PositionId: null,
        PositionNameEn: "",
        PositionNameTh: "",
        RegisteredDate: "",
        ReportToEmpCode: "",
        SignPicPath: null,
        Username: "",
      };
      dataSelectEmployee.push(_event);
      console.log("dataSelectEmployee", dataSelectEmployee);

      _AutoComplete(dataSelectEmployee, key === "ToId" ? key : "CcId");
    }
  };
  const onChangeReportLang = (e: RadioChangeEvent) => {
    props.setControlModelObj((prevState: any) => ({
      ...prevState,
      templateForm: {
        ...props.InformationDataProps.templateForm,
        ReportLang: e.target.value,
      },
    }));
  };
  const customModalStyle = {
    padding: "30px",
    maxHeight: "20px",
  };

  const filterToCC = (event: any, type: any) => {
    if (type === "to") {
      const filtered = toName.filter((e: any) => e !== event);
      setToName([...filtered]);
      setToSelect([...filtered]);
      props.setControlModelObj((prevState: any) => ({
        ...prevState,
        templateForm: {
          ...props.InformationDataProps.templateForm,
          ToId: filtered.toString(),
        },
      }));

      Modal.destroyAll();
    } else if (type === "pass") {
      const filtered = ccnName.filter((e: any) => e !== event);
      setCcName([...filtered]);
      setCcSelect([...filtered]);
      props.setControlModelObj((prevState: any) => ({
        ...prevState,
        templateForm: {
          ...props.InformationDataProps.templateForm,
          CcId: filtered.toString(),
        },
      }));

      Modal.destroyAll();
    }
  };
  const columnsTo: ColumnsType<any> = [
    {
      title: "#",
      dataIndex: "#",
      key: "#",
      render: (_, record: any, idx: number) => {
        return (
          <>
            <div>{idx + 1}</div>
          </>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record: any) => {
        return (
          <>
            <div>{record}</div>
          </>
        );
      },
    },

    {
      title: (
        <div style={{ display: "flex", justifyContent: "center" }}>Action</div>
      ),
      key: "action",
      render: (_, record) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Space size="middle">
            <Button
              danger
              type="primary"
              size="large"
              icon={<RiDeleteBin6Line />}
              className="set-button-css-ref"
              onClick={() => {
                filterToCC(record, "to");
              }}
            />
          </Space>
        </div>
      ),
    },
  ];

  const columnsCC: ColumnsType<any> = [
    {
      title: "#",
      dataIndex: "#",
      key: "#",
      render: (_, record: any, idx: number) => {
        return (
          <>
            <div>{idx + 1}</div>
          </>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record: any) => {
        return (
          <>
            <div>{record}</div>
          </>
        );
      },
    },

    {
      title: (
        <div style={{ display: "flex", justifyContent: "center" }}>Action</div>
      ),
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            danger
            type="primary"
            size="large"
            icon={<RiDeleteBin6Line />}
            className="set-button-css-ref"
            onClick={() => {
              filterToCC(record, "pass");
            }}
          />
        </Space>
      ),
    },
  ];
  const showMore = (type: any) => {
    Modal.info({
      title: (
        <>
          {type === "to" ? (
            <>
              <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                To(เรียนถึง) ทั้งหมด
              </p>
            </>
          ) : (
            <>
              <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                CC(สำเนา) ทั้งหมด
              </p>
            </>
          )}
        </>
      ),
      content: (
        <div>
          <>
            {" "}
            <Table
              columns={type === "to" ? columnsTo : columnsCC}
              dataSource={type === "to" ? toName : ccnName}
            />
          </>
        </div>
      ),
      style: customModalStyle,
      closable: true,
    });
  };
  function insertDataByEnter(event: any, type: any) {
    let toArr: any[] = toName;
    let ccArr: any[] = ccnName;

    if (!event) {
      return;
    }
    if (type === "ToId") {
      let auto = event.split(",").join("");
      toArr.push(auto);
      setToName([...toArr]);
      setToSelect([...toArr]);
      props.setControlModelObj((prevState: any) => ({
        ...prevState,
        templateForm: {
          ...props.InformationDataProps.templateForm,
          ToId: toArr.toString(),
        },
      }));
    } else if (type === "CcId") {
      let auto = event.split(",").join("");
      ccArr.push(auto);
      setCcName([...ccArr]);
      setCcSelect([...ccArr]);
      props.setControlModelObj((prevState: any) => ({
        ...prevState,
        templateForm: {
          ...props.InformationDataProps.templateForm,
          CcId: ccArr.toString(),
        },
      }));
    }
  }

  return (
    <>
      <Row className="0">
        <Col xs={12} md={7} lg={9} xl={9} style={{ alignItems: "center" }}>
          <p className="Col-text-header-Inform">Information</p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12} lg={12} xl={12}>
          <p className="informationComponents-line-border"></p>
        </Col>
      </Row>
      <div
        style={{ display: "flex", flexDirection: "column", rowGap: "1.25rem" }}
      >
        <Row className="">
          <Col
            xs={12}
            sm={2}
            xl={2}
            className="informationComponents-media-FormCategory-425px"
          >
            <TextHeaderComponents
              textHeaderProps={"Form Category:"}
              textSubProps={"ประเภทแบบฟอร์ม:"}
              isRequir={true}
            />
          </Col>
          <Col
            xs={12}
            sm={10}
            xl={4}
            style={{ marginTop: "-2px" }}
            className={"informationComponents-media-FormCategory"}
          >
            <AutoComplete
              className={`info-autocomplete set-text-placeholder ${
                props.checkValidation?.GroupTemplateName &&
                props.InformationDataProps?.templateForm?.GroupTemplateName
                  .length === 0
                  ? "set-inter-css"
                  : ""
              }`}
              options={props?.groupDataTemplate}
              value={
                props?.InformationDataProps?.templateForm?.GroupTemplateName
              }
              placeholder={
                props.InformationDataProps.templateForm?.GroupTemplateName !==
                undefined
                  ? props.InformationDataProps.templateForm
                      .GroupTemplateName !== null
                    ? props.InformationDataProps.templateForm.GroupTemplateName
                        .length === 0
                      ? "Form Category"
                      : ""
                    : ""
                  : ""
              }
              filterOption={(inputValue, option) =>
                option?.value
                  ?.toString()
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              }
              onChange={(e: any) => {
                try {
                  let _filtered;
                  let value = e;
                  if (value === "") {
                    _filtered = props.groupDataTemplate;
                  } else {
                    _filtered = props.groupDataTemplate.filter((e: any) => {
                      return e.value
                        ?.toLowerCase()
                        .startsWith(value.toLowerCase());
                    });
                  }

                  setCategoryFilter([..._filtered]);
                  props.setControlModelObj((prevState: any) => ({
                    ...prevState,
                    templateForm: {
                      ...props.InformationDataProps.templateForm,
                      GroupTemplateName: value,
                    },
                  }));
                } catch (error) {
                  console.log("table=>onFilter=>error", error);
                }
              }}
              onSelect={(e: any) => {
                try {
                  setCategoryFilter([...props.groupDataTemplate]);
                  props.setControlModelObj((prevState: any) => ({
                    ...prevState,
                    templateForm: {
                      ...props.InformationDataProps.templateForm,
                      GroupTemplateName: e,
                    },
                  }));
                } catch (error) {
                  console.log("table=>onFilter=>error", error);
                }
              }}
              allowClear
              onClear={() => {
                setCategoryFilter([...props.groupDataTemplate]);
                props.setControlModelObj((prevState: any) => ({
                  ...prevState,
                  templateForm: {
                    ...props.InformationDataProps.templateForm,
                    GroupTemplateName: "",
                  },
                }));
              }}
            />
            {props.checkValidation.GroupTemplateName &&
              props.InformationDataProps.templateForm.GroupTemplateName
                .length === 0 && (
                <small className="p-error">Form Category is required</small>
              )}
          </Col>
          <Col
            xs={12}
            sm={2}
            xl={2}
            className="informationComponents-media-department"
          >
            <TextHeaderComponents
              textHeaderProps={"Form Name:"}
              textSubProps={"ชื่อแบบฟอร์ม:"}
              isRequir={true}
            />
          </Col>
          <Col
            xs={12}
            sm={10}
            xl={4}
            className="informationComponents-media-department"
          >
            <InputTextComponents
              setStyleProps={{
                height: 38,
                borderColor:
                  props.checkValidation.TemplateName === true
                    ? props.InformationDataProps.templateForm.TemplateName
                        .length === 0
                      ? "red"
                      : undefined
                    : undefined,
              }}
              setClassNameProps={"information-inputTexta-width"}
              placeholderProps={
                props.InformationDataProps.templateForm?.TemplateName.length ===
                0
                  ? "Form Name"
                  : null
              }
              valueProps={props.InformationDataProps.templateForm?.TemplateName}
              onChangeProps={_InputText}
              keyProps={"TemplateName"}
            />
            {props.checkValidation.TemplateName &&
              props.InformationDataProps.templateForm.TemplateName.length ===
                0 && <small className="p-error">Form Name is required</small>}
          </Col>
        </Row>

        <Row className="">
          <Col
            xs={12}
            sm={2}
            xl={2}
            className="informationComponents-media-FormCategory-425px"
          >
            <TextHeaderComponents
              textHeaderProps={"DocType Code:"}
              textSubProps={"รหัสประเภทเอกสาร:"}
              isRequir={true}
            />
          </Col>
          <Col
            xs={12}
            sm={10}
            xl={4}
            style={{ marginTop: "-2px" }}
            className="informationComponents-media-FormCategory"
          >
            <div style={{ paddingBottom: "3px" }}>
              <InputTextComponents
                setStyleProps={{
                  height: 38,
                  borderColor:
                    props.checkValidation.DocumentCode === true
                      ? props.InformationDataProps.templateForm.DocumentCode
                          .length === 0
                        ? "red"
                        : undefined
                      : undefined,
                }}
                setClassNameProps={"information-inputTexta-width"}
                placeholderProps={
                  props.InformationDataProps.templateForm?.DocumentCode
                    .length === 0
                    ? "DocType Code"
                    : null
                }
                valueProps={
                  props.InformationDataProps.templateForm?.DocumentCode
                }
                onChangeProps={_InputText}
                disabledProps={
                  props.state === "edit" || props.isVersion === true
                    ? true
                    : false
                }
                keyProps={"DocumentCode"}
              />
              {props.checkValidation.DocumentCode &&
                props.InformationDataProps.templateForm.DocumentCode.length ===
                  0 && <small className="p-error">DocType is required</small>}
            </div>
          </Col>
        </Row>
        <Row className="">
          <Col
            xs={12}
            sm={2}
            xl={2}
            className="informationComponents-media-FormCategory-425px"
          >
            <TextHeaderComponents
              textHeaderProps={"To:"}
              textSubProps={"เรียน:"}
            />
          </Col>
          <Col
            xs={12}
            sm={10}
            xl={10}
            style={{ marginTop: "-2px" }}
            // className="informationComponents-media-FormCategory"
          >
            <div
              className="p-inputgroup"
              style={{
                height: `100%`,
              }}
            >
              <AutoCompleteComponents
                completeMethodProps={searchAutoComplete}
                suggestionsProps={filteredAutoComplete}
                fieldProps={userData.Lang === "TH" ? "NameTh" : "NameEn"}
                onChangeProps={(value: any, b: any, e: any) => {
                  selecteOrTypeListInToAndPass(e, b);
                }}
                valueProps={isMobile ? toValue : toSelect}
                keyProps={"ToId"}
                onKeyPress={
                  insertDataByEnter
                  // searchEmployeeEnter
                }
                disabledProps={isMobile && toSelect?.length > 3 ? true : false}
              />
              {isMobile && toSelect.length > 3 && (
                <button
                  className="show-more-btn"
                  onClick={() => {
                    showMore("to");
                  }}
                >
                  More({toSelect.length - 3})
                </button>
              )}

              <ButtonComponents
                setIconProps={"pi pi-search"}
                setClassNameProps={"p-button-text-position"}
                onClickProps={(data: any) => showModal("ToId")}
                setStyleProps={{
                  backgroundColor: "#282f6a",
                  border: "1px solid #282f6a",
                  borderTopRightRadius: "6px",
                  borderBottomRightRadius: "6px",
                  boxShadow: "none",
                  height: "100%",
                }}
              />
            </div>
          </Col>
        </Row>
        <Row className="">
          <Col
            xs={12}
            sm={2}
            xl={2}
            className="informationComponents-media-FormCategory-425px"
          >
            <TextHeaderComponents
              textHeaderProps={"Cc:"}
              textSubProps={"สำเนา:"}
            />
          </Col>
          <Col
            xs={12}
            sm={10}
            xl={10}
            style={{ marginTop: "-2px" }}
            className="informationComponents-media-FormCategory"
          >
            <div
              className="p-inputgroup"
              style={{
                height: `100%`,
              }}
            >
              <AutoCompleteComponents
                completeMethodProps={searchAutoComplete}
                suggestionsProps={filteredAutoComplete}
                fieldProps={userData.Lang === "TH" ? "NameTh" : "NameEn"}
                onChangeProps={(value: any, b: any, e: any) => {
                  selecteOrTypeListInToAndPass(e, b);
                }}
                valueProps={isMobile ? CCValue : ccSelect}
                keyProps={"CcId"}
                onKeyPress={
                  insertDataByEnter
                  // searchEmployeeEnter
                }
                disabledProps={isMobile && ccSelect?.length > 3 ? true : false}
              />
              {isMobile && ccSelect.length > 3 && (
                <button
                  className="show-more-btn"
                  onClick={() => {
                    showMore("pass");
                  }}
                >
                  More({ccSelect.length - 3})
                </button>
              )}

              <ButtonComponents
                setIconProps={"pi pi-search"}
                setClassNameProps={"p-button-text-position"}
                onClickProps={(data: any) => showModal("CcId")}
                setStyleProps={{
                  backgroundColor: "#282f6a",
                  border: "1px solid #282f6a",
                  borderTopRightRadius: "6px",
                  borderBottomRightRadius: "6px",
                  boxShadow: "none",
                  height: "100%",
                }}
              />
            </div>
          </Col>
        </Row>
        <Row className="">
          <Col
            xs={12}
            sm={2}
            xl={2}
            className="informationComponents-media-FormCategory-425px"
          >
            <TextHeaderComponents
              textHeaderProps={"Subject:"}
              textSubProps={"เรื่อง:"}
            />
          </Col>
          <Col
            xs={12}
            sm={10}
            xl={10}
            className="informationComponents-media-FormCategory"
          >
            <div style={{ paddingBottom: "3px" }}>
              <InputTextComponents
                setStyleProps={{ height: 38 }}
                setClassNameProps={"information-inputTexta-width"}
                placeholderProps={
                  props.InformationDataProps.templateForm?.TemplateSubject
                    .length === 0
                    ? "Subject"
                    : null
                }
                valueProps={
                  props.InformationDataProps.templateForm?.TemplateSubject
                }
                onChangeProps={_InputText}
                keyProps={"TemplateSubject"}
              />
            </div>
          </Col>
        </Row>
        <Row className="">
          <Col
            xs={12}
            sm={2}
            xl={2}
            className="informationComponents-media-FormCategory-425px"
          >
            <TextHeaderComponents
              textHeaderProps={"PDF Show Info:"}
              textSubProps={"แสดงหัวเอกสาร:"}
            />
          </Col>
          <Col
            xs={12}
            sm={10}
            xl={4}
            className="informationComponents-media-FormCategory"
          >
            <div>
              <CheckboxCpmponents
                checkedProps={
                  props.InformationDataProps.templateForm?.isPDFShowInfo
                }
                onChangeProps={_CheckboxCpmponents}
                keyProps={"isPDFShowInfo"}
              />
            </div>
          </Col>
          <Col
            xs={12}
            sm={2}
            xl={2}
            className="informationComponents-media-FormCategory-425px"
          >
            <TextHeaderComponents
              textHeaderProps={"PDF Language:"}
              textSubProps={"ภาษาในเอกสาร:"}
            />
          </Col>
          <Col
            xs={12}
            sm={10}
            xl={4}
            className="informationComponents-media-FormCategory"
          >
            <Radio.Group
              onChange={onChangeReportLang}
              value={props.InformationDataProps.templateForm?.ReportLang}
            >
              <Radio value={"TH"}>TH</Radio>
              <Radio value={"EN"}>EN</Radio>
            </Radio.Group>
          </Col>
        </Row>

        <Row className="">
          {/* <Col
            xs={12}
            sm={2}
            xl={2}
            className="informationComponents-media-FormCategory-425px"
          >
            <TextHeaderComponents
              textHeaderProps={"PDF Show Info:"}
              textSubProps={"แสดงหัวเอกสาร:"}
            />
          </Col>
          <Col
            xs={12}
            sm={1}
            xl={1}
            className="informationComponents-media-FormCategory"
          >
            <div>
              <CheckboxCpmponents
                checkedProps={
                  props.InformationDataProps.templateForm?.isPDFShowInfo
                }
                onChangeProps={_CheckboxCpmponents}
                keyProps={"isPDFShowInfo"}
              />
            </div>
          </Col> */}
          <Col
            xs={12}
            sm={2}
            xl={2}
            className="informationComponents-media-FormCategory-425px"
          >
            <TextHeaderComponents
              textHeaderProps={"Can Edit Approver"}
              textSubProps={"สามารถแก้ไขผู้อนุมัติได้:"}
            />
          </Col>
          <Col
            xs={12}
            sm={10}
            xl={4}
            className="informationComponents-media-FormCategory1024px"
          >
            <div>
              <CheckboxCpmponents
                checkedProps={
                  props.InformationDataProps.templateForm
                    ?.isRequesterEditApproval
                }
                onChangeProps={_CheckboxCpmponents}
                keyProps={"isRequesterEditApproval"}
              />
            </div>
          </Col>

          <Col
            xs={12}
            sm={2}
            xl={2}
            className="informationComponents-media-FormCategory-425px"
          >
            <TextHeaderComponents
              textHeaderProps={"Registered Form:"}
              textSubProps={"ฟอร์มสำหรับขึ้นทะเบียน:"}
            />
          </Col>

          <Col
            xs={12}
            sm={10}
            xl={4}
            className="informationComponents-media-FormCategory1024px"
          >
            <div>
              <CheckboxCpmponents
                checkedProps={
                  props.InformationDataProps.templateForm?.isFormControl
                }
                onChangeProps={_CheckboxCpmponents}
                keyProps={"isFormControl"}
              />
            </div>
          </Col>
        </Row>
        {/* validatt */}
        <Row className="">
          <Col
            xs={12}
            sm={2}
            xl={2}
            className="informationComponents-media-FormCategory-425px"
          >
            <TextHeaderComponents
              textHeaderProps={"Required Attachment"}
              textSubProps={"จำเป็นต้องแนบไฟล์:"}
            />
          </Col>
          <Col
            xs={12}
            sm={10}
            xl={4}
            className="informationComponents-media-FormCategory1024px"
          >
            <div>
              <CheckboxCpmponents
                checkedProps={
                  props.InformationDataProps.templateForm?.RequiredAttachment
                }
                onChangeProps={_CheckboxCpmponents}
                keyProps={"RequiredAttachment"}
              />
            </div>
          </Col>
        </Row>
        {/* 
        <EmployeeDialog
          isDialogVisibleProps={isDialogVisible}
          dataProps={employee}
          setDataProps={setSearchData}
          setisDialogVisibleProps={setDialogVisible}
          showModalProps={showModal}
          keyProps={key}
          onRowSelectProps={_AutoComplete}
          searchDataProps={searchData}
          valueProps={key === "CcId" ? ccSelect : toSelect}
        /> */}

        <SelectDataDialog
          dialogKey={"Employee"}
          dataList={employee}
          onSelectFunc={onSelectedEmployeeToAndPass}
          mutiable
          columns={[
            {
              field: "EmployeeCode",
              headerEn: "EmployeeCode",
              headerTh: "รหัสพนักงาน",
            },
            {
              field: "NameEn",
              headerEn: "Name",
              headerTh: "ชื่อ",
            },
            {
              field: "PositionNameEn",
              headerEn: "Position",
              headerTh: "ตำแหน่ง",
            },
            {
              field: "DepartmentNameEn",
              headerEn: "Department",
              headerTh: "หน่วยงาน",
            },
          ]}
          dialogVisible={isDialogVisible}
          setDialogVisible={setDialogVisible}
          loading={showDialogEmp}
        />
      </div>
    </>
  );
};
