import { FormInstance, Tabs } from "antd";
import React from "react";
import {
  BarsOutlined,
  CloseOutlined,
  CloudUploadOutlined,
  ContainerOutlined,
  InfoCircleOutlined,
  ProfileOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import InformationTab from "./TabsFormComponent/InformationTab";
import MatchFieldsTab from "./TabsFormComponent/MatchFieldsTab";
import { advanceFormItemType } from "../../../Helper/LogicHelper";
import EventTab from "./TabsFormComponent/EventTab";
import ConditionTab from "./TabsFormComponent/ConditionTab";
import APITab from "./TabsFormComponent/APITab";
import ReturnTab from "./TabsFormComponent/ReturnTab";
import ReturnMessageTab from "./TabsFormComponent/ReturnMessageTab";

type Props = {
  templateName: string;
  advanceFormItem: advanceFormItemType[];
  form: FormInstance<any>;
};

const ActionserviceForm = ({ templateName, advanceFormItem, form }: Props) => {
  return (
    <Tabs type="card" defaultActiveKey="1">
      <Tabs.TabPane
        key={"1"}
        tab={
          <div
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <InfoCircleOutlined
              style={{
                display: "flex",
                alignItems: "center",
              }}
              rev={undefined}
            />
            Information
          </div>
        }
      >
        <InformationTab templateName={templateName} />
      </Tabs.TabPane>
      <Tabs.TabPane
        key={"2"}
        tab={
          <div
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <InfoCircleOutlined
              style={{
                display: "flex",
                alignItems: "center",
              }}
              rev={undefined}
            />
            Match Field
          </div>
        }
      >
        <MatchFieldsTab advanceFormItem={advanceFormItem} form={form} />
      </Tabs.TabPane>
      <Tabs.TabPane
        key={"3"}
        tab={
          <div
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <InfoCircleOutlined
              style={{
                display: "flex",
                alignItems: "center",
              }}
              rev={undefined}
            />
            Event
          </div>
        }
      >
        <EventTab form={form} advanceFormItem={advanceFormItem} />
      </Tabs.TabPane>
      <Tabs.TabPane
        key={"4"}
        tab={
          <div
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <InfoCircleOutlined
              style={{
                display: "flex",
                alignItems: "center",
              }}
              rev={undefined}
            />
            Condition
          </div>
        }
      >
        <ConditionTab
          data={form.getFieldValue("Condition")}
          onChange={(data) => {
            let _data = form.getFieldsValue();
            _data.Condition = data;
            form.setFieldsValue(_data);
          }}
        />
      </Tabs.TabPane>
      <Tabs.TabPane
        key={"5"}
        tab={
          <div
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <InfoCircleOutlined
              style={{
                display: "flex",
                alignItems: "center",
              }}
              rev={undefined}
            />
            API
          </div>
        }
      >
        <APITab />
      </Tabs.TabPane>
      <Tabs.TabPane
        key={"6"}
        tab={
          <div
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <InfoCircleOutlined
              style={{
                display: "flex",
                alignItems: "center",
              }}
              rev={undefined}
            />
            Return
          </div>
        }
      >
        <ReturnTab advanceFormItem={advanceFormItem} form={form} />
      </Tabs.TabPane>
      <Tabs.TabPane
        key={"7"}
        tab={
          <div
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <InfoCircleOutlined
              style={{
                display: "flex",
                alignItems: "center",
              }}
              rev={undefined}
            />
            Return Message
          </div>
        }
      >
        <ReturnMessageTab />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default ActionserviceForm;
