import { Popover } from "antd";
import React from "react";
import FloatingButton from "../Button/FloatingButton";
import { BellOutlined, CloseCircleOutlined } from "@ant-design/icons";
import NotiPopOverContent from "./NotiPopOverContent";

type Props = {};

const NotificationComponent = (props: Props) => {
  return (
    <Popover
      key={"noti"}
      placement="bottomRight"
      content={() => <NotiPopOverContent />}
      trigger="click"
    >
      <FloatingButton
        onClick={() => {}}
        icon={<BellOutlined rev={undefined} style={{ fontSize: 20 }} />}
      />
    </Popover>
  );
};

export default NotificationComponent;
