export const formatKeyLogicData = (logicData: any) => {
  try {
    const _logicData = { ...logicData, data: [...logicData.data] };

    if (
      !_logicData.data.some((item: any) => item.item === "-- Please Select --")
    ) {
      _logicData.data.unshift({ item: "-- Please Select --" });
    }

    _logicData.data = _logicData.data.map((data: any) => {
      if (data[logicData.labelactionfield]) {
        return { item: data[logicData.labelactionfield] };
      }
      return data;
    });

    _logicData.data = Array.from(
      new Map(
        _logicData.data.map((item: { item: any }) => [item.item, item])
      ).values()
    );

    return _logicData;
  } catch (error) {
    console.error("logic=>formatKeyLogicData=>error", error);
  }
};
