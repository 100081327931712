import React, { useState } from "react";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { Form, Space, Input, Button, Divider, FormInstance } from "antd";
import { advanceFormItemType } from "../../../../Helper/LogicHelper";
import ReturnDataModal from "./ReturnDataModal";
import { ButtonComponents } from "../../../ButtonComponents/ButtonComponents";

type Props = {
  advanceFormItem: advanceFormItemType[];
  form: FormInstance<any>;
};

const ReturnTab = ({ advanceFormItem, form }: Props) => {
  const [selectedDataIdx, setSelectedDataIdx] = useState<number>(-1);

  return (
    <>
      <Divider>Return</Divider>
      <Form.List name={["Return"]}>
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map(({ key, name, ...restField }, idx) => (
                <Space
                  key={idx}
                  size={"middle"}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  align="start"
                >
                  <Form.Item name={[name, "HEAD"]}>
                    <Input placeholder="Header" />
                  </Form.Item>
                  <Form.Item name={[name, "TYPE"]}>
                    <Input placeholder="Type" />
                  </Form.Item>
                  <Form.Item name={[name, "TABLENAME"]}>
                    <Input placeholder="Table name" />
                  </Form.Item>

                  <ButtonComponents
                    key={"data" + idx}
                    setStyleProps={{
                      width: "150px",
                      borderRadius: "6px",
                      boxShadow: "none",
                      border: "1px solid #282f6a",
                      fontSize: "13px",
                      paddingLeft: "16px",
                    }}
                    typeProps={"button"}
                    setClassNameProps={"p-button-text-position"}
                    setLabelProps={"Data"}
                    onClickProps={() => {
                      setSelectedDataIdx(idx);
                    }}
                  />
                  <MinusCircleOutlined
                    onClick={() => remove(name)}
                    rev={undefined}
                  />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined rev={undefined} />}
                >
                  Add
                </Button>
              </Form.Item>
            </>
          );
        }}
      </Form.List>
      <ReturnDataModal
        rowData={
          selectedDataIdx !== -1
            ? form.getFieldValue("Return")[selectedDataIdx]?.DATA ?? []
            : undefined
        }
        onSubmit={(formData: { DATA: any[] }) => {
          if (formData.DATA.length > 0) {
            let allmatchSAPField = form.getFieldsValue();
            if (allmatchSAPField["Return"][selectedDataIdx]) {
              allmatchSAPField["Return"][selectedDataIdx].DATA = formData.DATA;
            } else {
              allmatchSAPField["Return"][selectedDataIdx] = formData;
            }

            form.setFieldsValue(allmatchSAPField);
          }
          setSelectedDataIdx(-1);
        }}
      />
    </>
  );
};

export default ReturnTab;
