import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Checkbox, Divider, Form, Input } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import { FilterDropdownProps, FilterValue } from "antd/lib/table/interface";
import { ColumnsProps } from "./DynamicTable";
import { onChanceRef } from '../../../Helper/RequestRefFunctions';
const CheckboxGroup = Checkbox.Group;
type Props = {
  colProp: ColumnsProps;
  columnLabel: string;
  option: any[];
  filterDropDownProps: FilterDropdownProps;
  filterState?: Record<string, FilterValue | null>;
  dataSource: any[];
  allColumnProps: ColumnsProps[];
  isCheckAll:(check:boolean)=>void
};

const SearchTextInTable = forwardRef(({
  isCheckAll,
  colProp,
  columnLabel,
  filterDropDownProps,
  filterState,
  dataSource,
  allColumnProps,
}: Props, ref) => {
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);
  const [checkAll, setCheckAll] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [columnOption, setColumnOption] = useState<any[]>([]);
  const [isLoadOption,setIsLoadOption] =useState<boolean>(false)
  useEffect(() => {
    filterDropDownProps.setSelectedKeys([...checkedList] as any);
  }, [checkedList]);

  const resetCheck = () => {
    setCheckAll(false)
    setCheckedList([])
  };
  
  useImperativeHandle(ref, () => ({
    resetCheck
  }));
  useEffect(() => {

    if (!filterDropDownProps.visible) return;
    setIsLoadOption(true)
    setColumnOption(filterDataSource() ?? []);
    setIsLoadOption(false)
  }, [filterDropDownProps.visible,checkedList]);
  
  const filterDataSource = () => {
    if (filterState) {
      const filterKeys = Object.keys(filterState).filter((key) => {
        const value = filterState[key];
        return value !== null && value !== undefined && value.length > 0;
      });
      const indexOfKey = filterKeys.indexOf(colProp.key) 
      
      if (indexOfKey > 0) {
        const keysToConsider = filterKeys.slice(
          0,
          filterKeys.indexOf(colProp.key)
        );

        const result = dataSource.filter((item) => {
          return keysToConsider.every((key) => {
            const filterColumnPorps = allColumnProps.find((e) => e.key === key);
            const filterValue: any = filterState[key];
            if (filterColumnPorps?.type === "date") {
              if (!filterValue) return true;
              if (!filterValue[0]) return true;
              return item[key]
                ?.add(1, "seconds")
                .isBetween(filterValue[0][0], filterValue[0][1]);
            } else if (filterColumnPorps?.type === "datetime") {
              if (!filterValue) return true;
              if (!filterValue[0]) return true;
              return item[key]?.isBetween(filterValue[0][0], filterValue[0][1]);
            } else if (filterColumnPorps?.type === "number") {
              const recordVal = Number(item[key]);
              return (
                recordVal >= filterValue[0][0] && recordVal <= filterValue[0][1]
              );
            } else {
              return filterState[key]?.includes(item[key]);
            }
          });
        });

        return Array.from(new Set(result.map((e) => e[colProp.key])));
      } else if (indexOfKey === -1) {
        const result = dataSource.filter((item) => {
          return filterKeys.every((key) => {
            const filterColumnPorps = allColumnProps.find((e) => e.key === key);

            const filterValue: any = filterState[key];

            if (filterColumnPorps?.type === "date") {
              if (!filterValue) return true;
              if (!filterValue[0]) return true;
              return item[key]
                ?.add(1, "seconds")
                .isBetween(filterValue[0][0], filterValue[0][1]);
            } else if (filterColumnPorps?.type === "datetime") {
              if (!filterValue) return true;
              if (!filterValue[0]) return true;
              return item[key]?.isBetween(filterValue[0][0], filterValue[0][1]);
            } else if (filterColumnPorps?.type === "number") {
              const recordVal = Number(item[key]);
              return (
                recordVal >= filterValue[0][0] && recordVal <= filterValue[0][1]
              );
            } else {
              return filterState[key]?.includes(item[key]);
            }
          });
        });

        return Array.from(new Set(result.map((e) => e[colProp.key])));
      } else if (indexOfKey === 0) {

        return Array.from(new Set(dataSource?.map((e) => e[colProp.key]))).map(
          (e) => {
            return e?.toString();
          }
        );
      }
    }
  };


  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
    setCheckAll(
      list.length ===
      columnOption.filter((e: string) =>
        e
          ?.toString()
          ?.toLowerCase()
          .includes(searchValue?.toString()?.toLowerCase())
      ).length
    );
  };


  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(
      e.target.checked
        ? columnOption.filter((e: string) =>
          e
            ?.toString()
            ?.toLowerCase()
            .includes(searchValue?.toString()?.toLowerCase())
        ).map((e) => e?.toString())
        : []
    );
    setCheckAll(e.target.checked);
  };

  useEffect(()=>{
    if(checkAll){
      if(!searchValue) isCheckAll(checkAll) 
      else isCheckAll(false)
    }else isCheckAll(false)
  },[checkAll])
  
  const onSearchCahnge = (value: string) => {
    // setCheckedList(
    //   columnOption.filter((e: string) =>
    //     e?.toString()?.toLowerCase().includes(value?.toString()?.toLowerCase())
    //   )
    // );
    setCheckAll(false);
    setSearchValue(value);
  };

  
  const checkAllChange = (e: boolean) => {
    setCheckedList(
      e
        ? columnOption.filter((e: string) =>
          e
            ?.toString()
            ?.toLowerCase()
            .includes(searchValue?.toString()?.toLowerCase())
        ).map((e) => e?.toString())
        : []
    );
    setCheckAll(e);
  };
  
  
  useEffect(()=>{
    filterState &&filterState[columnLabel] &&setCheckedList(filterState[columnLabel])
  },[filterDropDownProps.visible])
  useEffect(()=>{
    if (!filterDropDownProps.visible && !isLoadOption) return;
    const filterBycolumn=filterState&&filterState[columnLabel]

    if(filterState&&Object.keys(filterState).length===0 || filterBycolumn ===undefined ||
    filterBycolumn === null
  ){
      setCheckAll(true)
      checkAllChange(true)
    }
    
  },[filterDropDownProps.visible,columnLabel,isLoadOption])
  
  useEffect(()=>{
    if (filterDropDownProps.visible) return;
    const filterBycolumn=filterState&&filterState[columnLabel]
    if(filterBycolumn?.length === columnOption.length)setCheckAll(true)
  },[filterDropDownProps.visible])
  return (
    <>
      <div >
        <Input
          placeholder={`Search in ${columnLabel}`}
          onChange={(e) => {
            onSearchCahnge(e.target.value);
          }}
        />
      </div>
      <div>
        <Checkbox onChange={onCheckAllChange} checked={checkAll}>
          Select All
        </Checkbox>
        <Divider style={{ margin: 5 }} />
        <Form
          style={{ maxWidth: 600, maxHeight: 300, overflowY: 'auto', display: 'flex', flexDirection: 'column' }}
        >
          <Form.Item name="items" valuePropName="checked">
            <Checkbox.Group
              value={checkedList.filter(option => columnOption.includes(option))} // Filter checkedList based on visible options
              style={{
                paddingLeft: 20,
                display: "flex",
                flexDirection: "column",
                overflowY: "scroll",
                maxHeight: "20vh",
              }}
            >
              {columnOption
                .filter((e: string) =>
                  e
                    ?.toString()
                    ?.toLowerCase()
                    .includes(searchValue?.toString()?.toLowerCase())
                )
                .map((option, idx) => (
                  <Checkbox
                    key={idx}
                    value={option}
                    checked={!checkedList.includes(option)}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      const option = e.target.value;
                      let updatedSet = new Set(checkedList); 

                      if (isChecked) {
                        updatedSet.add(option); 
                      } else {
                        updatedSet.delete(option); 
                      }

                      setCheckedList(Array.from(updatedSet)); 
                      setCheckAll(
                        Array.from(updatedSet).length ===
                        columnOption.filter((e: string) =>
                          e
                            ?.toString()
                            ?.toLowerCase()
                            .includes(searchValue?.toString()?.toLowerCase())
                        ).length
                      );
                    }}
                  >
                    {option?.toString()}
                  </Checkbox>
                ))}
            </Checkbox.Group>
          </Form.Item>
        </Form>
      </div>
    </>
  );
});

export default SearchTextInTable;
