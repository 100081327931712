import React, { useEffect, useState } from "react";

type Props = {
  items: { key: string; label: string }[];
  onChange: (key: string) => void;
};

const TabsButton = ({ items, onChange }: Props) => {
  const [activeKey, setActiveKey] = useState("text");

  useEffect(() => {
    onChange(activeKey);
  }, [activeKey]);

  return (
    <>
      <div className="tabs-group">
        {items.map((e) => (
          <div
            className={`tab-button ${activeKey === e.key ? "active" : ""}`}
            key={e.key}
            title={e.label}
            onClick={() => {
              setActiveKey(e.key);
            }}
          >
            {e.label}
          </div>
        ))}
      </div>
    </>
  );
};

export default TabsButton;
