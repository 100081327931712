import { Divider, Form, Space, Input, Button } from "antd";
import React from "react";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";

const APITab = () => {
  return (
    <>
      <Divider>API</Divider>
      <Form.Item label="Link" name={["API", "Link"]}>
        <Input placeholder="Link" />
      </Form.Item>
      <Form.List name={["API", "Header"]}>
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map(({ key, name, ...restField }, idx) => (
                <Space
                  key={idx}
                  size={"middle"}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  align="start"
                >
                  <Form.Item {...restField} name={[name, "KEY"]}>
                    <Input placeholder="Key" />
                  </Form.Item>
                  <Form.Item {...restField} name={[name, "VALUE"]}>
                    <Input placeholder="Value" />
                  </Form.Item>
                  <MinusCircleOutlined
                    onClick={() => remove(name)}
                    rev={undefined}
                  />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined rev={undefined} />}
                >
                  Add
                </Button>
              </Form.Item>
            </>
          );
        }}
      </Form.List>
    </>
  );
};

export default APITab;
