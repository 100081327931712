import { Form, Select, FormInstance } from "antd";
import React, { useEffect, useState } from "react";
import { advanceFormItemType, enumToObject } from "../../../Helper/LogicHelper";
import { ILogicTypeReladToLoadData } from "../../../IRequestModel/ILogicModel";
import { LogicType } from "../../../constants/logic-type";
import HideAndReadOnlyControlForm from "./LogicFormByType/ShowHideControlForm";
import DataSourceLoadForm from "./LogicFormByType/DataSourceLoadForm";
import DataSourceRelatedForm from "./LogicFormByType/DataSourceRelatedForm";
import DataSourceRelatedToLoadData from "./LogicFormByType/DataSourceRelatedToLoadData";
import ShowHideByRoles from "./LogicFormByType/ShowHideByRoles";
import SpecialActionServiceForm from "./LogicFormByType/SpecialActionServiceForm";
import SetValueControlForm from "./LogicFormByType/SetValueControlForm";
const { Option } = Select;

export type LogicFormProps = {
  advanceFormItem: advanceFormItemType[];
  form: FormInstance<any>;
};
export const refInfors = [
  { label: "Requestor_Employee_code", value: "Requestor_EmployeeCode" },
  { label: "Requestor_NameEn", value: "Requestor_NameEn" },
  { label: "Requestor_NameTh", value: "Requestor_NameTh" },
  { label: "Requestor_PositonEn", value: "Requestor_PositonEn" },
  { label: "Requestor_PositonTh", value: "Requestor_PositonTh" },
  { label: "Requestor_DivisionEn", value: "Requestor_DivisionEn" },
  { label: "Requestor_DivisionTh", value: "Requestor_DivisionTh" },
  { label: "Requestor_DepartmentEn", value: "Requestor_DepartmentEn" },
  { label: "Requestor_DepartmentTh", value: "Requestor_DepartmentTh" },
  { label: "Requestor_Email", value: "Requestor_Email" },
  { label: "Actor_Employee_code", value: "Actor_EmployeeCode" },
  { label: "Actor_NameEn", value: "Actor_NameEn" },
  { label: "Actor_NameTh", value: "Actor_NameTh" },
  { label: "Actor_PositonEn", value: "Actor_PositonEn" },
  { label: "Actor_PositonTh", value: "Actor_PositonTh" },
  { label: "Actor_DivisionEn", value: "Actor_DivisionEn" },
  { label: "Actor_DivisionTh", value: "Actor_DivisionTh" },
  { label: "Actor_DepartmentEn", value: "Actor_DepartmentEn" },
  { label: "Actor_DepartmentTh", value: "Actor_DepartmentTh" },
  { label: "Actor_Email", value: "Actor_Email" },
  { label: "Company_Company Id", value: "Company_Company id" },
  { label: "Company_Company name", value: "Company_Company name" },
];

const JsonLogicForm = ({ form, advanceFormItem }: LogicFormProps) => {
  const readableLabels = enumToObject(LogicType);
  const sortedOptions = Object.entries(readableLabels)
    .sort((a, b) => a[1].localeCompare(b[1]))
    .filter((e) => {
      return (
        e[0] === LogicType.HideControl ||
        e[0] === LogicType.ReadOnlyControl ||
        e[0] === LogicType.DataSourceLoad ||
        e[0] === LogicType.DataSourceRelated ||
        e[0] === LogicType.DataRelatedToLoadData ||
        e[0] === LogicType.Role ||
        e[0] === LogicType.SpecialActionService ||
        e[0] === LogicType.SetValueControl
      );
    });
  const [dynamicForm, setDynamicForm] = useState<React.ReactNode>(<></>);
  useEffect(() => {
    getFormByType(form.getFieldValue("type"));
  }, []);

  const onValueChange = (changedValues: any, allValues: any) => {
    if (!changedValues["type"]) return;
    getFormByType(changedValues["type"]);
  };

  const getFormByType = (type: string) => {
    switch (type) {
      case LogicType.HideControl:
        setDynamicForm(
          <HideAndReadOnlyControlForm
            form={form}
            advanceFormItem={advanceFormItem}
          />
        );
        break;
      case LogicType.ReadOnlyControl:
        setDynamicForm(
          <HideAndReadOnlyControlForm
            form={form}
            advanceFormItem={advanceFormItem}
          />
        );
        break;
      case LogicType.DataSourceLoad:
        setDynamicForm(
          <DataSourceLoadForm form={form} advanceFormItem={advanceFormItem} />
        );
        break;
      case LogicType.DataSourceRelated:
        setDynamicForm(
          <DataSourceRelatedForm
            advanceFormItem={advanceFormItem}
            form={form}
          />
        );
        break;
      case LogicType.DataRelatedToLoadData:
        setDynamicForm(
          <DataSourceRelatedToLoadData
            advanceFormItem={advanceFormItem}
            form={form}
          />
        );

        break;
      case LogicType.Role:
        setDynamicForm(
          <ShowHideByRoles advanceFormItem={advanceFormItem} form={form} />
        );
        break;
      case LogicType.SpecialActionService:
        setDynamicForm(
          <SpecialActionServiceForm
            advanceFormItem={advanceFormItem}
            form={form}
          />
        );
        break;
      case LogicType.SetValueControl:
        setDynamicForm(
          <SetValueControlForm advanceFormItem={advanceFormItem} form={form} />
        );
        break;
      default:
        break;
    }
  };
  return (
    <>
      {" "}
      <Form
        form={form}
        onValuesChange={onValueChange}
        onFinish={() => {}}
        labelCol={{ span: 4 }}
        validateTrigger={["onValuesChange", "onBlur", "onFinish"]}
      >
        <Form.Item label="Logic type" name="type">
          <Select showSearch>
            {sortedOptions.map(([value, label], index) => (
              <Option key={index} value={value}>
                {label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {dynamicForm}
      </Form>
    </>
  );
};

export default JsonLogicForm;
