import React, { useEffect, useState } from "react";
import "./WorklistScreenComponent.css";
interface Props {
  approvals?: any;
  requestor?: any;
  memoDetail: any;
}

export const FlowCardComponent = (props: Props) => {
  console.log(
    props.approvals?.sequence ===
      props.memoDetail.requestDetails.memoDetail?.current_approval_level
  );

  return (
    <>
      <div
        className={`flow-card-container ${
          props.approvals &&
          props.approvals.sequence ===
            props.memoDetail.requestDetails.memoDetail
              ?.current_approval_level &&
          "highlight"
        }`}
      >
        <div className="img-flow-card">
          <div className="circle-text">
            {props.approvals && (
              <p className="text-profile">
                {props.approvals.approver &&
                  props.approvals.approver.nameEn.substring(0, 2).toUpperCase()}
              </p>
            )}
            {props.requestor && (
              <p className="text-profile">
                {props.requestor &&
                  props.requestor.NameEn.substring(0, 2).toUpperCase()}
              </p>
            )}
          </div>
        </div>
        <div className="flow-card-detail">
          <div className="text-user-name">
            {props.approvals && (
              <>
                <p className="user-name">{props.approvals.approver.nameEn}</p>
                <p className="user-role">
                  {props.approvals.approver.positionNameEn}
                </p>
              </>
            )}
            {props.requestor && (
              <>
                <p className="user-name">{props.requestor.NameEn}</p>
                <p className="user-role">{props.requestor.PositionNameEn}</p>
              </>
            )}
          </div>
          {/* change text color */}
          <p className="text-status highlight-text">
            {props.approvals && props.approvals.approver.departmentNameEn}
            {props.requestor && props.requestor.DepartmentNameEn}
          </p>
        </div>
        {props.approvals && (
          <div className="status-badge">
            <span>{props.approvals.signature_en}</span>
          </div>
        )}
      </div>
    </>
  );
};
