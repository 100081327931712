import React, {
  RefAttributes,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import "../PDFTemplateComponent/PDFTemplateComponent.css";
import PDFElementBox from "./PDFElementBox";
import GridLayout from "react-grid-layout";
import usePDFEditor from "../../../hooks/usePDFEditor";
import "react-grid-layout/css/styles.css"; // CSS for react-grid-layout
import "react-resizable/css/styles.css"; //
import DropableComponent from "./DropableComponent";
import { DragOutlined } from "@ant-design/icons";
import { Button, Tag } from "antd";
import { IControlLayout } from "../../../Context/PDFEditorContext";
import { findMaxInArrayObject } from "../../../Helper/arrayHelper";
const ResponsiveGridLayout = WidthProvider(Responsive);

interface props {
  scale: number;
}

const PaperLayout = React.memo(({ scale }: props) => {
  const {
    paperLayout,
    addLayout,
    pageProperty,
    setPaperLayout,
    updatePageProperties,
    refHtml,
    selectionBlock,
    onDeselected,
  } = usePDFEditor();

  const refPaper = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleZoom = () => {
      // Set the scale based on your zoom logic

      // Center the paper div within the board
      if (refPaper.current && refPaper.current?.parentElement) {
        const boardWidth = refPaper.current?.parentElement?.clientWidth;
        const boardHeight = refPaper.current?.parentElement?.clientHeight;
        const paperWidth = refPaper.current.clientWidth * scale;
        const paperHeight = refPaper.current.clientHeight * scale;

        const offsetX = (boardWidth - paperWidth) / 2;
        const offsetY = (boardHeight - paperHeight) / 2;

        refPaper.current.style.transform = `scale(${scale})`;
        refPaper.current.style.left = `${offsetX}px`;
        // refPaper.current.style.top = `${offsetY}px`;
      }
    };

    handleZoom();
  }, [scale]);

  const checkForNewPage = (height: number) => {
    if (height / pageProperty.pageAmount > pageProperty.pageSize?.height) {
      updatePageProperties({
        pageAmount: pageProperty.pageAmount + 1,
      });
    } else if (
      height > pageProperty.pageSize?.height &&
      height <= pageProperty.pageSize?.height * pageProperty.pageAmount &&
      height <= pageProperty.pageSize?.height * (pageProperty.pageAmount - 1)
    ) {
      updatePageProperties({
        pageAmount: pageProperty.pageAmount - 1,
      });
    } else if (
      pageProperty.pageAmount - 1 === 1 &&
      height < pageProperty.pageSize?.height
    ) {
      updatePageProperties({
        pageAmount: 1,
      });
    }
  };

  const breakPages = () => {
    const lines: any[] = [];
    for (let i = 1; i < pageProperty.pageAmount; i++) {
      lines.push(
        <div
          className="red-line"
          style={{ top: `${pageProperty.pageSize.height * i}px` }}
        ></div>
      );
    }
    return lines;
  };

  const renderedPaperLayout = useMemo(() => {
    return paperLayout.map((e: any) => {
      e.isBounded = true;
      return (
        <div
          className={`custom-grid ${e.i}`}
          key={e.i}
          data-grid={e}
          style={{ border: "1px solid" }}
        >
          <Tag color="success">{e.i}</Tag>
          <DropableComponent
            name={e.type}
            paperWidth={refPaper.current?.offsetWidth || 1200}
            children={e.children || []}
          />
          {e.i === "body" && breakPages()}
        </div>
      );
    });
  }, [paperLayout]);

  return (
    <>
      <div
        className="paper"
        ref={refPaper}
        style={{
          width: `${pageProperty.pageSize?.width}px`,
        }}
      >
        <div ref={refHtml}>
          <GridLayout
            className="main-layout paper"
            isResizable
            style={{
              backgroundSize: `${
                selectionBlock !== undefined
                  ? `${pageProperty.gridSize}px ${pageProperty.gridSize}px`
                  : "0px 0px"
              }`,
            }}
            cols={12}
            rowHeight={15}
            margin={[0, 0]}
            preventCollision={false}
            width={refPaper.current?.offsetWidth}
            autoSize
            isBounded
            layout={paperLayout}
            isDroppable={
              pageProperty.dragingType !== "content" &&
              pageProperty.dragingType !== undefined
            }
            isDraggable={false}
            onResize={() => {
              const bodyElement = refPaper.current?.querySelector(".body");
              if (bodyElement instanceof HTMLElement) {
                checkForNewPage(bodyElement.offsetHeight);
              }
            }}
            onResizeStop={(layouts, oldItem, newItem) => {
              setPaperLayout([
                ...paperLayout.map((layout) => {
                  if (layout?.children) {
                    if (layout.i === newItem.i) {
                      layout.w = newItem.w;
                      layout.h = newItem.h;
                    }
                  }

                  return layout;
                }),
              ]);
            }}
          >
            {renderedPaperLayout}
          </GridLayout>
        </div>
      </div>
    </>
  );
});

export default PaperLayout;
