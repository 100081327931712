import { Select, Divider, Form, Button, Input, Space } from "antd";
import React, { useEffect, useState } from "react";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { getScreenWidthWithBreakpoint } from "../../../../Helper/ScreenHelper";
import DataSourceRelateConditionRow from "./DataSourceRelateConditionRow";
import { LogicFormProps } from "../JsonLogicForm";

const { Option, OptGroup } = Select;

const DataSourceRelatedForm = ({ form, advanceFormItem }: LogicFormProps) => {
  const [breakpoint, setBreakpoint] = useState(getScreenWidthWithBreakpoint());
  useEffect(() => {
    const handleResize = () => {
      setBreakpoint(getScreenWidthWithBreakpoint());
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const controlOption: any[] = advanceFormItem
    .map((item) => {
      if (item.type === "tb" && item.columns) {
        return {
          label: "Tb :" + item.label,
          options: item.columns.map((col) => ({
            label: col.label,
            value: col.label + "|Tb",
          })),
        };
      }
      return {
        label: item.label,
        value: item.label,
      };
    })
    .filter((e) => e.label);

  return (
    <>
      <Form.Item label="Action control" name="label">
        <Select
          placeholder="Compare with control"
          style={{ minWidth: 200 }}
          showSearch
        >
          {controlOption.map((item: any, index) => {
            if (item.options) {
              return (
                <>
                  <Option key={item.label} value={item.value}>
                    {item.label}
                  </Option>
                  <OptGroup label={item.label + "'s columns"}>
                    {item.options.map((option: any) => (
                      <Option
                        key={item.label + "'s columns" + option.value}
                        value={option.value}
                      >
                        {option.label}
                      </Option>
                    ))}
                  </OptGroup>
                </>
              );
            } else {
              return (
                <Option key={item.label} value={item.value}>
                  {item.label}
                </Option>
              );
            }
          })}
        </Select>
      </Form.Item>
      <Form.Item label="Table" name="table">
        <Input placeholder="Table" />
      </Form.Item>
      <Divider>Related values</Divider>
      <Form.List name="relatedvalue">
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map(({ key, name, ...restField }, idx) => (
                <Space
                  key={idx}
                  size={"middle"}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  align="start"
                >
                  <Form.Item
                    {...restField}
                    name={[name, "label"]}
                    rules={[
                      { required: true, message: "Missing related control" },
                    ]}
                  >
                    <Select
                      placeholder="Related to control"
                      style={{ minWidth: 200 }}
                      showSearch
                    >
                      {" "}
                      {controlOption.map((item: any, index) => {
                        if (item.options) {
                          return (
                            <>
                              <Option key={item.label} value={item.value}>
                                {item.label}
                              </Option>
                              <OptGroup label={item.label + "'s columns"}>
                                {item.options.map((option: any) => (
                                  <Option
                                    key={
                                      item.label + "'s columns" + option.value
                                    }
                                    value={option.value}
                                  >
                                    {option.label}
                                  </Option>
                                ))}
                              </OptGroup>
                            </>
                          );
                        } else {
                          return (
                            <Option key={item.label} value={item.value}>
                              {item.label}
                            </Option>
                          );
                        }
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "value"]}
                    rules={[{ required: true, message: "Missing value" }]}
                  >
                    <Input placeholder="value" />
                  </Form.Item>
                  <MinusCircleOutlined
                    onClick={() => remove(name)}
                    rev={undefined}
                  />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined rev={undefined} />}
                >
                  Add value
                </Button>
              </Form.Item>
            </>
          );
        }}
      </Form.List>

      <Divider>Conditions</Divider>
      <Form.List name="conditions">
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map(({ ...restField }, idx) => (
                <DataSourceRelateConditionRow
                  {...restField}
                  remove={remove}
                  onRowChange={(rowData) => {
                    const value = form.getFieldsValue();
                    value.conditions = value.conditions.map(
                      (e: any, idx: string | number) => {
                        if (rowData[idx]) {
                          return rowData[idx];
                        }
                        return e;
                      }
                    );
                    form.setFieldsValue(value);
                  }}
                  defaultValue={form.getFieldValue("conditions")[idx]}
                />
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined rev={undefined} />}
                >
                  Add condition
                </Button>
              </Form.Item>
            </>
          );
        }}
      </Form.List>
    </>
  );
};

export default DataSourceRelatedForm;
