import React, { useEffect, useState } from "react";
import { AutoComplete, Button, Col, Row } from "antd";
import { TextHeaderComponents } from "../../../../components/TextHeaderComponents/TextHeaderComponents";
import { ReportTable } from "./ReportTable";
import withPerMission from "../../../../components/HOC/withPermission";

const PaymentReportScreen = (props: PaymentReportProps) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any[]>([]);

  const [searchValue, setSearchValue] = useState("");
  const [searchOptions, setSearchOptions] = useState<{ value: string }[]>([]);

  useEffect(() => {
    const abortController = new AbortController();
    retrieveData(abortController.signal);
    return () => {
      abortController.abort("abort");
    };
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    const handler = setTimeout(() => {
      fetchAutocompleteOptions(searchValue, 10, abortController.signal).then(
        (value) => {
          setSearchOptions(value);
        }
      );
    }, 1500);

    return () => {
      abortController.abort();
      clearTimeout(handler);
    };
  }, [searchValue]);

  function onSearch(textSearch: string) {
    setSearchValue(textSearch);
  }

  function onSelect(textSearch: string) {
    setSearchValue(textSearch);
  }

  async function fetchAutocompleteOptions(
    q: string,
    count: number,
    signal: AbortSignal
  ) {
    const reqBody = {
      q: q,
      count: count,
    };
    let resData: { value: string }[] = await fetch(
      `api/Frasers/PaymentReport/GetAutocompleteOptions`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
        signal: signal,
      }
    )
      .then((r) => (r.ok ? r.json() : Promise.resolve({ data: [] })))
      .then((r) => r.data);
    return resData;
  }

  async function retrieveData(signal?: AbortSignal) {
    setLoading(true);
    const reqBody = {
      searchValue: searchValue,
    };
    let resData: PaymentReportResponse["data"] = await fetch(
      "api/Frasers/GetPaymentReport",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
        signal: signal,
      }
    )
      .then((r) => r.json())
      .then((r) => r.data);
    if (Array.isArray(resData)) {
      resData = resData.map((x) => {
        if (Array.isArray(x.children)) {
          x.children = x.children.map((y) => {
            y.key = `${x.desc}_${y.desc}`;
            return y;
          });
        }
        return x;
      });
    }
    setData([...resData]);
    setLoading(false);
  }

  return (
    <>
      {loading && (
        <div className="logo-loading">
          <img src={props.responeConfig?.pathLoading} alt="loading..." />
        </div>
      )}
      <div className="main-container">
        <div className="worklist-container">
          <div>
            <br />
            <h3>Payment Voucher Report</h3>
            <form
              onSubmit={() => {
                retrieveData();
              }}
            >
              <div>
                <Row style={{ paddingBottom: "10px" }}>
                  <Col xs={24} sm={6} md={2}>
                    <TextHeaderComponents
                      textHeaderProps={"Vendor Name"}
                      textSubProps={""}
                    />
                  </Col>
                  <Col xs={24} sm={18} md={22}>
                    <span
                      style={{
                        width: "100%",
                        maxWidth: "500px",
                        display: "inline-block",
                      }}
                    >
                      <AutoComplete
                        value={searchValue}
                        options={searchOptions}
                        style={{ width: "100%" }}
                        onSearch={onSearch}
                        onSelect={onSelect}
                        placeholder="Search by Vendor Name"
                        allowClear
                      />
                    </span>
                    <span>&nbsp;</span>
                    <Button type="primary" onClick={() => retrieveData()}>
                      Search
                    </Button>
                  </Col>
                </Row>
              </div>
            </form>
          </div>
          <div>
            <ReportTable data={data} />
          </div>
        </div>
      </div>
    </>
  );
};
export default withPerMission(PaymentReportScreen);
