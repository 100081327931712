import { Button, Form, FormInstance, Input, Select, Space } from "antd";
import form from "antd/lib/form";
import React, { useEffect, useState } from "react";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { GetAllDynamic } from "../../../../Services/DynamicService";
import { advanceFormItemType } from "../../../../Helper/LogicHelper";

type Props = {
  advanceFormItem: advanceFormItemType[];
  form: FormInstance<any>;
};

const EventTab = ({ advanceFormItem, form }: Props) => {
  const [buttonDump, setButtonDump] = useState<any[]>([]);
  const [docStatus, setDocStatus] = useState<any[]>([]);

  useEffect(() => {
    const fetchMasterData = async () => {
      const masterData = await GetAllDynamic("MasterData/GetAll", undefined);

      const masterButtonDump = masterData.find((e: any) => {
        return e.MasterType === "ButtonDump" && e.IsActive === true;
      });
      const masterDocStatus = masterData.find((e: any) => {
        return e.MasterType === "DocStatus" && e.IsActive === true;
      });

      if (masterButtonDump) {
        const obj = JSON.parse(masterButtonDump.Value1);

        setButtonDump(
          obj.map((data: any) => {
            return {
              label: data.Text,
              value: data.key,
            };
          })
        );
      }
      if (masterDocStatus) {
        const obj = JSON.parse(masterDocStatus.Value1);

        setDocStatus(
          obj.map((data: any) => {
            return {
              label: data.l,
              value: data.v,
            };
          })
        );
      }
    };
    fetchMasterData();
  }, []);
  return (
    <>
      <Form.List name="Event">
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map(({ key, name, ...restField }, idx) => (
                <Space
                  key={idx}
                  size={"middle"}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  align="start"
                >
                  <Form.Item {...restField} name={[name, "ACTION"]}>
                    <Select
                      placeholder="-- Please Select --"
                      options={buttonDump}
                      style={{ minWidth: "200px" }}
                    />
                  </Form.Item>
                  <Form.Item {...restField} name={[name, "STATUS"]}>
                    <Select
                      placeholder="-- Please Select --"
                      options={docStatus}
                      style={{ minWidth: "200px" }}
                    />
                  </Form.Item>
                  <Form.Item {...restField} name={[name, "LABEL"]}>
                    <Select
                      placeholder="-- Please Select --"
                      options={advanceFormItem.map((e) => {
                        if (e.type === "em") {
                          return { label: e.label, value: "" };
                        }
                        return { label: e.label, value: e.label };
                      })}
                      style={{ minWidth: "200px" }}
                    />
                  </Form.Item>
                  <Form.Item {...restField} name={[name, "VALUE"]}>
                    <Input placeholder="Value" />
                  </Form.Item>
                  <Form.Item {...restField} name={[name, "OUTCOME"]}>
                    <Input placeholder="Outcome" />
                  </Form.Item>
                  <Form.Item {...restField} name={[name, "FORAPPROVE"]}>
                    <Select
                      placeholder={"For approve"}
                      options={[
                        {
                          value: "",
                          label: "Empty",
                        },
                        {
                          value: "First",
                          label: "First",
                        },
                        {
                          value: "Last",
                          label: "Last",
                        },
                      ]}
                      style={{ minWidth: "200px" }}
                    />
                  </Form.Item>

                  <MinusCircleOutlined
                    onClick={() => remove(name)}
                    rev={undefined}
                  />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined rev={undefined} />}
                >
                  Add
                </Button>
              </Form.Item>
            </>
          );
        }}
      </Form.List>
    </>
  );
};

export default EventTab;
