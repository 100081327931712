import { Checkbox, Select, Table, TableProps } from "antd";
import React, { useEffect, useState } from "react";
import { IJsonCondition } from "./AddMatrixCondition";
import type { ColumnsType } from "antd/es/table";
import { MultiSelect } from "primereact/multiselect";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Dropdown } from "primereact/dropdown";

type ReadOnlyTableProps = {
  jsonConditionField: IJsonCondition[];
  templateField: any[];
  onChange: (data: IJsonCondition[]) => void;
};

const ReadOnlyTable = ({
  jsonConditionField,
  templateField,
  onChange,
}: ReadOnlyTableProps) => {
  const handleChange = (key: string, value: any, index: number) => {
    onChange(
      jsonConditionField.map((rowData, idx) => {
        if (idx === index) {
          rowData[key] = value;
        }
        return rowData;
      })
    );
  };

  const columns: ColumnsType<IJsonCondition> = [
    {
      title: "Read Only/Hide",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: 200,
      render: (val, record, index) => {
        return (
          <Dropdown
            value={val}
            onChange={(e) => handleChange("action", e.value, index)}
            options={[
              { value: "readonly", label: "Read only" },
              { value: "editable", label: "Editable" },
              { value: "hide", label: "Hide" },
            ]}
            style={{ width: "100% ", fontSize: "13px", textAlign: "left" }}
            optionLabel="label"
          />
        );
      },
    },
    {
      title: "Action Control",
      dataIndex: "fieldaction",
      align: "center",
      key: "fieldaction",
      render: (val, record, index) => {
        return (
          <>
            <MultiSelect
              id={"fieldaction"}
              value={val}
              options={templateField}
              onChange={(e) => {
                handleChange("fieldaction", e.target.value, index);
              }}
              maxSelectedLabels={3}
              placeholder="-- Please Select --"
              display="chip"
              style={{ width: "100% ", fontSize: "13px", textAlign: "left" }}
            />
          </>
        );
      },
    },
    {
      title: (
        <div
          style={{
            width: "100%",
            height: "100%",
            cursor: "pointer",
          }}
          onClick={() => {
            onChange([
              ...jsonConditionField,
              { action: "readonly", fieldaction: [], isRequester: "N" },
            ]);
          }}
        >
          <PlusCircleOutlined rev={undefined} />
        </div>
      ),
      align: "center",
      width: 100,
      render: (val, record, index) => (
        <>
          <div
            className="pi pi-fw pi-trash"
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => {
              onChange([
                ...jsonConditionField.filter((e, idx) => idx !== index),
              ]);
            }}
          />
        </>
      ),
    },
  ];

  return (
    <div>
      <Table dataSource={jsonConditionField} columns={columns} />
    </div>
  );
};

export default ReadOnlyTable;
