import React, { useEffect, ReactNode, useMemo } from "react";
import { Redirect, Route, RouteProps, useLocation } from "react-router";
import { LoginScreenOpenId } from "../screens/LoginScreen/LoginScreenOpenId";
import { useSessionContext } from "../Context/AuthContext";
import { clearLocalStorageOnLogout } from "../Helper/localStoreFunction";
import { GetAllMasterData } from "../Services/MasterDataService";

export type ProtectedRouteProps = {
  isAuthenticated: boolean;
  authenticationPath: string;
  redirectPath: string;
  setRedirectPath: (path: string) => void;
  children?: ReactNode;
} & RouteProps;

export default function PrivateRoute({
  isAuthenticated,
  authenticationPath,
  redirectPath,
  setRedirectPath,
  children,
  ...routeProps
}: ProtectedRouteProps) {
  const currentLocation = useLocation();
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  let paramString = window.location.href.split("#")[1];
  const codeURLSearch = new URLSearchParams(paramString);
  const _codeUrl = codeURLSearch.get("code");
  const [sessionContext, updateSessionContext] = useSessionContext();

  useEffect(() => {
    if (!isAuthenticated) {
      if (query.get("MemoID")) {
        localStorage.setItem("memoid", query.get("MemoID") || "");
      }
    } else {
      localStorage.removeItem("memoid");
      chaeckLastActtion();
    }
    chaeckLastActtion();
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      setRedirectPath("/login");
    }
  }, [isAuthenticated, currentLocation]);

  const chaeckLastActtion = async () => {
    try {
      const lastAction = Number(
        localStorage.getItem("lastAction") || Date.now()
      );
      const diffMilliseconds = Math.abs(Date.now() - lastAction);
      const masterData = await GetAllMasterData();
      let timeout = 60 * 60 * 1000;

      if (masterData) {
        timeout = Number(
          masterData?.find((e: any) => e.MasterType === "Timeout" && e.IsActive)
            ?.Value2
        );
        timeout =
          Number(
            masterData.find((e: any) => e.MasterType === "Timeout")?.Value2
          ) *
          60 *
          1000;
      }

      localStorage.setItem("timer", timeout.toString());

      if (diffMilliseconds >= timeout) {
        updateSessionContext({ ...sessionContext, isAuthenticated: false });

        setRedirectPath("/login");
        clearLocalStorageOnLogout();
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (
    isAuthenticated &&
    redirectPath !== currentLocation.pathname
    && currentLocation.pathname === "/"
  ) {
    return <Redirect to={"/Default"} />;
  } else if (
    isAuthenticated &&
    redirectPath !== currentLocation.pathname &&
    currentLocation.pathname !== "/login"
  ) {
    return (
      <Route {...routeProps} path={routeProps.path}>
        {children}
      </Route>
    );
  } else if (
    // isAuthenticated &&
    // redirectPath !== currentLocation.pathname &&
    paramString &&
    paramString.includes("code")
  ) {
    return (
      <Route {...routeProps}>
        <LoginScreenOpenId PathCodeUrl={_codeUrl} />
      </Route>
    );

    /* </LoginScreenOpenId><h1>Oh my god</h1>; */
  } else {
    return (
      <Redirect
        to={{ pathname: isAuthenticated ? redirectPath : authenticationPath }}
      />
    );
  }
}
