import React from "react";
import { createContext, useContext, useState } from "react";
import { IUserModel } from "../IRequestModel/IUserModel";
const _userData = JSON.parse(localStorage?.getItem("userData") || "null") || { employeeData: {} };


const employeeData = _userData.employeeData;
const initialSession: IUserModel = employeeData;

export const UserContext = createContext<
  [IUserModel, (session: IUserModel) => void]
>([initialSession, () => {}]);

export const useUserContext = () => useContext(UserContext);

export const UserContextProvider: React.FC = (props) => {
  const [userData, setUserData] = useState(initialSession);
  const defaultUserContext: [IUserModel, typeof setUserData] = [
    userData,
    setUserData,
  ];

  return (
    <UserContext.Provider value={defaultUserContext}>
      {props.children}
    </UserContext.Provider>
  );
};
