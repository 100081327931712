export enum LogicType {
  DataSourceRelated = "datasourcerelated",
  Reference = "reference",
  DataSourceLoad = "datasourceload",
  DataLineApprove = "datalineapprove",
  DataRelatedToLoadData = "datareladtoloaddata",
  DataAjaxLoadTable = "dataajaxloadtable",
  Permission = "Permission",
  Role = "role",
  RefInfomation = "refinfomation",
  SpecialActionService = "specialactionservice",
  HideControl = "hidecontrol",
  ReadOnlyControl = "readonlycontrol",
  ActionService = "actionservice",
  SetValueControl = "setvaluecontrol",
}

export enum LogicOperatorType {
  Equal = "=",
  MoreThan = ">",
  LessThan = "<",
  GreaterThanAndEqual = ">=",
  LessThanAndEqual = "<=",
  NotEqual = "!=",
  Contain = "contain",
  StartWith = "startwith",
  EndWith = "endwith",
}
