export const GetUserManualList = async () => {
  const respone = await fetch("api/UserManual/GetAll")
    .then((response) => response.json())
    .then((data) => {
      return data;
    });

  return respone;
  // .filter((e: any) => e.IsActive === true);
};

export const AddUserManual = async (formData: any) => {
  const respone = await fetch("api/UserManual/AddUserManual", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    });

  return respone;
};
