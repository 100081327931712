export const getScreenWidthWithBreakpoint = () => {
  const screenWidth = window.innerWidth;
  if (screenWidth >= 1200) {
    return "xl";
  } else if (screenWidth >= 992) {
    return "lg";
  } else if (screenWidth >= 768) {
    return "md";
  } else if (screenWidth >= 576) {
    return "sm";
  } else {
    return "xs";
  }
};
