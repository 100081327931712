import React, { useEffect, useState } from "react";
import { Badge, Space } from "antd";
import IC_HISTORY from "./../../../assets/ic_history.svg";
import IC_ATTACHMENT_FILE from "./../../../assets/ic_attachment_file.svg";

interface BadgeNotificateProps {
  badgeSize?: "default" | "small";
  badgeColor?: string;
  dataDetail: any;
  requestData: any;
  dataMemo: any;
  type?: "attachment" | "history";
  children?: any;
  offset?: [number, number]; // [left, top]
  isCard?: boolean;
}

export const BadgeNotificate = (props: BadgeNotificateProps) => {
  const [count, setCount] = useState<number>(0);
  const [hasLastComment, setHasLastComment] = useState<boolean>(false);

  useEffect(() => {
    setNotificate();
  });

  const setNotificate = () => {
    if (props.dataMemo) {
      if (props.type === "history") {
        setHasLastComment(props.dataMemo?.IsMemoHistoryLastComment);
        setCount(props.dataMemo?.MemoHistoryAmount);
      } else {
        setCount(props.dataMemo?.MemoAttachFileAmount);
      }
    } else {
      let amountAttachment: number = 0;
      let history: any[] = [];
      let lastComment: boolean = false;

      if (props.dataDetail) {
        let data = props.dataDetail;
        amountAttachment = data?.attachfiles?.length;
        history = data?.history;
      } else if (props.requestData) {
        const requestDetail = props.requestData;
        const request = requestDetail?.requestDetails;
        amountAttachment = request?.listFileAttachDetails?.length;
        history = request?.listHistoryDetails;
      }
      history = history?.sort((a: any, b: any) => a?.action_id - b?.action_id);
      lastComment = history?.slice(-1).shift()?.comment !== "";
      let amountComment: number = history?.filter(
        (el: any) => el.comment !== ""
      ).length;
      let amount: number = 0;
      if (props.type === "attachment") {
        amount = amountAttachment;
      } else if (props.type === "history") {
        amount = amountComment;
        setHasLastComment(lastComment);
      }
      setCount(amount);
    }
  };

  if (props.dataMemo) {
    return (
      <Space>
        {count > 0 && (
          <Space>
            <Badge dot={hasLastComment}>
              {props.type === "attachment" ? (
                <img src={IC_ATTACHMENT_FILE} alt="" />
              ) : (
                <img src={IC_HISTORY} alt="" />
              )}
            </Badge>
          </Space>
        )}
      </Space>
    );
  }

  return !props.dataDetail && !props.requestData ? (
    props.children
  ) : props.isCard ? (
    count > 0 ? (
      <Badge.Ribbon text={count} color={hasLastComment ? "red" : "#bfbfbf"}>
        {props.children}
      </Badge.Ribbon>
    ) : (
      props.children
    )
  ) : count > 0 ? (
    <Badge
      offset={props.offset}
      size={props.badgeSize}
      count={count}
      color={hasLastComment ? "red" : "#bfbfbf"}
    >
      {props.children}
    </Badge>
  ) : (
    props.children
  );
};
