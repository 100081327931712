export const loginWolfAccount = async (method: any, data: any) => {
  const respone = await fetch("api/Login/WolfAccount", {
    method: method,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: data.username,
      password: data.password,
      TmpUrl: data.TmpUrl,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
  return respone;
};
export const loginWolfBD = async (method: any, data: any) => {
  const email = window.localStorage.getItem("email") || "";
  data.UserPrincipalName = email;
  const respone = await fetch("api/Login/LoginBD", {
    method: method,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: data.username,
      password: data.password,
      TmpUrl: data.TmpUrl,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
  return respone;
};
export const loginAD = async (method: any, data: any) => {
  const respone = await fetch("api/Login/ADAccount", {
    method: method,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: data.username,
      password: data.password,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
  return respone;
};

export const oidc = {
  getAuthorizationEndpoint: async () => {
    let response = await fetch("api/OpenId/Authorize").then((res) =>
      res.json()
    );
    return response;
  },

  getEndSessionEndpoint: async (logoutRequest: {
    postLogoutRedirectUri?: string;
  }) => {
    let searchParams = new URLSearchParams();
    searchParams.append(
      "post_logout_redirect_uri",
      logoutRequest.postLogoutRedirectUri || ""
    );

    let response = await fetch(
      `api/OpenId/EndSession?${searchParams.toString()}`
    ).then((res) => res.json());
    return response;
  },

  callbackHandler: async (querystring: string) => {
    let response = await fetch(
      "api/OpenId/Authorize/CallBack?" + querystring
    ).then((res) => res.json());
    return response;
  },
};
