import { Modal, Form, Col, Row, Input, Select, Space, Button } from "antd";
import React, { useEffect, useState } from "react";
import {
  PlusOutlined,
  MinusCircleOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { BiSave } from "react-icons/bi";
import { ButtonComponents } from "../../../ButtonComponents/ButtonComponents";
import { LogicFormProps, refInfors } from "../JsonLogicForm";
import { enumToObject } from "../../../../Helper/LogicHelper";
import { LogicOperatorType } from "../../../../constants/logic-type";
const { Option, OptGroup } = Select;

type Props = {
  rowData: any;
  onSubmit: (formData: any) => void;
} & LogicFormProps;

const SetValueControlModal = ({
  rowData,
  onSubmit,
  advanceFormItem,
}: Props) => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState<any>();
  const operators = enumToObject(LogicOperatorType);

  useEffect(() => {
    if (rowData) form.setFieldsValue(rowData);
  }, [rowData]);

  return (
    <Modal
      destroyOnClose={true}
      maskClosable
      closable={false}
      visible={rowData}
      title={"Conditions"}
      width={"70vw"}
      footer={
        <>
          <Row className="sub-header" gutter={[10, 10]} justify="end">
            <Col span={4} style={{ alignItems: "center" }}></Col>
            <Col span={4} style={{ alignItems: "flex-end" }}>
              <ButtonComponents
                setStyleProps={{
                  width: "100%",
                  boxShadow: "none",
                  fontSize: "13px",
                  paddingLeft: "16px",
                  backgroundColor: "red",
                  border: "1px solid red",
                }}
                setClassNameProps={"p-button-text-position"}
                setLabelProps={"Cancel"}
                setIconProps={<CloseOutlined rev={undefined} />}
                onClickProps={() => {
                  onSubmit(undefined);
                }}
              />
            </Col>
            <Col span={4} style={{ alignItems: "flex-end" }}>
              <ButtonComponents
                setStyleProps={{
                  width: "100%",
                  borderRadius: "6px",
                  boxShadow: "none",
                  border: "1px solid #282f6a",
                  fontSize: "13px",
                  paddingLeft: "16px",
                }}
                setClassNameProps={"p-button-text-position"}
                setLabelProps={"Save"}
                setIconProps={<BiSave />}
                onClickProps={() => {
                  onSubmit(formData);
                }}
              />
            </Col>
          </Row>
        </>
      }
    >
      <Form
        form={form}
        labelAlign={"left"}
        labelCol={{ span: 8 }}
        onValuesChange={(changeValue, allValues) => {
          setFormData(allValues);
        }}
      >
        <Form.List name={"conditions"}>
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map(({ key, name, ...restField }, idx) => (
                  <>
                    <Row justify="center">
                      <Col span={24}>
                        <Space
                          size={"middle"}
                          align="start"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Form.Item name={[name, "field"]}>
                            <Select
                              placeholder="field"
                              style={{ minWidth: 200 }}
                              showSearch
                            >
                              {advanceFormItem.map((item: any, index) => {
                                if (item.columns) {
                                  return (
                                    <OptGroup label={item.label}>
                                      {item.columns.map((option: any) => (
                                        <Option
                                          key={
                                            item.label +
                                            "'s columns" +
                                            option.label
                                          }
                                          value={option.label}
                                        >
                                          {option.label}
                                        </Option>
                                      ))}
                                    </OptGroup>
                                  );
                                }

                                return (
                                  <Option key={index} value={item.label}>
                                    {item.label}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                          <Form.Item name={[name, "operator"]}>
                            <Select
                              placeholder="Operator"
                              style={{ minWidth: 200 }}
                            >
                              {Object.entries(operators).map(
                                ([value, label], index) => (
                                  <Option key={index} value={value}>
                                    {label}
                                  </Option>
                                )
                              )}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            name={[name, "value"]}
                            validateTrigger={["onChange", "onBlur"]}
                            rules={[
                              {
                                validator: (_, value) => {
                                  if (
                                    !value &&
                                    !form.getFieldValue("conditions")[name][
                                      "comparewith"
                                    ]
                                  ) {
                                    return Promise.reject(
                                      "Value or comparewith cannot be empty"
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ]}
                          >
                            <Input placeholder="value" />
                          </Form.Item>
                          {/* <Form.Item
                            name={[name, "comparewith"]}
                            validateTrigger={["onChange", "onBlur"]}
                            rules={[
                              {
                                validator: (_, value) => {
                                  if (
                                    !value &&
                                    !form.getFieldValue("conditions")[name][
                                      "value"
                                    ]
                                  ) {
                                    return Promise.reject(
                                      "Value or comparewith cannot be empty"
                                    );
                                  }

                                  return Promise.resolve();
                                },
                              },
                            ]}
                          >
                            <Select
                              placeholder="Compare with control"
                              style={{ minWidth: 200 }}
                              allowClear
                              showSearch
                            >
                              {refInfors.map((item, index) => (
                                <Option key={item.value} value={item.label}>
                                  {item.label}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item> */}
                          <MinusCircleOutlined
                            onClick={() => remove(name)}
                            rev={undefined}
                          />
                        </Space>
                      </Col>
                    </Row>
                  </>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined rev={undefined} />}
                  >
                    Add condition
                  </Button>
                </Form.Item>
              </>
            );
          }}

          {/* <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              block
              icon={<PlusOutlined rev={undefined} />}
            >
              Add condition
            </Button>
          </Form.Item> */}
        </Form.List>
      </Form>
    </Modal>
  );
};

export default SetValueControlModal;
