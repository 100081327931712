export const GetAll = async (request: any) => {
    const respone = await fetch("api/SendEmail/GetAll", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      })
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  
      return respone;
};

export const ReSend = async (request: any) => {
    const respone = await fetch("api/SendEmail/ReSend", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      })
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  
      return respone;
};