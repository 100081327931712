import React, { useEffect, useRef, useState } from "react";
import "../PDFTemplateComponent/PDFTemplateComponent.css";

import PDFToolBar from "./PDFToolBar";
import usePDFEditor from "../../../hooks/usePDFEditor";
import PDFEditorToolBar from "./PDFEditorToolBar";
import PaperLayout from "./PaperLayout";
import { ZoomInOutlined, ZoomOutOutlined } from "@ant-design/icons";
import { Button, Col, Popover, Row, Spin, Switch, Tabs, TabsProps } from "antd";
import { IControlLayout } from "../../../Context/PDFEditorContext";
import { GetMasterDataReportTem } from "../../../Services/MasterDataService";
import { ITemplatePDF } from "../../../IRequestModel/CreateFormControl/ITemplatePDF";

type Props = {
  advanceForm: any;
  templateID: string;
  setControlModel: any;
  reportTem: any[];
  fontList: any[];
  templatePDF: ITemplatePDF;
};

const PDFTemplateComponent = ({
  advanceForm,
  templateID,
  setControlModel,
  reportTem,
  fontList,
  templatePDF,
}: Props) => {
  const {
    paperLayout,
    pageProperty,
    selectionBlock,
    setPaperLayout,
    updatePageProperties,
    onDeselected,
    refHtml,
  } = usePDFEditor();
  const [scale, setScale] = useState(1);
  var _userData = JSON.parse(window.localStorage.getItem("userData") || "");
  const _sharepointSiteURL = _userData.SharepointSiteURL;
  const [isEnabled, setIsEnabled] = useState<boolean>(templatePDF.IsActive);
  useEffect(() => {
    if (templatePDF.JsonLayout) {
      setPaperLayout(JSON.parse(templatePDF.JsonLayout));
      updatePageProperties({ ...JSON.parse(templatePDF.JsonProps) });
    }
  }, []);

  useEffect(() => {
    genFormat();
  }, [paperLayout, pageProperty, isEnabled]);

  const getLink = (val: string) => {
    let imgUrl = "";
    const protocol = window.location.protocol;

    if (_sharepointSiteURL) {
      imgUrl = _sharepointSiteURL + "/" + val;
    } else {
      const _port = window.location.port;
      let _tinyURL = _userData.TinyURL;
      if (_port) {
        _tinyURL = _userData.TinyURL + ":" + _port;
      }
      imgUrl = `${protocol}//` + _tinyURL + val;
    }

    return imgUrl !== ""
      ? imgUrl
      : "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png";
  };

  // const fecthMasterData = async () => {
  //   const res = await GetMasterDataReportTem();
  //   console.log(res);
  // };

  const genFormat = () => {
    try {
      let requestResult: any[] = [];
      paperLayout.forEach((layout, index) => {
        const _layout = layout?.children?.sort((a, b) => a.y - b.y);
        if (_layout) {
          const elements = refHtml.current?.getElementsByClassName(layout.i);
          let allDivElement = elements;
          if (elements && elements.length > 0) {
            for (let i = 0; i < elements.length; i++) {
              const element = elements[i];
              const allElements = element.getElementsByClassName("child-grid");
              allDivElement = allElements;
            }
          }
          let result: any[][] = [];
          let tableCount = _layout.filter(
            (e) => e.template.type === "tb"
          ).length;
          let tempAfter: IControlLayout[] = [];
          for (let i = 0; i <= tableCount; i++) {
            let beforeTb: any[] = [];
            let isBeforeTb = true;
            let afterTb: any[] = [];
            let tableControl: any[] = [];
            if (allDivElement) {
              if (i === 0) {
                for (const item of _layout) {
                  if (item.template.type === "tb") {
                    isBeforeTb = false;
                    if (tableControl.length === 0) {
                      tableControl.push({
                        i: item.i,
                        w: item.w,
                        h: item.h,
                        x: item.x,
                        y: item.y,
                        type: item.template.type,
                        Alabel: item.template.label,
                        html: getDivForTemplate(allDivElement, item),
                      });
                    }
                  }

                  if (isBeforeTb) {
                    beforeTb.push({
                      i: item.i,
                      w: item.w,
                      h: item.h,
                      x: item.x,
                      y: item.y,
                      type: item.template.type,
                      Alabel: item.template.label,
                      html: getDivForTemplate(allDivElement, item),
                    });
                  } else {
                    afterTb.push(item);
                  }
                }
              } else {
                tempAfter = tempAfter.slice(1, tempAfter.length);

                const a = tempAfter?.find((e) => e.template.type === "tb");
                if (a) {
                  tableControl.push({
                    i: a.i,
                    w: a.w,
                    h: a.h,
                    x: a.x,
                    y: a.y,
                    type: a.template.type,
                    Alabel: a.template.label,
                    html: getDivForTemplate(allDivElement, a),
                  });
                }

                for (const item of tempAfter) {
                  if (item.template.type === "tb") {
                    isBeforeTb = false;
                  }

                  if (isBeforeTb) {
                    beforeTb.push({
                      i: item.i,
                      w: item.w,
                      h: item.h,
                      x: item.x,
                      y: item.y,
                      type: item.template.type,
                      Alabel: item.template.label,
                      html: getDivForTemplate(allDivElement, item),
                    });
                  } else {
                    afterTb.push(item);
                  }
                }
              }
            }

            result.push(beforeTb);
            if (tableControl.length > 0) result.push(tableControl);

            tempAfter = afterTb;
            beforeTb = [];
          }

          requestResult.push(result);
        }
      });
      console.log(requestResult);

      setControlModel((prevState: any) => ({
        ...prevState,
        templatePDF: {
          ID: 0,
          TemplateId: templateID,
          JsonProps: JSON.stringify(pageProperty),
          JsonLayout: JSON.stringify(paperLayout),
          HeaderLayout: JSON.stringify(requestResult[0]),
          BodyLayout: JSON.stringify(requestResult[1]),
          FooterLayout: JSON.stringify(requestResult[2]),
          isActive: isEnabled,
        },
      }));
    } catch (error) {
      console.error("genFormat=>error", error);
    }
  };

  const getDivForTemplate = (
    allDivElement: HTMLCollectionOf<Element>,
    item: IControlLayout
  ) => {
    const tempDiv = document.createElement("div");
    var regex = /(\btransform:\s*[^;]+;|position:\s*absolute;)/g;
    for (let i = 0; i < allDivElement.length; i++) {
      const element = allDivElement[i];
      // Use getElementsByClassName on the individual element to get elements with a new class name
      const title = element.getAttribute("title");
      if (title === item.i) {
        try {
          if (item.template.type !== "staticImage") {
            tempDiv.setAttribute("style", element.getAttribute("style") || "");
            tempDiv.style.padding = "";
            tempDiv.style.width = "100%";
            tempDiv.style.height = "100%";
          } else {
            tempDiv.style.textAlign = "center";
          }

          const content = element?.getElementsByClassName(
            "content"
          )[0] as HTMLElement;
          if(content){
            const computedStyle = window.getComputedStyle(content);
            content.style.lineHeight = computedStyle.lineHeight;
            content.style.fontSize = tempDiv.style.fontSize;
            content.style.fontFamily = tempDiv.style.fontFamily;
            content.style.fontWeight = tempDiv.style.fontWeight;
          }
          tempDiv.appendChild(content.cloneNode(true));
        } catch (error) {
          console.error(error);
        }
      }
    }

    return tempDiv.outerHTML
      .replace(regex, "")
      .replace(/hidden/g, "")
      .replace(/border:\s*[^;]+;/g, "")
      .replace("Column value", "{value}")
      .replace(":5001", "");
  };

  const zoomIn = () => {
    if (scale < 2) {
      setScale(scale + 0.1);
    }
  };

  const zoomOut = () => {
    if (scale > 0) {
      setScale(scale - 0.1);
    }
  };

  return (
    <>
      <div className="toolbar" style={{ position: "relative" }}>
        <PDFEditorToolBar fontList={fontList} />
        <div style={{ position: "absolute", top: -30, right: 0 }}>
          <span>Enabled : </span>
          <Switch
            checked={isEnabled}
            onChange={(e) => {
              setIsEnabled(e);
            }}
          />
        </div>
      </div>

      <div className="editor-layout" onDoubleClick={onDeselected}>
        <div className="sidebar">
          <PDFToolBar advanceForm={advanceForm} reportTem={reportTem} />
        </div>
        <div className="board">
          <PaperLayout scale={scale} />
          <div className="zoom-panel">
            <div className="zoom-element-group">
              <ZoomOutOutlined
                style={{ fontSize: 25, cursor: "pointer" }}
                onClick={zoomOut}
                rev={undefined}
              />
              <div className="zoom-slider">
                <div
                  className="zoom-cursor"
                  style={{ left: `${(200 / 2) * scale * 0.5}%` }}
                />
              </div>
              <ZoomInOutlined
                style={{ fontSize: 25, cursor: "pointer" }}
                onClick={zoomIn}
                rev={undefined}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PDFTemplateComponent;
