import { Popover, Button } from "antd";
import React, { useEffect, useState } from "react";
import { ColorResult, SketchPicker } from "react-color";
import { BgColorsOutlined } from "@ant-design/icons";

type Props = { color: string; onChageColor: (e: ColorResult) => void };

const ColorPicker = ({ color, onChageColor }: Props) => {
  const [value, setValue] = useState<string>("#ffff");

  return (
    <Popover
      overlayClassName="color-picker-popover"
      onVisibleChange={(e) => {
        setValue(color);
      }}
      content={
        <SketchPicker
          color={value}
          onChange={(e) => {
            setValue(e.hex);
          }}
          onChangeComplete={onChageColor}
        />
      }
      trigger={"click"}
    >
      <Button icon={<BgColorsOutlined rev={undefined} />} type={"default"} />
    </Popover>
  );
};

export default ColorPicker;
