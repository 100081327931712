import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  PlusOutlined,
  MinusCircleOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { BiSave } from "react-icons/bi";
import { ButtonComponents } from "../../../ButtonComponents/ButtonComponents";

type Props = { rowData: any; onSubmit: (formData: { DATA: any }) => void };

const MatchFieldsDataModal = ({ rowData, onSubmit }: Props) => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState<any>({ DATA: [] });
  useEffect(() => {
    form.setFieldsValue({ DATA: rowData });
  }, [rowData]);

  return (
    <Modal
      destroyOnClose={true}
      maskClosable
      closable={false}
      visible={rowData}
      width={"70vw"}
      footer={
        <>
          <Row className="sub-header" gutter={[10, 10]} justify="end">
            <Col span={4} style={{ alignItems: "center" }}></Col>
            <Col span={4} style={{ alignItems: "flex-end" }}>
              <ButtonComponents
                setStyleProps={{
                  width: "100%",
                  boxShadow: "none",
                  fontSize: "13px",
                  paddingLeft: "16px",
                  backgroundColor: "red",
                  border: "1px solid red",
                }}
                setClassNameProps={"p-button-text-position"}
                setLabelProps={"Cancel"}
                setIconProps={<CloseOutlined rev={undefined} />}
                onClickProps={() => {
                  onSubmit({ DATA: [] });
                }}
              />
            </Col>
            <Col span={4} style={{ alignItems: "flex-end" }}>
              <ButtonComponents
                setStyleProps={{
                  width: "100%",
                  borderRadius: "6px",
                  boxShadow: "none",
                  border: "1px solid #282f6a",
                  fontSize: "13px",
                  paddingLeft: "16px",
                }}
                setClassNameProps={"p-button-text-position"}
                setLabelProps={"Save"}
                setIconProps={<BiSave />}
                onClickProps={() => {
                  onSubmit(formData);
                }}
              />
            </Col>
          </Row>
        </>
      }
    >
      <Divider>Data</Divider>

      <Form
        form={form}
        onValuesChange={(changeValue, allValues) => {
          setFormData(allValues);
        }}
      >
        <Form.List name="DATA">
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map(({ key, name, ...restField }, idx) => (
                  <Space
                    key={idx}
                    size={"middle"}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                    align="start"
                  >
                    <Form.Item {...restField} name={[name, "KEY"]}>
                      <Input placeholder="KEY" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "TYPE"]}
                      rules={[{ required: true, message: "Missing value" }]}
                    >
                      <Select
                        showSearch
                        style={{ minWidth: 100 }}
                        allowClear
                        options={[
                          {
                            value: null,
                            label: "Empty & Advance Field",
                          },
                          {
                            value: "tb",
                            label: "Table",
                          },
                          {
                            value: "standardfield",
                            label: "Standard Field",
                          },
                          {
                            value: "rerun",
                            label: "Rerun",
                          },
                          {
                            value: "running",
                            label: "Running",
                          },
                        ]}
                      />
                    </Form.Item>
                    <Form.Item {...restField} name={[name, "LABEL"]}>
                      <Input placeholder="LABEL" />
                    </Form.Item>
                    <Form.Item {...restField} name={[name, "VALUE"]}>
                      <Input placeholder="VALUE" />
                    </Form.Item>
                    <MinusCircleOutlined
                      onClick={() => remove(name)}
                      rev={undefined}
                    />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined rev={undefined} />}
                  >
                    Add
                  </Button>
                </Form.Item>
              </>
            );
          }}
        </Form.List>
      </Form>
    </Modal>
  );
};

export default MatchFieldsDataModal;
