import React, { useEffect, useState } from "react";
import { LogicFormProps } from "../JsonLogicForm";
import { Button, Col, Form, Row, Select, Space } from "antd";
import { GetAllDynamic } from "../../../../Services/DynamicService";
import { useUserContext } from "../../../../Context/UserContext";
const { Option, OptGroup } = Select;

const ShowHideByRoles = ({ form, advanceFormItem }: LogicFormProps) => {
  const controlOption: any[] = advanceFormItem
    .map((item) => {
      if (item.type === "tb" && item.columns) {
        return {
          label: item.label,
          value: JSON.stringify({
            lable: item.label,
            type: item.type,
          }),
          options: item.columns.map((col) => ({
            label: col.label,
            value: JSON.stringify({
              lable: col.label,
              type: item.type,
              isInTable: item.label,
            }),
          })),
        };
      }
      return {
        label: item.label,
        value: JSON.stringify({
          lable: item.label,
          type: item.type,
        }),
      };
    })
    .filter((e) => e.label);

  const [mstRoles, setMstRoles] = useState<any[]>([]);
  const [userData] = useUserContext();

  useEffect(() => {
    fecthRoles();
  }, []);

  const fecthRoles = async () => {
    try {
      const roles = await GetAllDynamic("Roles/GetAll");
      if (roles.length) {
        const _roles = roles.map(
          (e: { NameEn: any; NameTh: any; RoleId: any }) => {
            return {
              label: userData.Lang === "EN" ? e.NameEn : e.NameTh,
              value: e.RoleId,
            };
          }
        );
        setMstRoles([..._roles]);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Form.Item label="Action control" name="action">
        <Select
          placeholder="Compare with control"
          showSearch
          style={{ minWidth: 200 }}
          filterOption={(inputValue, option) => {
            return (
              option?.label.toLowerCase().includes(inputValue.toLowerCase()) ||
              false
            );
          }}
          options={[
            { label: "Show", value: "show" },
            { label: "Hide", value: "hide" },
            { label: "Read only", value: "readonly" },
          ]}
        />
      </Form.Item>
      <Form.Item label="Field Action" name="fieldaction">
        <Select
          placeholder="Field to Action"
          mode="multiple"
          allowClear
          showSearch
          style={{ minWidth: 200 }}
        >
          {controlOption.map((item: any, index) => {
            if (item.options) {
              return (
                <>
                  <Option key={item.label} value={item.value}>
                    {item.label}
                  </Option>
                  <OptGroup label={item.label + "'s columns"}>
                    {item.options.map((option: any) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </OptGroup>
                </>
              );
            } else {
              return (
                <Option key={item.label} value={item.value}>
                  {item.label}
                </Option>
              );
            }
          })}
        </Select>
      </Form.Item>

      <Row style={{ width: "100%" }} gutter={10} justify="end">
        <Col span={22}>
          <Form.Item label="Role" name={["roleids"]}>
            <Select
              placeholder="Action to role"
              mode="multiple"
              allowClear
              maxTagCount={5}
              filterOption={(inputValue, option) => {
                return option.label
                  .toLowerCase()
                  .includes(inputValue.toLowerCase());
              }}
              showSearch
              style={{ minWidth: 200 }}
              options={mstRoles}
            />
          </Form.Item>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              form.setFieldsValue({ roleids: mstRoles.map((e) => e.value) });
            }}
          >
            Select All
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ShowHideByRoles;
