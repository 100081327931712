import { useContext } from "react";
import { MasterSettingContext } from "../Context/MasterSettingContext";

const useMasterSetting = () => {
  const context = useContext(MasterSettingContext);
  if (!context)
    throw new Error("MemoPage Context  must be use inside MemoPage Provider");
  return context;
};

export default useMasterSetting;
