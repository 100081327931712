import _ from "lodash";

export const findMaxInArrayObject = (arr: any[], key: string) => {
  const maxValue = arr.reduce((max, current) => {
    return current[key] > max[key] ? current : max;
  }, arr[0]);
  return maxValue;
};

export function checkTheKeyMatch(
  arr1: any[],
  arr2: any[],
  key: string | number
) {
  // Extract MemoId from both arrays
  const arrItems1 = arr1?.map((item: { [x: string]: any }) => item[key]);
  const arrItems2 = arr2?.map((item: { [x: string]: any }) => item[key]);
  return _.isEqual(arrItems1, arrItems2);
}
