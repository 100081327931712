import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FiPlus } from "react-icons/fi";
import { ButtonComponents } from "../../ButtonComponents/ButtonComponents";
import "./ReferenceInformationComponents.css";
import ReferenceInformationDialog from "./ReferenceInformationDialog";
import { TextHeaderComponents } from "../../TextHeaderComponents/TextHeaderComponents";
import { confirmDialog } from "primereact/confirmdialog";
import binIcon from "../../../assets/bin-icon.png";
import { ILogicReferenceInformation } from "../../../IRequestModel/ILogicModel";

type Props = {
  listRefInformationProps: any;
  stateProps: any;
  controlModelObj: any;
  setControlModelObj: any;
  advanceForm: any;
  dataLogic: any;
  setLogicsRefIn: any;
};

export const ReferenceInformation = (props: Props) => {
  const [visibleRefenceDocumentDialog, setVisibleRefenceDocumentDialog] =
    useState<any>(false);

  const [referenceData, setReferenceData] = useState<any>();
  const [refLogic, setRefLogic] = useState<any>([]);

  const [refInformation, setRefInformation] = useState<any>();

  useEffect(() => {
    if (props.dataLogic) {
      setReferenceData([
        ...props.dataLogic
          .filter(
            (e: { Logictype: string; logictype: string }) =>
              e.logictype === "refinfomation"
          )
          .map((logic: { jsonvalue: string }) => {
            const jsonValue: ILogicReferenceInformation =
              logic.jsonvalue &&
              logic.jsonvalue.length > 0 &&
              JSON.parse(logic.jsonvalue);

            return jsonValue;
          }),
      ]);
    }
  }, []);

  useEffect(() => {
    if (referenceData) {
      //setReferenceData(props.refInformation);
      fecthRefInformation();
    }
  }, [referenceData]);
  const fecthRefInformation = () => {
    try {
      let TemplateLogic: any[] = [];
      let arrayRef = referenceData;
      let Logics = [];
      // TemplateLogic = Logics;
      let logicControl: any[] = [];
      arrayRef.map((cons: any) => {
        if (
          logicControl.filter((e: any) => e.label === cons.condition).length ===
          0
        ) {
          logicControl.push({
            condition: cons.condition,
            required: cons.required,
          });
        }
      });

      logicControl.forEach((logic: any, idx: number) => {
        TemplateLogic.push({
          Seq: idx + 1,
          Logictype: "refinfomation",
          Jsonvalue: JSON.stringify(logic),
          LstCondition: [],
        });
      });

      props.setLogicsRefIn([...TemplateLogic]);
      // if (TemplateLogic.length > 0) {
      //   props.setControlModelObj((prevState: any) => ({
      //     ...prevState,
      //     cMSTTemplateLogic: [...TemplateLogic],
      //   }));
      // }
    } catch (error) {
      console.error(error);
    }
  };

  function onClickVisible() {
    setVisibleRefenceDocumentDialog(!visibleRefenceDocumentDialog);
  }

  const onDeleteRow = (data: any, setData: any, rowIdx: number) => {
    let selected: any = [...data];
    let TemplateLogic: any[] = [];
    confirmDialog({
      message: "Do you to delete this row.",
      header: "Delete Confirmation",
      icon: "p-confirm-dialog-icon pi pi-info-circle",
      acceptClassName:
        "p-button p-component p-confirm-dialog-accept table-accept p-button-danger table-control-confirm-button p-button-accept-cancel",
      accept: () => {
        selected.splice(rowIdx, 1);
        selected.map((e: any, idx: number) => {
          e.Seq = idx + 1;
        });
        setData(selected);
        setRefInformation(selected);

        let Logics = props.dataLogic.filter(
          (element: any) => element.Logictype !== "refinfomation"
        );
        Logics.push(...selected);
        console.log("refInformationcMSTTemplateLogic", Logics);
        props.setControlModelObj((prevState: any) => ({
          ...prevState,
          cMSTTemplateLogic: Logics,
        }));
      },
    });
  };

  return (
    <>
      <Row className="">
        <Col xs={12} md={7} lg={9} xl={9} style={{ alignItems: "center" }}>
          <p
            className="Col-text-header-Inform"
            style={{ fontWeight: 500, color: "#262A2D" }}
          >
            Reference Information
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12} lg={12} xl={12}>
          <p className="informationComponents-line-border"></p>
        </Col>
      </Row>
      <Row className="gutter-row">
        <Col xs={12} md={12} lg={12} xl={12}>
          <ButtonComponents
            setStyleProps={{
              width: "150px",
              borderRadius: "6px",
              boxShadow: "none",
              border: "1px solid #282f6a",
              fontSize: "13px",
              paddingLeft: "16px",
              marginTop: "15px",
            }}
            onClickProps={onClickVisible}
            setLabelProps={"Add Reference"}
            setIconProps={<FiPlus />}
            setClassNameProps={"p-button-text-position"}
          />
        </Col>
      </Row>
      <Row className="gutter-row">
        <Col xs={12} md={12} lg={12} xl={12}>
          <DataTable
            value={referenceData}
            responsiveLayout="scroll"
            className="referenceDocumentComponents-dataTable"
          >
            <Column
              bodyClassName="approveMatrix-table"
              header={
                <TextHeaderComponents
                  textHeaderProps={"Sequence"}
                  textSubProps={"ลำดับ"}
                />
              }
              style={{ textAlign: "start" }}
              body={(rowData, options) => options.rowIndex + 1}
            ></Column>
            <Column
              field="condition"
              header={
                <TextHeaderComponents
                  textHeaderProps={"Selected Reference"}
                  textSubProps={"ตัวเลือกที่อ้างอิง"}
                />
              }
              style={{ textAlign: "start" }}
            ></Column>
            <Column
              field="required"
              style={{ textAlign: "start" }}
              header={
                <TextHeaderComponents
                  textHeaderProps={"Reference Field"}
                  textSubProps={"อ้างอิงถึง"}
                />
              }
            ></Column>

            <Column
              header={
                <TextHeaderComponents
                  textHeaderProps={"Delete"}
                  textSubProps={"ลบ"}
                />
              }
              headerStyle={{ width: "5rem" }}
              body={(rowData: any, options: any) => {
                return (
                  <button
                    className="table-button"
                    type="button"
                    onClick={() => {
                      onDeleteRow(
                        referenceData,
                        setReferenceData,
                        options.rowIndex
                      );
                    }}
                  >
                    <img src={binIcon} alt="logo" />
                  </button>
                );
              }}
            ></Column>
          </DataTable>
        </Col>
      </Row>

      <ReferenceInformationDialog
        visibleRefenceDocumentDialog={visibleRefenceDocumentDialog}
        setVisibleRefenceDocumentDialog={setVisibleRefenceDocumentDialog}
        advanceForm={props.advanceForm}
        controlModel={props.controlModelObj}
        setControlModelObj={props.setControlModelObj}
        listRefInformationProps={props.listRefInformationProps}
        setRefInformation={setReferenceData}
        refInformation={referenceData}
        refLogic={refLogic}
        dataLogic={props.controlModelObj.cMSTTemplateLogic}
        setLogicsRefIn={props.setLogicsRefIn}
      />
    </>
  );
};
