/**
 * PDFPreviewComponent
 * component ที่ใช้ preview PDF file ที่ไม่ใช่ iframe
 * โดย PDF จะถูกอ่านด้วน lib PDF.JS ของ Mozilla
 * แล้วทำการวาดลง Canvas พร้อมป้องกันคลิกขวา (context menu)
 */

import React, { ReactElement, useEffect, useState } from "react";
import "./PDFPreviewComponent.css";
import { PDFDocumentProxy } from "pdfjs-dist/legacy/build/pdf"; // Updated import

// The workerSrc property shall be specified.
export function PDFPreviewComponent(props: { src: string }) {
  const [elements, setElements] = useState<ReactElement[]>([]);
  // The workerSrc property shall be specified.
  const pdfjs = (window as any).pdfjsLib;
  
  useEffect(() => {
    const renderDocument = async () => {
      try {
        const base64Src = await convertToBase64(props.src);

        // Ensure pdfjs and getDocument are defined
        if (!pdfjs?.getDocument) throw new Error("PDF.js library not loaded");

        const loadingTask = pdfjs.getDocument({ data: atob(base64Src) });
        
        const pdf: PDFDocumentProxy = await loadingTask.promise;
        const pages: ReactElement[] = [];

        for (let i = 1; i <= pdf.numPages; i++) {
          pages.push(
            <div key={"page-" + i} className="page">
              <canvas
                key={"canvas-page-" + i}
                ref={(node) => node && renderPage(node, pdf, i)}
                role="presentation"
              ></canvas>
            </div>
          );
        }
        setElements(pages);
      } catch (error) {
      }
    };

    renderDocument();
  }, [props.src, pdfjs]);


  const convertToBase64 = async (url: string): Promise<string> => {
    const response = await fetch(url);
    const buffer = await response.arrayBuffer();
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
  
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
  
    return btoa(binary); // Convert binary string to base64
  };
  
  function renderPage(
    canvas: HTMLCanvasElement | null,
    pdf: PDFDocumentProxy,
    pageNum: number
  ) {
    if (!canvas) return;
  
    pdf.getPage(pageNum).then(
      (page) => {
        // Adjust scale based on device pixel ratio
        const scale = 1.5;
        const viewport = page.getViewport({ scale });
        
        // HiDPI screen support
        const outputScale = window.devicePixelRatio || 1;
        canvas.width = Math.floor(viewport.width * outputScale);
        canvas.height = Math.floor(viewport.height * outputScale);
        canvas.style.width = `${Math.floor(viewport.width)}px`;
        canvas.style.height = `${Math.floor(viewport.height)}px`;
  
        const context = canvas.getContext("2d");
        if (!context) {
          console.error("Failed to get 2D context for canvas");
          return;
        }
  
        // Apply scaling transformation if required
        const transform = outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;
  
        // Render context with scaling options
        const renderContext :any= {
          canvasContext: context,
          viewport,
          transform,
          intent: "display",
        };
  
        const renderTask = page.render(renderContext);
        renderTask.promise.then(
          () => {
            console.log(`Page ${pageNum} rendered successfully.`);
          },
          (reason) => {
            console.error("Page render error:", reason);
          }
        );
      },
      (reason) => {
        console.error("Error loading page:", reason);
      }
    );
  }
  

  function disableContextMenu(e: React.MouseEvent) {
    e.preventDefault();
  }

  return (
    <>
      <div className="pdf-preview-container" onContextMenu={disableContextMenu}>
        {elements}
      </div>
    </>
  );
}
