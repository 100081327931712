import React, { useEffect, useState } from "react";

interface Props {
  leaveTypeTable: any;
  LeaveConfig: any;
}

export default function LeaveTypeComponents(props: Props) {
  return (
    <div style={{ display: "flex", padding: "17px 0px 30px 0px" }}>
      <table className="table-layout-create-control table-striped">
        <thead className="thead-light">
          <tr className="set-bg-color-table-create-control">
            <th style={{ width: "5%" }}>
              <p className="row headtext"></p>
              <p className="row subtext">#</p>
            </th>
            <th style={{ width: "50%" }}>
              <p className="row headtext">
                <p className="row headtext">
                  {props.LeaveConfig && props.LeaveConfig.length > 0
                    ? props.LeaveConfig[0].Value2.split('|')[0]
                    : "Leave Type"}
                </p>
              </p>
              <p className="row subtext">
                {props.LeaveConfig && props.LeaveConfig.length > 0
                  ? props.LeaveConfig[0].Value2.split('|')[1]
                  : "ประเภทของการขอลา"}
              </p>
            </th>
            <th style={{ width: "15%" }}>
              <p className="row headtext">
                {props.LeaveConfig && props.LeaveConfig.length > 0
                  ? props.LeaveConfig[0].Value3.split('|')[0]
                  : "Package"}
              </p>
              <p className="row subtext">
                {props.LeaveConfig && props.LeaveConfig.length > 0
                  ? props.LeaveConfig[0].Value3.split('|')[1]
                  : "จำนวนวันลาตั้งต้น"}
              </p>
            </th>
            <th style={{ width: "15%" }}>
              <p className="row headtext">
                {props.LeaveConfig && props.LeaveConfig.length > 0
                  ? props.LeaveConfig[0].Value4.split('|')[0]
                  : "Used"}
              </p>
              <p className="row subtext">
                {props.LeaveConfig && props.LeaveConfig.length > 0
                  ? props.LeaveConfig[0].Value4.split('|')[1]
                  : "จำนวนวันลาที่ใช้ไป"}
              </p>
            </th>
            <th style={{ width: "15%" }}>
              <p className="row headtext">
                {props.LeaveConfig && props.LeaveConfig.length > 0
                  ? props.LeaveConfig[0].Value5.split('|')[0]
                  : "Balance"}
              </p>
              <p className="row subtext">
                {props.LeaveConfig && props.LeaveConfig.length > 0
                  ? props.LeaveConfig[0].Value5.split('|')[1]
                  : "จำนวนวันลาที่เหลืออยู่"}
              </p>
            </th>
          </tr>
        </thead>
        <tbody>
          {/* if master date LR */}
          {props.leaveTypeTable?.map((_data: any, idx: any) => (
            <>
              <tr>
                <td>{idx + 1}</td>
                <td>{_data.Value2}</td>
                <td>{(_data.Value3)}</td>
                <td>{(_data.Value4)}</td>
                <td>{(_data.Value5)}</td>
              </tr>
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
}
